import React from 'react';
import {useNavigate} from 'react-router-dom';

export default () => {
    const navigate = useNavigate();

    return (
        <section class="hoverPlayTime">
            <div class="hoverPlayTime__wrapper wrapper">
                <ul class="hoverPlayTime__line">
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/evolution')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/evolution.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/kagaming')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/kagaming.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/spadegaming')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/spadegaming.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/relaxgaming')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/relaxgaming.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/redrake')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/redrake.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/pushgaming')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/pushgaming.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/playtech')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/playtech.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/playson')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/playson.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/playngo')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/playngo.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/kajot')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/kajot.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/hacksaw')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/hacksaw.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/gaminator')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/gaminator.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/booongo')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/boongo.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/betsoft')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/betsoft.png`} alt="" /></div>
                    </li>

                    {/*  */}

                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/amatic')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/2.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/igrosoft')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/5.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/wazdan')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/11.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/quickspin')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/16.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/pragmatic')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/17.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/netent')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/19.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/microgaming')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/20.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/merkur')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/21.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/igt')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/23.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/egt')}> 
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/25.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/aristocrat')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/28.png`} alt="" /></div>
                    </li>
                    <li class="hoverPlayTime__item" onClick={e => navigate('/provider/apollo')}>
                        <div class="hoverPlayTime__img"><img src={`${window.location.origin}/img/logos/30.png`} alt="" /></div>
                    </li>
                </ul>
            </div>
        </section>
    )
}