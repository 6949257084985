import {React} from 'react';
import Lang from '../../../lang';

export default () => {

    return (
        <section class="guarantee">
            <div class="guarantee__head">
                <h3 class="guarantee__headTitle" dangerouslySetInnerHTML={{__html: Lang('pages.main.money.title')}}></h3>
                <div class="guarantee__headDesc" dangerouslySetInnerHTML={{__html: Lang('pages.main.money.text')}}></div>
            </div>
            <ul class="wrapper guarantee__list">
                <li class="guarantee__item">
                    <div class="guarantee__icon">
                        <img 
                            src={`${window.location.origin}/img/icons/award-03.svg`} 
                            alt="" />
                    </div>
                    <h6 class="guarantee__title" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.enjoy.title')}}></h6>
                    <p class="guarantee__decription" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.enjoy.text')}}></p>
                </li>
                <li class="guarantee__item">
                    <div class="guarantee__icon">
                        <img 
                            src={`${window.location.origin}/img/icons/check-verified.svg`}
                            alt="" />
                    </div>
                    <h6 class="guarantee__title" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.purchase.title')}}></h6>
                    <p class="guarantee__decription" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.purchase.text')}}></p>
                </li>
                <li class="guarantee__item">
                    <div class="guarantee__icon">
                        <img 
                            src={`${window.location.origin}/img/icons/message-chat-square.svg`} 
                            alt="" />
                    </div>
                    <h6 class="guarantee__title" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.support.title')}}></h6>
                    <p class="guarantee__decription" dangerouslySetInnerHTML={{__html: Lang('pages.main.cards.support.text')}}></p>
                </li>
            </ul>
        </section>
    )
}