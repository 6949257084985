import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/form/signup';

import Account from './account';
import Person from './person';

export default () => {
    const [data, setData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
        first_name: '',
        last_name: '',
        sex: null,
        birth: '',
        mailing: false,
        confirm: false,
        country: ''
    });

    const {stage, form} = useSelector(Selector)

    return (
        <div class="popup" id="login_signup" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            {
                stage === 1 
                ? <Account 
                    data={data} 
                    setData={setData} 
                    formData={form} /> 
                : <Person 
                    data={data} 
                    setData={setData}
                    formData={form} />}
        </div>
    )
    
}