export default {
    gameId: ({game}) => game.gameId,
    demo: ({game}) => game.demo,
    session: ({game}) => game.session,
    loaded: ({game}) => game.game !== null,
    game: ({game}) => game.game,
    modalGame: ({game}) => game.modalGame,
    buttons: {
        favorite: ({game}) => game.buttons.favorite
    }
}