import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PresetSelector from '../../../selectors/preset';
import LoaderUtil from '../../../utils/loader';
import FaqUtil from '../../../utils/faq';
import Lang from '../../../lang';
import {Helmet} from 'react-helmet';

export default () => {
    const [state, setState] = useState({}),
          map = Lang('pages.faq.questions');

    const {preset, language} = useSelector(PresetSelector);

    useEffect(() => {
        LoaderUtil.hide();
    });

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].faq)}</title>
            </Helmet>
            <div class="blocker">
                <div class="blocker__title">{Lang('pages.faq.title')}</div>
                <ul class="blocker__accordion accordion">
                    {map.map((item, key) => {
                        return (
                            <li class={`accordion__item ${state[key.toString()] ? 'active' : ''}`} key={key} onClick={e => {
                                setState({
                                    ...state,
                                    [key.toString()]: !state[key.toString()]
                                });
                            }}>
                                <div class="accordion__block">
                                    <p class="accordion__title">{item.question}</p>
                                    <div class="accordion__icon">
                                        <svg width="9" height="8" viewBox="0 0 9 8" fill="white" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06215 0C0.275079 0 -0.203484 0.867179 0.216099 1.53309L3.40506 6.59424C3.79732 7.21678 4.70492 7.21678 5.09718 6.59424L8.28614 1.53309C8.70572 0.867177 8.22715 0 7.44008 0H1.06215Z" fill="inherit"></path>
                                        </svg>
                                    </div>
                                </div>
                                <ul class="accordion__content">
                                    {item.answer.map((anw, key2) => {
                                        return (
                                            <li class="accordion__li" key={key2}>
                                                <div class="blocker__text title">{anw.title}</div>
                                                <div class="blocker__text" dangerouslySetInnerHTML={{__html: anw.text}}></div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}