import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.auth.buttons.approveAccountInformation,
    props.main.countrys,
    (button, countrys) => {
        return {
            button,
            countrys
        }
    }
)