import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.game.gameId,
    props.game.loaded,
    props.main.sounds,
    (gameId, loaded, sounds) => {
        return {
            gameId,
            loaded,
            sounds
        }
    }
)