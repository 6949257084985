export default {
    categorys: {
        home: "Главная",
        search: "Поиск",
        favorites: "Избранное",
        new: "Новые игры",
        popular: "Популярные",
        fish: "Fish",
        live: "Лайв-Дилеры",
        cards: "Карточные",
        animals: "Животные",
        books: "Книги",
        fruits: "Фрукты",
        asian: "Азиатские",
        fantasy: "Фэнтези",
        instant: "Instant",
        wagering: "Отыгрыш бонуса",
        bonus_buy: "Покупка бонуса",
        game_show: "Шоу-Игры",
        roulette: "Рулетка",
        slots: "Слоты",
        jackpots: "Джекпоты",
        fastgames: "Fast игры",
    },
    sliders: {
        live: "Лайв-Игры",
        poker: "Покер",
        dice: "Кости",
        blackjack: "Блэкджек",
        baccarat: "Баккара",
        show: "Шоу-Игры",
        fish: "Fish игры",
        new: "Новые игры",
        top: "Лучшие выигрыши",
        popular: "Популярные",
        recommend: "Рекомендуем",
        all: "Все игры"
    },
    money: {
        title: "Выигрывайте в 2000+ играх казино Вегаса",
        text: "Каждую неделю выходят новые игры в стиле казино Вегаса. Вы почувствуете себя победителем имея под рукой огромное разнообразие игровых автоматов Вегаса!"
    },
    cards: {
        enjoy: {
            title: "Лицензированные игры",
            text: "Наше казино сотрудничает только с лучшими лицензионными провайдерами."
        },
        purchase: {
            title: "Приветственный бонус",
            text: "Для всех наших игроков мы предоставляем безпедозитный приветственный бонус с возможностью вывода средств!"
        },
        support: {
            title: "24/7 Поддержка",
            text: "Наши операторы готовы решить любой ваш вопрос в любое время суток!"
        }
    },
    more: ({count, games}) => `Показать  еще (${count}/${games})`
}