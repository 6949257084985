import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/components/header';
import {toggleHeader, setType} from '../../reducers/main/actions';
import Icons from '../pages/main/icons';
import {useNavigate, useLocation} from 'react-router-dom';
import ModalsUtil from '../../utils/modals';
import Lang from '../../lang';

export default () => {
    const {header_active, active_bonus, type, isAuth} = useSelector(Selector),
          dispatch = useDispatch(),
          navigate = useNavigate(),
          location = useLocation(),
          [activeTab, setActiveTab] = useState(null),
          [walletOpen, setWalletState] = useState(false);

    useEffect(() => {
        if(location.pathname === '/' && type === "home") setActiveTab('home');
        if(location.pathname === '/' && type === "favorites") setActiveTab('favorites');
        if(location.pathname === '/profile/bonuses') setActiveTab('bonuses');
    }, [location, type]);

    return (
        <div className={`mobile-nav ${walletOpen ? 'wallet-open' : ''}`}>
            {!walletOpen ? (
                <>
                    <div className={`mobile-nav-element ${activeTab === "home" ? "active" : ""}`} onClick={e => {
                        dispatch(setType('home'));
                        navigate('/');
                        setTimeout(() => document.dispatchEvent(new CustomEvent('mobile_main')), 50)
                    }}>
                        <Icons.Home />
                        Главная
                    </div>
                    <div className={`mobile-nav-element ${activeTab === "favorites" ? "active" : ""}`} onClick={e => {
                        dispatch(setType('favorites'));
                        navigate('/');
                        setTimeout(() => document.dispatchEvent(new CustomEvent('main_favorites')), 50)
                    }}>
                        <Icons.Favorites />
                        Избранное
                    </div>
                </>
            ) : (
                <div className="movile-nav-full-element">
                    <div className="mobile-nav-element full-element" onClick={e => {
                        if(active_bonus)
                            return ModalsUtil.error(Lang('modals.deposit.bonus.title'), Lang('modals.deposit.bonus.text'))

                        ModalsUtil.toggle('#deposit_modal');
                    }}>
                        Внести
                    </div>
                </div>
            )}
            <div className="mobile-nav-element">
                <div class={`header__btnPopup ${walletOpen ? 'active' : ''}`} onClick={e => {
                    setWalletState(!walletOpen);
                }}>
                    {walletOpen ? <Icons.Close /> : <Icons.Wallet />}
                </div>
            </div>
            {!walletOpen ? (
                <>
                    <div className={`mobile-nav-element ${activeTab === "bonuses" ? "active" : ""}`} onClick={e => {
                        navigate('/profile/bonuses');
                        setTimeout(() => document.dispatchEvent(new CustomEvent('profile_bonus')), 50)
                    }}>
                        <Icons.Bonus_buy />
                        Мои бонусы
                    </div>
                    <a href="https://t.me/casinos_support_bot?start=azimut" target="_blank" className="mobile-nav-element">
                        <Icons.Operator />
                        Поддержка
                    </a>
                </>
            ) : (
                <div className="movile-nav-full-element">
                    <div className="mobile-nav-element full-element bordered" onClick={e => {
                        if(active_bonus)
                            return ModalsUtil.error(Lang('modals.cashout.bonus.title'), Lang('modals.cashout.bonus.text'))

                        ModalsUtil.toggle('#cashout_modal');
                    }}>
                        Вывести
                    </div>
                </div>
            )}
        </div>
    )
}