import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.cashout.window,
    props.deposit.methods,
    props.deposit.methods_map,
    props.deposit.currencies,
    (method, methods, methods_map, currencies) => {
        const methods_list = {};

        for(const method of methods) 
            if(typeof method.comissions.cashout !== 'undefined') {
                if(typeof methods_list[method.type] === 'undefined')
                    methods_list[method.type] = [];

                methods_list[method.type].push(method);
            }

        const methods_list_map = [];
        for(const method of methods_map)
            if(typeof methods_list[method] !== 'undefined' && methods_list[method].length > 0)
                methods_list_map.push(methods_list[method]);

        return {
            method,
            methods: methods_list_map,
            _methods: methods,
            currencies
        }
    }
)