import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.deposit.freekassa.form,
    props.deposit.buttons.freekassa_payment,
    (form, button) => {
        return {
            form, 
            button
        }
    }
)
