import Lang from '../../../lang';
import ModalsUtil from '../../../utils/modals';
import {useNavigate} from 'react-router-dom';
import {currency_short} from '../../../utils/types';

export default ({currency, active_bonus, isAuth}) => {
    const navigate = useNavigate();

    const getBonusAmount = () => {
        if(currency.key === 'RUB') return 50;
        return 0;
    }

    return (
        <li class="promotion__item">
            <div class="promotion__block">
                <div class="promotion__img">
                    <img 
                        src={`${window.location.origin}/bonuses/whatsapp.png`} 
                        alt={Lang('bonuses.preview.name.whatsapp')} />
                </div>

                <p class="promotion__title txt20x26 cWh">
                    {Lang('bonuses.preview.name.whatsapp')}
                </p>

                <div 
                    class="invite-details" 
                    style={{marginBottom: "10px"}}>
                        <p>{Lang('bonuses.preview.descriptions.whatsapp')}</p>
                </div>


                <div className="promotion__rules promotion__rules-bonus">
                    <span>{Lang('bonuses.names.bonus')}</span>
                    <div className="promotion__rules__value">{currency_short(getBonusAmount(), currency.key, currency.symbol)}</div>
                </div>

                <div className="promotion__rules promotion__rules-wager">
                    <span>{Lang('bonuses.names.wager')}</span>
                    <div className="promotion__rules__value">X10</div>
                </div>

                <button 
                    class="btn"
                    onClick={e => {
                        if(!isAuth)
                            return ModalsUtil.toggle('#login_signin');

                        navigate('/profile');
                    }}
                    style={{
                        color: "#14143d",
                    }}
                    >{Lang('pages.promotions.get')}</button>

                <button 
                    class="btn btn--outside"
                    onClick={e => navigate('/promotions/example')}
                    style={{marginTop: "10px"}}
                    >{Lang('pages.promotions.details')}</button>
            </div>
        </li>
    )
}