import {currency_short} from '../../../utils/types';

export default {
    done: {
        title: "Ваш платеж обработан!",
        text: ({amount, currency}) => `Ваш платеж успешно обработан! На ваш баланс зачислено ${currency_short(amount, currency.key, currency.symbol)}`
    },
    bonus: "Вы не можете делать депозит, поскольку находитесь в активной бонусной сессии",
    sww: "Что-то пошло не так, попробуйте чуть позже...",
    method_not_found: "Выбранная платежная система не найдена",
    method_currency_not_supported: "Не удалось найти выбранный метод для Вашей игровой валюты",
    method_minimal_deposit: ({amount, currency}) => `Минимальная сумма пополнения для выбранного метода - ${amount} ${currency}`,
    method_maximal_deposit: ({amount, currency}) => `Максимальная сумма пополнения для выбранного метода - ${amount} ${currency}`
} 