import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/profile/sidebar';
import {useNavigate, useLocation} from 'react-router-dom';
import {logout} from '../../../reducers/user/actions';
import ModalsUtil from '../../../utils/modals';
import Lang from '../../../lang';

export default () => {
    const {active_bonus} = useSelector(Selector),
          dispatch = useDispatch(),
          navigate = useNavigate(),
          location = useLocation();

    return (
        <ul class="profileWr__sideBar">
            <li class="profileWr__item">
                <button class={`profileWr__btn ${location.pathname === '/profile' ? 'active' : ''}`} onClick={e => navigate('/profile')}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="rgba(238,238,255,.5)" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0001 15C8.83002 15 6.01089 16.5306 4.21609 18.906C3.8298 19.4172 3.63665 19.6728 3.64297 20.0183C3.64785 20.2852 3.81546 20.6219 4.02546 20.7867C4.29728 21 4.67396 21 5.42733 21L18.5729 21C19.3262 21 19.7029 21 19.9747 20.7867C20.1847 20.6219 20.3523 20.2852 20.3572 20.0183C20.3635 19.6728 20.1704 19.4172 19.7841 18.906C17.9893 16.5306 15.1702 15 12.0001 15Z" stroke="inherit" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12.0001 12C14.4854 12 16.5001 9.98528 16.5001 7.5C16.5001 5.01472 14.4854 3 12.0001 3C9.51481 3 7.5001 5.01472 7.5001 7.5C7.5001 9.98528 9.51481 12 12.0001 12Z" stroke="inherit" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.profile')}</span>
                </button>
            </li>
            <li class="profileWr__item">
                <button class="profileWr__btn" onClick={e => {
                    if(active_bonus)
                        return ModalsUtil.error(Lang('modals.deposit.bonus.title'), Lang('modals.deposit.bonus.text'))

                    ModalsUtil.toggle('#deposit_modal');
                }}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8L12 16M8 12L16 12M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.deposit')}</span>
                </button>
            </li>
            <li class="profileWr__item">
                <button class="profileWr__btn" onClick={e => {
                    if(active_bonus)
                        return ModalsUtil.error(Lang('modals.cashout.bonus.title'), Lang('modals.cashout.bonus.text'))

                    ModalsUtil.toggle('#cashout_modal');
                }}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 12L16 12M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.cashout')}</span>
                </button>
            </li>
            {/* <li class={`profileWr__btn ${location.pathname === '/profile/transfer' ? 'active' : ''}`} onClick={e => navigate('/profile/transfer')}>
                <button class="profileWr__btn">
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 17H20M20 17L16 13M20 17L16 21M20 7L4 7M4 7L8 3M4 7L8 11" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">Transfer</span>
                </button>
            </li> */}
            <li class="profileWr__item">
                <button class={`profileWr__btn ${location.pathname === '/profile/transactions' ? 'active' : ''}`} onClick={e => navigate('/profile/transactions')}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13L8 13M16 17H8M10 9H8M14 2L8.8 2C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8L4 17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2L20 8L14 2Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.transactions')}</span>
                </button>
            </li>
            {/* <li class="profileWr__item">
                <button class={`profileWr__btn ${location.pathname === '/profile/vouchers' ? 'active' : ''}`} onClick={e => navigate('/profile/vouchers')}>
                    <span class="profileWr__icon">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0007 6.91634H6.87565C6.26786 6.91634 5.68497 6.6749 5.2552 6.24513C4.82543 5.81536 4.58398 5.23246 4.58398 4.62467C4.58398 4.01689 4.82543 3.43399 5.2552 3.00422C5.68497 2.57445 6.26786 2.33301 6.87565 2.33301C10.084 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634H15.1257C15.7334 6.91634 16.3163 6.6749 16.7461 6.24513C17.1759 5.81536 17.4173 5.23246 17.4173 4.62467C17.4173 4.01689 17.1759 3.43399 16.7461 3.00422C16.3163 2.57445 15.7334 2.33301 15.1257 2.33301C11.9173 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634L11.0007 20.6663M1.83398 13.333H20.1673M1.83398 9.84967L1.83398 17.733C1.83398 18.7598 1.83398 19.2731 2.03381 19.6653C2.20957 20.0103 2.49004 20.2907 2.835 20.4665C3.22717 20.6663 3.74055 20.6663 4.76732 20.6663L17.234 20.6663C18.2607 20.6663 18.7741 20.6663 19.1663 20.4665C19.5113 20.2908 19.7917 20.0103 19.9675 19.6653C20.1673 19.2732 20.1673 18.7598 20.1673 17.733V9.84968C20.1673 8.82291 20.1673 8.30953 19.9675 7.91736C19.7917 7.5724 19.5113 7.29193 19.1663 7.11616C18.7741 6.91634 18.2607 6.91634 17.234 6.91634L4.76732 6.91634C3.74056 6.91634 3.22717 6.91634 2.835 7.11616C2.49004 7.29193 2.20957 7.57239 2.03381 7.91736C1.83398 8.30953 1.83398 8.82291 1.83398 9.84967Z" stroke="rgba(238,238,255,.5)" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">Vouchers</span>
                </button>
            </li>  */}
            <li class="profileWr__item">
                <button class={`profileWr__btn ${location.pathname === '/profile/bonuses' ? 'active' : ''}`} onClick={e => navigate('/profile/bonuses')}>
                    <span class="profileWr__icon">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0007 6.91634H6.87565C6.26786 6.91634 5.68497 6.6749 5.2552 6.24513C4.82543 5.81536 4.58398 5.23246 4.58398 4.62467C4.58398 4.01689 4.82543 3.43399 5.2552 3.00422C5.68497 2.57445 6.26786 2.33301 6.87565 2.33301C10.084 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634H15.1257C15.7334 6.91634 16.3163 6.6749 16.7461 6.24513C17.1759 5.81536 17.4173 5.23246 17.4173 4.62467C17.4173 4.01689 17.1759 3.43399 16.7461 3.00422C16.3163 2.57445 15.7334 2.33301 15.1257 2.33301C11.9173 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634L11.0007 20.6663M1.83398 13.333H20.1673M1.83398 9.84967L1.83398 17.733C1.83398 18.7598 1.83398 19.2731 2.03381 19.6653C2.20957 20.0103 2.49004 20.2907 2.835 20.4665C3.22717 20.6663 3.74055 20.6663 4.76732 20.6663L17.234 20.6663C18.2607 20.6663 18.7741 20.6663 19.1663 20.4665C19.5113 20.2908 19.7917 20.0103 19.9675 19.6653C20.1673 19.2732 20.1673 18.7598 20.1673 17.733V9.84968C20.1673 8.82291 20.1673 8.30953 19.9675 7.91736C19.7917 7.5724 19.5113 7.29193 19.1663 7.11616C18.7741 6.91634 18.2607 6.91634 17.234 6.91634L4.76732 6.91634C3.74056 6.91634 3.22717 6.91634 2.835 7.11616C2.49004 7.29193 2.20957 7.57239 2.03381 7.91736C1.83398 8.30953 1.83398 8.82291 1.83398 9.84967Z" stroke="rgba(238,238,255,.5)" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.bonuses')}</span>
                </button>
            </li>
            <li class="profileWr__item">
                <button class={`profileWr__btn ${location.pathname === '/profile/referal' ? 'active' : ''}`} onClick={e => navigate('/profile/referal')}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0001 15.8369C19.456 16.5683 20.7042 17.742 21.6153 19.2096C21.7957 19.5003 21.886 19.6456 21.9172 19.8468C21.9805 20.2558 21.7009 20.7585 21.32 20.9204C21.1326 21 20.9218 21 20.5001 21M16.0001 11.5322C17.4818 10.7959 18.5001 9.26686 18.5001 7.5C18.5001 5.73314 17.4818 4.20411 16.0001 3.46776M14.0001 7.5C14.0001 9.98528 11.9854 12 9.50008 12C7.0148 12 5.00008 9.98528 5.00008 7.5C5.00008 5.01472 7.0148 3 9.50008 3C11.9854 3 14.0001 5.01472 14.0001 7.5ZM2.55931 18.9383C4.15362 16.5446 6.66945 15 9.50008 15C12.3307 15 14.8465 16.5446 16.4409 18.9383C16.7901 19.4628 16.9648 19.725 16.9446 20.0599C16.929 20.3207 16.758 20.64 16.5496 20.7976C16.282 21 15.9139 21 15.1777 21H3.82244C3.08625 21 2.71816 21 2.45052 20.7976C2.24213 20.64 2.07117 20.3207 2.05551 20.0599C2.03541 19.725 2.21004 19.4628 2.55931 18.9383Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.referral')}</span>
                </button>
            </li>
            <li class="profileWr__item">
                <button 
                    class="profileWr__btn"
                    onClick={e => {
                        e.preventDefault();

                        dispatch(logout(navigate));
                    }}>
                    <span class="profileWr__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 16.9998L21 11.9998M21 11.9998L16 6.99982M21 11.9998L9 11.9998M12 16.9998C12 17.2954 12 17.4432 11.989 17.5712C11.8748 18.9018 10.8949 19.9967 9.58503 20.2571C9.45903 20.2821 9.31202 20.2985 9.01835 20.3311L7.99694 20.4446C6.46248 20.6151 5.69521 20.7003 5.08566 20.5053C4.27293 20.2452 3.60942 19.6513 3.26118 18.8723C3 18.288 3 17.5161 3 15.9721L3 8.02751C3 6.48358 3 5.71162 3.26118 5.12734C3.60942 4.3483 4.27293 3.75442 5.08566 3.49435C5.69521 3.29929 6.46246 3.38454 7.99694 3.55503L9.01835 3.66852C9.31212 3.70117 9.45901 3.71749 9.58503 3.74254C10.8949 4.00297 11.8748 5.09786 11.989 6.42843C12 6.55645 12 6.70424 12 6.99982" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" style={{fill: "transparent!important"}}></path>
                        </svg>
                    </span>
                    <span class="profileWr__title">{Lang('pages.profile.navigation.exit')}</span>
                </button>
            </li>
        </ul>
    )
}