import React, {useRef} from 'react';
import Slider from 'react-slick';
import Arrows from './arrows';
import SlidersMap from './map';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/main/slider';
import Lang from '../../../lang';
import {useNavigate} from 'react-router-dom';
import ModalsUtil from '../../../utils/modals';

export default () => {
    const sliderRef = useRef(),
          {sliders, isAuth} = useSelector(Selector),
          navigate = useNavigate();

    return (
        <div className="slider__wrapper">
            <Slider 
                className="slider"
                arrows={true}
                ref={sliderRef}
                nextArrow={<Arrows.NextArrow sliderRef={sliderRef} />}
                prevArrow={<Arrows.PreviousArrow sliderRef={sliderRef} />}>
                {sliders.map((slider, key) => {
                    return (
                        <div key={key}>
                            <div className={`slider__content ${slider.orientation === 'right' ? 'slider__content-reversed' : ''}`}>
                                <div class="sliderContent__img">
                                    <img src={`${window.location.origin}${slider.image}`} alt="" />
                                </div>
                                <div className={`slider__items ${slider.orientation === 'right' ? 'slider__items-reversed' : ''}`}>
                                    <p class="headSlider__toGo">{slider.content.top}</p>
                                    <p class="headSlider__title">{slider.content.main}</p>
                                    <button className={`btn ${slider.orientation === 'right' ? 'btn-reversed' : ''}`} onClick={e => {
                                        e.preventDefault();

                                        if(slider.button_type === 'modal') ModalsUtil.toggle(isAuth ? `#${slider.button_trigger}` : `#login_signin`);
                                        if(slider.button_type === 'external_link_blank') window.open(slider.button_trigger, '_blank');
                                        if(slider.button_type === 'external_link') window.location.href = slider.button_trigger;
                                        if(slider.button_type === 'internal_link_blank') window.open(`${window.location.origin}/${slider.button_trigger}`);
                                        if(slider.button_type === 'internal_link') navigate(`/${slider.button_trigger}`);
                                    }}>
                                        <span class="text txt18x23">
                                            {slider.content.button}
                                        </span>
                                        {/* <span class="icon">
                                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.0007 6.91634H6.87565C6.26786 6.91634 5.68497 6.6749 5.2552 6.24513C4.82543 5.81536 4.58398 5.23246 4.58398 4.62467C4.58398 4.01689 4.82543 3.43399 5.2552 3.00422C5.68497 2.57445 6.26786 2.33301 6.87565 2.33301C10.084 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634H15.1257C15.7334 6.91634 16.3163 6.6749 16.7461 6.24513C17.1759 5.81536 17.4173 5.23246 17.4173 4.62467C17.4173 4.01689 17.1759 3.43399 16.7461 3.00422C16.3163 2.57445 15.7334 2.33301 15.1257 2.33301C11.9173 2.33301 11.0007 6.91634 11.0007 6.91634ZM11.0007 6.91634L11.0007 20.6663M1.83398 13.333H20.1673M1.83398 9.84967L1.83398 17.733C1.83398 18.7598 1.83398 19.2731 2.03381 19.6653C2.20957 20.0103 2.49004 20.2907 2.835 20.4665C3.22717 20.6663 3.74055 20.6663 4.76732 20.6663L17.234 20.6663C18.2607 20.6663 18.7741 20.6663 19.1663 20.4665C19.5113 20.2908 19.7917 20.0103 19.9675 19.6653C20.1673 19.2732 20.1673 18.7598 20.1673 17.733V9.84968C20.1673 8.82291 20.1673 8.30953 19.9675 7.91736C19.7917 7.5724 19.5113 7.29193 19.1663 7.11616C18.7741 6.91634 18.2607 6.91634 17.234 6.91634L4.76732 6.91634C3.74056 6.91634 3.22717 6.91634 2.835 7.11616C2.49004 7.29193 2.20957 7.57239 2.03381 7.91736C1.83398 8.30953 1.83398 8.82291 1.83398 9.84967Z" stroke="#14143D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span> */}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}