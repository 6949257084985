import React from 'react';

export default () => {
    return (
        <div class="profileWr__content invite">
            <div class="profile-invite">
                <div class="head-invite-info">
                    <div class="invite-details">
                        <h1>Invite a person via a special link</h1>
                        <p>$10 Bonus when replenishing an invitee with $20 or more</p>
                    </div>
                </div>
                <div class="invite-redem">
                    <label class="inputBox inputBox--rightSome inputBox--noTitle">
                        <input type="text" placeholder="Enter email" value="michael.mitc@example.com" />
                    </label>
                    <button class="btn">Redeem</button>
                </div>
            </div>
            <hr class="hr" />
            <div class="intite-table">
                <div class="mt24 table__wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Game</th>
                                <th>Free spins</th>
                                <th>Won</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="invite-game-image">
                                        <img src="https://azimut777.net/img/games/1.jpg" alt="" />
                                    </div>
                                </td>
                                <td>1 of 20</td>
                                <td>$120.98</td>
                                <td>Not wagered</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="invite-game-image">
                                        <img src="https://azimut777.net/img/games/1.jpg" alt="" />
                                    </div>
                                </td>
                                <td>1 of 20</td>
                                <td>$120.98</td>
                                <td class="active">Active</td>
                                <td>
                                    <div class="action-invite">
                                        <button class="btn mt24 wA w600">Play</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="invite-game-image">
                                        <img src="https://azimut777.net/img/games/1.jpg" alt="" />
                                    </div>
                                </td>
                                <td>1 of 20</td>
                                <td>$120.98</td>
                                <td>Pending</td>
                                <td>
                                    <div class="action-invite">
                                        <button class="btn mt24 wA w600 button-b">Cancel</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}