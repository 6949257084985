export default {
    title: "FAQ",
    questions: [
        {
            question: "Первый вопрос",
            answer: [
                {
                    title: "Ответ",
                    text: "Ответ на первый вопрос"
                }
            ]
        },
        {
            question: "Второй вопрос",
            answer: [
                {
                    title: "Ответ",
                    text: "Ответ на второй вопрос"
                }
            ]
        }
    ]
}