import components from './components';
import pages from './pages';
import notifications from './notifications';
import bonuses from './bonuses';
import modals from './modals';

export default {
    components,
    notifications,
    bonuses,
    pages,
    modals,
    error: "Ошибка",
    success: "Готово",
    notFound: path => `[${path}]`
}