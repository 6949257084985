import types from './types';
import API from '../../utils/api';

export const loadPayments = () => async dispatch => {
    const response = await API.call({
        path: '/user/payments'
    });

    if(response.success)
        dispatch({
            type: types.payments.loaded,
            payload: response.payments
        });
}