export default {
    countryNotFound: ({country}) => `Не удалось найти страну по ключу ${country}`,
    dataNotApproved: "Указанные данные не были подтверждены, попробуйте чуть позже...",
    dataNotFound: "Данные не были найдены. Попробуйте чуть позже...",
    rules: "Вы должны принять пользовательское соглашение",
    under18: "Вы младше 18-ти",
    invalidDate: "Дата рождения указана неверно",
    username: "Имя пользователя должно содержать в себе от 6-ти до 15-ти латинских букв или цифр",
    usernameExist: "Это имя пользователя уже занято",
    first_name: "Ваше имя должно состоять минимум из 2-ух букв",
    password: "Пароль должен состоять минимум из 6-ти символов",
    country: "Вы забыли выбрать страну",
    email: "e-Mail указан неверно",
    emailExist: "Указанный e-Mail уже используется в системе",
    phoneExist: "Этот номер телефона уже зарегистрирован в системе",
    phoneCountry: "Этот номер телефона не принадлежит стране, которую вы выбрали",
    phone: "Номер телефона указан неверно",
    phoneVerify: "Не удалось проверить Ваш номер телефона. Попробуйте чуть позже...",
    sex: "Вы забыли указать свой пол",
    success: "Вы успешно зарегистрировались!"
}