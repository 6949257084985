import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.bonuses,
    props.user.restrictions.wager,
    (bonuses, wager) => {
        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            active_bonus
        }
    }
)