import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Selector from '../../../../../selectors/pages/profile/tabs/bonuses';
import {cancelBonus, removeBonus, getCashbackBonus} from '../../../../../reducers/user/actions';
import Lang from '../../../../../lang';
import ModalsUtil from '../../../../../utils/modals';
import {currency as currency_format, currency_short, getMaximal} from '../../../../../utils/types';
import PresetSelector from '../../../../../selectors/preset';
import {Helmet} from 'react-helmet';

import Wager from './wager';
import Input from './input';

export default () => {
    const {bonuses, currency} = useSelector(Selector),
          {preset, language} = useSelector(PresetSelector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].my_bonuses)}</title>
            </Helmet>
            <div className="profile-bonus">
                <div className="profile-scroll"></div>
                <Wager />
                <Input />
                <div class="profileWr__content promotion promotion--block" style={{marginTop: '20px'}}>
                    <p class="txt24x30 w700 cWh">{Lang('pages.profile.bonuses.bonuses.title')}</p>
                    <ul class="promotion__list mt24">
                        {bonuses.map((bonus, key) => {
                            return (
                                <li className={`promotion__item ${bonus.status > 1 ? 'bonus-end' : ''}`} key={key}>
                                    <div class="promotion__block">
                                        <div class="promotion__img">
                                            <img 
                                                src={bonus.game ? bonus.game.image : bonus.image} 
                                                alt={bonus.content[language].name} />
                                        </div>

                                        <p class="promotion__title txt16x20 cWh" style={{marginBottom: "5px"}}>{bonus.content[language].name}</p>

                                        <div className="invite-details">
                                            <p>{bonus.content[language].description}</p>
                                        </div>

                                        <div className="promotions__rules__block">
                                            {bonus.details.deposit_include && (
                                                <>
                                                    {bonus.status === 0 ? (
                                                        <div className="promotion__rules promotion__rules-freespins">
                                                            <span>{Lang('bonuses.names.deposit')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.deposits', {deposits: bonus.details.deposits, currency})}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-freespins">
                                                            <span>{Lang('bonuses.names.deposit')}</span>
                                                            <div className="promotion__rules__value">{currency_format(bonus.details.deposit_amount, currency.key, currency.symbol, true)}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {bonus.details.bonus.amount > 0 && (
                                                <>
                                                    {bonus.status === 0 ? (
                                                        <>
                                                            {bonus.details.bonus.amount_type === "multiplier" && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.bonus')}</span>
                                                                    <div className="promotion__rules__value">X{bonus.details.bonus.amount}</div>
                                                                </div>
                                                            )}

                                                            {bonus.details.bonus.amount_type === "percent" && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.bonus')}</span>
                                                                    <div className="promotion__rules__value">+{bonus.details.bonus.amount}%</div>
                                                                </div>
                                                            )}

                                                            {bonus.details.bonus.amount_type === "amount" && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.bonus')}</span>
                                                                    <div className="promotion__rules__value">{currency_format(bonus.details.bonus.amount, currency.key, currency.symbol)}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-bonus">
                                                            <span>{Lang('bonuses.names.bonus')}</span>
                                                            <div className="promotion__rules__value">{currency_format(bonus.details.bonus_amount, currency.key, currency.symbol, true)}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {bonus.details.bonus.freespins_enabled && (
                                                <>
                                                    {bonus.status === 0 ? (
                                                        <>
                                                            {bonus.details.bonus.freespins_type === "fixed" && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.freespins')}</span>
                                                                    <div className="promotion__rules__value">{bonus.details.bonus.freespins_count}</div>
                                                                </div>
                                                            )}

                                                            {bonus.details.bonus.freespins_type === "devider" && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.freespins')}</span>
                                                                    <div className="promotion__rules__value">{getMaximal(Math.round(bonus.details.deposits.from / bonus.details.bonus.freespins_count), bonus.details.bonus.freespins_minimal_count)}-{getMaximal(Math.round(bonus.details.deposits.to / bonus.details.bonus.freespins_count), bonus.details.bonus.freespins_maximal_count)}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-bonus">
                                                            <span>{Lang('bonuses.names.freespins')}</span>
                                                            <div className="promotion__rules__value">{bonus.details.freespins_count}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {bonus.status === 0 ? (
                                                <>
                                                    {bonus.wager.type === 'limit' ? (
                                                        <>
                                                            {bonus.wager.fixed ? (
                                                                <div className="promotion__rules promotion__rules-wager">
                                                                    <span>{Lang('bonuses.names.redeem')}</span>
                                                                    <div className="promotion__rules__value">{currency_format(bonus.wager.value, currency.key, currency.symbol)}</div>
                                                                </div>
                                                            ) : (
                                                                <div className="promotion__rules promotion__rules-wager">
                                                                    <span>{Lang('bonuses.names.redeem')}</span>
                                                                    <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-wager">
                                                            <span>{Lang('bonuses.names.wager')}</span>
                                                            <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="promotion__rules promotion__rules-wager">
                                                    {bonus.wager.type === 'limit' ? (
                                                        <span>{Lang('bonuses.names.redeem')}</span>
                                                    ) : (
                                                        <span>{Lang('bonuses.names.wager')}</span>
                                                    )}
                                                    <div className="promotion__rules__value">{currency_format(bonus.details.wager_amount, currency.key, currency.symbol, true)}</div>
                                                </div>
                                            )}

                                            {/* {bonus.details.deposit_include && (
                                                <>
                                                    {bonus.status === 0 ? (
                                                        <div className="promotion__rules promotion__rules-freespins">
                                                            <span>{Lang('bonuses.names.deposit')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.deposits', {deposits: bonus.details.deposits, currency})}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-freespins">
                                                            <span>{Lang('bonuses.names.deposit')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.deposit_amount, currency})}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {bonus.type === "cashback" && (
                                                <div className="promotion__rules promotion__rules-freespins">
                                                    <span>{Lang('bonuses.names.deposit')}</span>
                                                    <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.deposit_amount, currency})}</div>
                                                </div>
                                            )}

                                            {bonus.status === 0 ? (
                                                <>
                                                    {['multiplier', 'freespins', 'voucher', 'welcome_free', 'welcome_deposit', 'cashback'].includes(bonus.type) && (
                                                        <>
                                                            {bonus.type === 'multiplier' && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.bonus')}</span>
                                                                    <div className="promotion__rules__value">X{bonus.details.multiplier}</div>
                                                                </div>
                                                            )}

                                                            {bonus.type === 'freespins' && (
                                                                <div className="promotion__rules promotion__rules-bonus">
                                                                    <span>{Lang('bonuses.names.freespins')}</span>
                                                                    <div className="promotion__rules__value">{Math.round(bonus.details.deposits.from / bonus.details.freespins_rate)} - 1000</div>
                                                                </div>
                                                            )}

                                                            {bonus.type === 'cashback' && (
                                                                <>
                                                                    <div className="promotion__rules promotion__rules-bonus">
                                                                        <span>{Lang('bonuses.names.bonus')}</span>
                                                                        <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.bonus.amount, currency})}</div>
                                                                    </div>

                                                                    <div className="promotion__rules promotion__rules-bonus">
                                                                        <span>{Lang('bonuses.names.freespins')}</span>
                                                                        <div className="promotion__rules__value">{bonus.details.bonus.freespins}</div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {bonus.type === 'voucher' && (
                                                                <>
                                                                    {bonus.details.bonus.amount > 0 && (
                                                                        <div className="promotion__rules promotion__rules-bonus">
                                                                            <span>{Lang('bonuses.names.bonus')}</span>
                                                                            <div className="promotion__rules__value">{bonus.details.bonus.amount}</div>
                                                                        </div>
                                                                    )}

                                                                    {bonus.details.bonus.freespins > 0 && (
                                                                        <div className="promotion__rules promotion__rules-bonus">
                                                                            <span>{Lang('bonuses.names.freespins')}</span>
                                                                            <div className="promotion__rules__value">{bonus.details.bonus.freespins}</div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}

                                                            {bonus.type === 'welcome_free' && (
                                                                <>
                                                                    {bonus.details.bonus.amount > 0 && (
                                                                        <div className="promotion__rules promotion__rules-bonus">
                                                                            <span>{Lang('bonuses.names.bonus')}</span>
                                                                            <div className="promotion__rules__value">{currency_short(bonus.details.bonus.amount, currency.key, currency.symbol)}</div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}

                                                            {bonus.type === 'welcome_deposit' && (
                                                                <>
                                                                    {bonus.details.bonus.amount > 0 && (
                                                                        <div className="promotion__rules promotion__rules-bonus">
                                                                            <span>{Lang('bonuses.names.bonus')}</span>
                                                                            <div className="promotion__rules__value">+{bonus.details.bonus.amount}%</div>
                                                                        </div>
                                                                    )}

                                                                    {bonus.details.bonus.freespins > 0 && (
                                                                        <div className="promotion__rules promotion__rules-bonus">
                                                                            <span>{Lang('bonuses.names.freespins')}</span>
                                                                            <div className="promotion__rules__value">{bonus.details.bonus.freespins}</div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {bonus.wager.type === 'limit' ? (
                                                        <>
                                                            {bonus.wager.fixed ? (
                                                                <div className="promotion__rules promotion__rules-wager">
                                                                    <span>{Lang('bonuses.names.redeem')}</span>
                                                                    <div className="promotion__rules__value">{currency_format(bonus.wager.value, currency.key. currency.symbol)}</div>
                                                                </div>
                                                            ) : (
                                                                <div className="promotion__rules promotion__rules-wager">
                                                                    <span>{Lang('bonuses.names.redeem')}</span>
                                                                    <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-wager">
                                                            <span>{Lang('bonuses.names.wager')}</span>
                                                            <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {bonus.details.bonus_amount > 0 && (
                                                        <div className="promotion__rules promotion__rules-bonus">
                                                            <span>{Lang('bonuses.names.bonus')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.bonus_amount, currency})}</div>
                                                        </div>
                                                    )}

                                                    {bonus.details.freespins > 0 && (
                                                        <div className="promotion__rules promotion__rules-bonus">
                                                            <span>{Lang('bonuses.names.freespins')}</span>
                                                            <div className="promotion__rules__value">{bonus.details.freespins}</div>
                                                        </div>
                                                    )}

                                                    {bonus.wager.type === 'limit' ? (
                                                        <div className="promotion__rules promotion__rules-wager">
                                                            <span>{Lang('bonuses.names.redeem')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.wager_amount, currency})}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="promotion__rules promotion__rules-wager">
                                                            <span>{Lang('bonuses.names.wager')}</span>
                                                            <div className="promotion__rules__value">{Lang('bonuses.names.amount', {amount: bonus.details.wager_amount, currency})}</div>
                                                        </div>
                                                    )}
                                                </>
                                            )} */}
                                        </div>


                                        {/* {bonus.type === 'multiplier' && (
                                            <div className="promotions__rules__block">
                                                <div className="promotion__rules promotion__rules-wager">
                                                    <span>Redeem</span>
                                                    <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                </div>

                                                <div className="promotion__rules promotion__rules-bonus">
                                                    <span>Bonus</span>
                                                    <div className="promotion__rules__value">2500Р</div>
                                                </div>

                                                <div className="promotion__rules promotion__rules-freespins">
                                                    <span>FreeSpins</span>
                                                    <div className="promotion__rules__value">250</div>
                                                </div>
                                            </div>
                                        )} */}

                                        {(bonus.status === 0) && (
                                            <>
                                                {bonus.details.deposit_include ? (
                                                    <div className="btns" style={{
                                                        marginBottom: '10px'
                                                    }}>
                                                        <button class="btn" onClick={e => ModalsUtil.toggle('#deposit_modal')}>{Lang('bonuses.buttons.deposit')}</button>

                                                        <button class="btn btn-cancel" onClick={e => ModalsUtil.confirm({
                                                            title: Lang('bonuses.modals.cancel.title'),
                                                            text: Lang('bonuses.modals.cancel.text', {content: bonus.content}),
                                                            confirm: Lang('bonuses.modals.cancel.confirm'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                dispatch(cancelBonus(bonus, currency));
                                                            },
                                                            cancel: Lang('bonuses.modals.cancel.cancel'),
                                                            onCancel: () => ModalsUtil.close()
                                                        })}>
                                                            <img src={`${window.location.origin}/img/components/mdi_trash.svg`} alt="Remove icon" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="btns" style={{
                                                        marginBottom: '10px'
                                                    }}>
                                                        <button class="btn" style={{
                                                            opacity: bonus.disabled ? 0.7 : 1,
                                                            cursor: bonus.disabled ? 'no-drop' : 'pointer'
                                                        }} onClick={e => {
                                                            if(bonus.disabled)
                                                                return;

                                                            dispatch(getCashbackBonus(bonus, currency));
                                                        }}>{!bonus.disabled ? Lang('bonuses.buttons.get') : Lang('bonuses.buttons.wait')}</button>

                                                        <button class="btn btn-cancel" onClick={e => ModalsUtil.confirm({
                                                            title: Lang('bonuses.modals.cancel_cashback.title'),
                                                            text: Lang('bonuses.modals.cancel_cashback.text', {content: bonus.content}),
                                                            confirm: Lang('bonuses.modals.cancel_cashback.confirm'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                dispatch(cancelBonus(bonus, currency));
                                                            },
                                                            cancel: Lang('bonuses.modals.cancel_cashback.cancel'),
                                                            onCancel: () => ModalsUtil.close()
                                                        })}>
                                                            <img src={`${window.location.origin}/img/components/mdi_trash.svg`} alt="Remove icon" />
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {(bonus.status === 1) && (
                                            <>
                                                {bonus.freespins_session && bonus.freespins_session.left > 0 && (
                                                    <button class="btn" style={{color: 'rgb(20, 20, 61)', marginBottom: '10px'}} onClick={e => {
                                                        navigate(`/bonus/${bonus.id}`)
                                                    }}>Фриспины ({bonus.freespins_session.left})</button>
                                                )}
                                            </>
                                        )}

                                        {bonus.status === 2 && (
                                            <div className="bonus-status won">{Lang('pages.profile.bonuses.bonuses.buttons.won', {amount: bonus.details.end_balance, currency})}</div>
                                        )}

                                        {bonus.status === 3 && (
                                            <div className="bonus-status lose">{Lang('pages.profile.bonuses.bonuses.buttons.canceled')}</div>
                                        )}

                                        {bonus.status === 4 && (
                                            <div className="bonus-status lose">{Lang('pages.profile.bonuses.bonuses.buttons.lose')}</div>
                                        )}

                                        {bonus.status === 5 && (
                                            <div className="bonus-status rules">{Lang('pages.profile.bonuses.bonuses.buttons.rules')}</div>
                                        )}

                                        <button class="btn button-b" style={{marginBottom: '10px', color: "#fff"}}>{Lang('pages.profile.bonuses.bonuses.buttons.bonus_info')}</button>

                                        {(bonus.status === 1) && (
                                            <>
                                                <button class="btn btn-cancel btn-cancel-full" onClick={e => ModalsUtil.confirm({
                                                    title: Lang('bonuses.modals.remove_deposit.title'),
                                                    text: Lang('bonuses.modals.remove_deposit.text', {content: bonus.content}),
                                                    confirm: Lang('bonuses.modals.remove_deposit.confirm'),
                                                    onConfirm: () => {
                                                        dispatch(removeBonus(bonus, currency));
                                                    },
                                                    cancel: Lang('bonuses.modals.remove_deposit.cancel'),
                                                    onCancel: () => ModalsUtil.close()
                                                })}>
                                                    {Lang('pages.profile.bonuses.bonuses.buttons.remove')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}