import types from './types';
import userTypes from '../user/types';
import API from '../../utils/api';
import Cookies from 'react-cookies';
import ModalsUtil from '../../utils/modals';
import toastr from '../../utils/toastr';
import Socket from '../../utils/websocket';
import Lang from '../../lang';

export const setSignUpStage = stage => {
    return {
        type: types.signup.stage,
        payload: stage
    }
}

export const approveAccountData = data => async dispatch => {
    dispatch({
        type: types.signup.formReset
    });

    dispatch({
        type: types.toggleButton,
        payload: 'approveAccountInformation'
    });

    const response = await API.call({
        path: '/auth/approve',
        body: {
            username: data.username,
            email: data.email,
            phone: data.phone,
            password: data.password,
            country: data.country
        }
    });

    if(!response.success)
        if(response.failed) {
            dispatch({
                type: types.signup.form,
                payload: {
                    error: Lang('notifications.system.failed')
                }
            });
        } else {
            dispatch({
                type: types.signup.form,
                payload: response.data
            });
        }

    if(response.success) {
        dispatch({
            type: types.signup.token,
            payload: response.token
        });

        dispatch({
            type: types.signup.stage,
            payload: 2
        });
    }
    dispatch({
        type: types.toggleButton,
        payload: 'approveAccountInformation'
    });
}

export const createAccount = (data, token) => async dispatch => {
    dispatch({
        type: types.signup.formReset
    });

    dispatch({
        type: types.toggleButton,
        payload: 'signup'
    });

    const response = await API.call({
        path: '/auth/signup',
        body: {
            token,
            first_name: data.first_name,
            last_name: data.last_name,
            sex: data.sex === null ? 'undefined' : (data.sex === 1 ? 'male' : 'female'),
            birth: data.birth,
            confirm: data.confirm ? 1 : 0,
            mailing: data.mailing ? 1 : 0
        }
    });

    if(!response.success)
        if(response.failed) {
            dispatch({
                type: types.signup.form,
                payload: {
                    error: Lang('notifications.system.failed')
                }
            });
        } else {
            dispatch({
                type: types.signup.form,
                payload: response.data
            });
        }

    if(response.success) {
        Cookies.save('session', response.session, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/'
        });

        Cookies.remove('ref', {
            path: '/'
        });

        // Getting user
        // const userResponse = await API.call({
        //     path: '/user/get'
        // });

        // if(userResponse.success) {
        //     dispatch({
        //         type: userTypes.loaded,
        //         payload: userResponse
        //     });

        //     ModalsUtil.close();
        //     toastr.success(Lang('notifications.auth.signup.success'));
    
        //     Socket.authorize();
        // }

        window.location.pathname = '/';
    }

    dispatch({
        type: types.toggleButton,
        payload: 'signup'
    });
}

export const login = (data) => async dispatch => {
    dispatch({
        type: types.signin.formReset
    });

    dispatch({
        type: types.toggleButton,
        payload: 'signin'
    });

    const response = await API.call({
        path: '/auth/signin',
        body: data
    });

    if(!response.success)
        if(response.failed) {
            dispatch({
                type: types.signin.form,
                payload: {
                    error: Lang('notifications.system.failed')
                }
            });
        } else {
            dispatch({
                type: types.signin.form,
                payload: response.data
            });
        }

    if(response.success) {
        Cookies.save('session', response.session, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/'
        });

        window.location.pathname = '/';

        // Getting user
        // const userResponse = await API.call({
        //     path: '/user/get'
        // });

        // if(userResponse.success) {
        //     dispatch({
        //         type: userTypes.loaded,
        //         payload: userResponse
        //     }); 

        //     ModalsUtil.close();
        //     toastr.success(Lang('notifications.auth.signin.success'));

        //     Socket.authorize();
        // }
    }

    dispatch({
        type: types.toggleButton,
        payload: 'signin'
    });
}