import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/deposit/cashapp';
import {createCashAppRequest} from '../../../reducers/deposit/actions';
import ModalsUtil from '../../../utils/modals';
import Input from '../../modals/signin/input';

export default () => {
    const {form, button} = useSelector(Selector),
          dispatch = useDispatch();

    const [data, setData] = useState({
        cashtag: "",
        amount: ""
    });

    return (
        <form class="popup__contain" onSubmit={e => {
            e.preventDefault();

            if(button)
                return;

            dispatch(createCashAppRequest(data));
        }}>
            <div class="popup__top">
                <p class="popup__title">CashApp</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>
            <p class="inputAgree__text cGry mb24">To deposit via CashApp, you need to create an application, specifying your $cashtag and the amount</p>

            {form.error !== null && (
                <div class="inputBox--error" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{form.error}</p>
                    </div>
                </div>
            )}

            <Input
                type="text"
                label="Your $cashtag"
                placeholder="$cashtag"
                name="cashtag"
                updateState={value => setData({...data, cashtag: value})}
                inputs={form.inputs}
                defaultValue={data.cashtag} />

            <Input
                type="number"
                label="Amount"
                placeholder="100"
                name="amount"
                updateState={value => setData({...data, amount: parseInt(value)})}
                inputs={form.inputs}
                defaultValue={data.amount} />

            <button 
                class="btn"
                style={{
                    opacity: !button ? 1 : 0.7,
                    cursor: !button ? 'pointer' : 'no-drop'
                }}>{!button ? 'Create request' : 'Please wait..'}</button>
        </form>
    )
}