export default () => (
<svg xmlns="http://www.w3.org/2000/svg" width="98" height="98" viewBox="0 0 98 98" fill="none">
<g clip-path="url(#clip0_132_37)">
<rect width="98" height="44" fill="#FFAA04"/>
<rect y="54" width="98" height="44" fill="#FFAA04"/>
</g>
<defs>
<clipPath id="clip0_132_37">
<rect width="98" height="98" fill="white"/>
</clipPath>
</defs>
</svg>
)