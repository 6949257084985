import types from './types';
import API from '../../utils/api';

export const setGameId = (gameId) => dispatch => {
    dispatch({
        type: types.setGameId,
        payload: gameId
    });
}

export const loadGame = gameId => async dispatch => {
    const response = await API.call({
        path: '/games/get',
        body: {
            gameId
        }
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        });
}

export const toggleFavorite = gameId => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'favorite'
    });

    const response = await API.call({
        path: '/games/toggleFavorite',
        body: {
            gameId
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'favorite'
    });

    if(response.success)
        dispatch({
            type: types.favorite,
            payload: {
                game: gameId,
                state: response.state
            }
        });
}

export const toggleDemo = state => {
    return {
        type: types.toggleDemo,
        payload: state
    }
}

export const getSession = (gameId, isDemo) => async dispatch => {
    const response = await API.call({
        path: '/games/getSession',
        body: {
            gameId,
            demo: isDemo ? 1 : 0
        }
    });

    if(response.success)
        dispatch({
            type: types.session,
            payload: {
                session: response.data,
                demo: isDemo
            }
        });
}

export const setModalGame = game => {
    return {
        type: types.modalGame,
        payload: game
    }
}