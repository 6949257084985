export default {
    loaded: 'user.loaded',
    logout: 'user.logout',
    balance: 'user.balance',
    stats: 'user.stats',
    avatar: 'user.avatar',
    toggleButton: 'user.toggle.button',
    stats: 'user.stats',
    restrictions: {
        wager: 'user.restrictions.wager'
    },
    bonuses: {
        new: 'user.bonuses.new',
        update: 'user.bonuses.update',
        remove: 'user.bonuses.remove',
        toggle: 'user.bonuses.toggle'
    }
}