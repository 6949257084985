import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.auth.signin.form,
    props.auth.buttons.signin,
    (form, button) => {
        return {
            form,
            button
        }
    }
)