import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.cashout.forms.freekassa,
    props.cashout.buttons.freekassa,
    props.user.currency,
    (form, button, currency) => {
        return {
            form, 
            button,
            currency
        }
    }
)
