import {React, createRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/game/content';
import {toggleFavorite, toggleDemo, getSession} from '../../../reducers/game/actions';
import ModalsUtil from '../../../utils/modals';
import {Link, useNavigate} from 'react-router-dom';
import Provider from '../../../utils/provider';
import LoaderPage from '../../components/loader/page';
import Device from '../../../utils/device';
import Winners from '../../components/winners';
import waitElement from '../../../utils/waitElement';
import Lang from '../../../lang';
import Icons from '../main/icons';
import Providers from '../main/providers';
import PresetSelector from '../../../selectors/preset';
import {Helmet} from 'react-helmet';

const iframe = createRef(),
      demo = createRef();

export default ({bonus}) => {
    const navigate = useNavigate(),
          {preset, language} = useSelector(PresetSelector);

    useEffect(() => {
        window.onmessage = e => {
            const methods = ['closeGame', 'notifyCloseContainer', 'GAME_MODE:CLOSED', 'GAME_MODE:LOBBY'];

            let closing = false;
            for(const method of methods) {
                if(!closing && JSON.stringify(e.data).indexOf(method) >= 0) {
                    closing = true;
                    navigate(-1);
                }
            }
        }
    });

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', bonus.game.name + ` [FREESPINS]`)}</title>
            </Helmet>
            <section class="game">
                <div class="game__wrapper wrapper">
                    {!Device.isMobile() && (
                        <>
                            <div class="game__top">
                                <h4 class="game__title">
                                    <Icons.Bonus_buy style={{
                                        fill: "#ffb61d",
                                        width: "40px",
                                        height: "40px",
                                        float: "left",
                                        marginRight: "10px"
                                    }} />
                                    {bonus.game.name} - <Link to={`/provider/${bonus.game.title}`} class="cYel link"> {Provider.getName(bonus.game.title)}</Link>
                                </h4>
                                <div class="game__nav">
                                    <div class="game__navBtns navBtns game__navItem">
                                        <button 
                                            class="navBtn"
                                            onClick={e => {
                                                if(!bonus.session)
                                                    return;

                                                if(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled) {
                                                    const win = iframe.current;

                                                    if(win)
                                                        if(win.requestFullscreen) win.requestFullscreen();
                                                        else if(win.webkitRequestFullscreen) win.webkitRequestFullscreen();
                                                        else if(win.mozRequestFullScreen) win.mozRequestFullScreen();
                                                        else if(win.msRequestFullscreen) win.msRequestFullscreen();
                                                }
                                            }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5005 5.23272C16.5005 5.64698 16.1648 5.98281 15.7506 5.98281C15.3365 5.98281 15.0007 5.64698 15.0007 5.23272L15.0007 4.03901L11.7342 7.26016C11.4392 7.55099 10.9644 7.54762 10.6737 7.25261C10.3829 6.95761 10.3863 6.48269 10.6812 6.19186L13.9218 2.99628L12.7648 2.99627C12.3506 2.99627 12.0149 2.66044 12.0149 2.24618C12.0149 1.83191 12.3506 1.49609 12.7648 1.49609L15.7506 1.49611C16.1648 1.49611 16.5005 1.83194 16.5005 2.2462L16.5005 5.23272ZM1.5 5.23337C1.5 5.64754 1.83582 5.98329 2.25008 5.98329C2.66433 5.98329 3.00015 5.64754 3.00015 5.23337L3.00016 4.05797L6.19946 7.25661C6.49238 7.54947 6.96731 7.54947 7.26023 7.25661C7.55315 6.96374 7.55315 6.48892 7.26023 6.19606L4.06092 2.99742L5.23659 2.99741C5.65084 2.99741 5.98666 2.66165 5.98666 2.24748C5.98666 1.83331 5.65083 1.49756 5.23658 1.49756L2.25007 1.49758C1.83582 1.49758 1.5 1.83334 1.5 2.2475L1.5 5.23337ZM15.7504 12.0131C16.1645 12.0131 16.5003 12.3489 16.5003 12.7632L16.5003 15.7497C16.5003 16.1639 16.1645 16.4998 15.7504 16.4998L12.7645 16.4998C12.3504 16.4998 12.0146 16.164 12.0146 15.7497C12.0146 15.3354 12.3504 14.9996 12.7645 14.9996L13.9399 14.9996L10.6773 11.7362C10.3844 11.4433 10.3844 10.9684 10.6773 10.6754C10.9701 10.3825 11.445 10.3825 11.7378 10.6754L15.0005 13.9388L15.0005 12.7632C15.0005 12.3489 15.3362 12.0131 15.7504 12.0131ZM1.50025 12.7625C1.50025 12.3483 1.83607 12.0126 2.25032 12.0126C2.66458 12.0126 3.0004 12.3483 3.0004 12.7625L3.0004 13.9195L6.19585 10.679C6.48668 10.3841 6.96159 10.3807 7.25659 10.6715C7.55159 10.9622 7.55496 11.4371 7.26413 11.732L4.04311 14.9985L5.23683 14.9985C5.65109 14.9985 5.98691 15.3342 5.98691 15.7484C5.9869 16.1626 5.65108 16.4983 5.23682 16.4983L2.25032 16.4983C1.83607 16.4983 1.50025 16.1625 1.50025 15.7484L1.50025 12.7625Z" fill="inherit"></path>
                                            </svg>
                                        </button>
                                        <button
                                            style={{
                                                cursor: 'pointer'
                                            }} 
                                            class="navBtn"
                                            onClick={e => {
                                                e.preventDefault();
                                                navigate(-1);
                                            }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L1 15M1 1L15 15" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div class="game__game game__game--img">
                        <iframe
                            width="100%"
                            height="100%"
                            src={bonus.session}
                            ref={iframe}></iframe>  
                    </div>
                </div>
            </section>
            {!Device.isMobile() && <Winners />}
            <Providers />
        </>
    )
}