import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.user.restrictions.wager,
    props.main.currency,
    props.user.bonuses,
    props.user.balance,
    (wager, currency, bonuses, balance) => {
        return {
            wager,
            currency,
            bonuses,
            balance
        }
    }
)