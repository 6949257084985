import API from '../../utils/api';
import types from './types';
import Toastr from '../../utils/toastr';
import ModalsUtil from '../../utils/modals';

export const loadDeposit = () => async dispatch => {
    const response = await API.call({
        path: '/deposit/load'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        });
}

export const cancelPendingOrder = id => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'cancel_pending_order'
    });

    const response = await API.call({
        path: '/deposit/cashapp/cancel',
        body: {
            id
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'cancel_pending_order'
    });

    if(response.success)
        dispatch({
            type: types.cashapp.removePayment
        });
    else
        Toastr.error(response.error);
}

export const confirmPendingOrder = id => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'confirm_pending_order'
    });

    const response = await API.call({
        path: '/deposit/cashapp/confirm',
        body: {
            id
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'confirm_pending_order'
    });

    if(response.success)
        dispatch({
            type: types.cashapp.payment,
            payload: response.payment
        });
    else
        Toastr.error(response.error);
}

export const skipBonus = (bonusId, nextWindow) => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'skip_bonus'
    });

    dispatch({
        type: types.setError,
        payload: null
    });

    const response = await API.call({
        path: '/deposit/bonus',
        body: {
            bonusId
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'skip_bonus'
    });

    if(response.success)
        dispatch({
            type: types.setWindow,
            payload: nextWindow
        });
    else
        dispatch({
            type: types.setError,
            payload: response.error
        });
}

export const clear = () => {
    return {
        type: types.clear
    }
}

export const createCashAppRequest = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: "cashapp_create_request"
    });

    dispatch({
        type: types.cashapp.clear
    });

    const response = await API.call({
        path: '/deposit/cashapp/request',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'cashapp_create_request'
    });

    if(response.success) {
        dispatch({
            type: types.cashapp.payment,
            payload: response.payment
        });

        dispatch({
            type: types.setWindow,
            payload: 'cashapp-request'
        });
    } else {
        dispatch({
            type: types.cashapp.updateForm,
            payload: {
                error: response.error,
                inputs: response.inputs || []
            }
        });
    }
}

export const createFreeKassaPayment = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: "freekassa_payment"
    });

    dispatch({
        type: types.freekassa.clear
    });

    const response = await API.call({
        path: '/deposit/freekassa/request',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'freekassa_payment'
    });

    if(response.success) {
        // ModalsUtil.close();
        // window.open();

        window.location.href = response.url;
    } else {
        dispatch({
            type: types.freekassa.updateForm,
            payload: {
                error: response.error,
                inputs: response.inputs || []
            }
        });
    }
}

export const setWindow = window => {
    return {
        type: types.setWindow,
        payload: window
    }
}

export const setBonusId = bonusId => {
    return {
        type: types.setBonusId,
        payload: bonusId
    }
}

export const showPendingDeposit = () => dispatch => {
    dispatch({
        type: types.setWindow,
        payload: 'cashapp'
    });

    ModalsUtil.toggle('#deposit_modal'); 
}