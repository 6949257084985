import {currency, currency as currencyFormat} from '../../../utils/types';

export default {
    title: "Бонусы",
    preview: {
        name: {
            cashback: "Cashback бонус",
            whatsapp: "Подключите WhatsApp",
            phone: "Верификация номера"
        },
        descriptions: {
            cashback: "Сделайте депозит на сумму от 500₽ и получите кэшбэк бонус в виде 50% от депозита + бесплатные вращения",
            phone: "Подтвердите свой номер телефона и получите бесплатные 50₽ на свой баланс",
            whatsapp: "Подключите WhatsApp к Вашему профилю и получите бесплатные 50₽ на свой баланс"
        }
    },
    list: {
        name: {
            cashback: "Cashback бонус",
            multiplier: ({multiplier, redeem}) => `Умножьте свой депозит в ${multiplier} раз(а)!`,
            welcome_free: "Приветственный бонус",
            welcome_deposit: "Первый депозит",
            deposit_bonus: ({bonus, deposits, currency}) => `Получите +${bonus.amount}% к своему депозиту`,
            freespins: ({freespins, deposits, game_name, currency}) => `Фриспины в игре ${game_name}`
        },
        descriptions: {
            cashback: ({bonus, deposit_amount, game_name, currency}) => `Cashback бонус за Ваш депозит в размере ${currencyFormat(deposit_amount, currency.key, currency.symbol, false, '0')}. При активации бонуса вы получите ${bonus.freespins} фриспинов в игре ${game_name}, а так же бонус в размере ${currencyFormat(bonus.amount, currency.key, currency.symbol, false, '0')}`,
            welcome_free: ({bonus, currency}) => `Активируйте этот бонус и получите бесплатные ${currencyFormat(bonus.amount, currency.key, currency.symbol, false, '0')} на свой баланс`,
            welcome_deposit: ({bonus, deposits, currency}) => `Активируйте этот бонус и получите +${bonus.amount}% к своему первому депозиту депозиту, а так же ${bonus.freespins} бесплатных вращений в случайной игре!`,
            multiplier: ({multiplier, redeem}) => `Активируйте этот бонус и сделайте депозит. Он умножется в ${multiplier} раз(а), но минимальный вывод будет в ${redeem} раз больше депозита!`,
            deposit_bonus: ({bonus, deposits, currency}) => `Активируйте этот бонус и сделайте депозит на сумму от  ${currencyFormat(deposits.from, currency.key, currency.symbol, false, '0,0')} и вы получите +${bonus.amount}% в качестве бонуса`,
            freespins: ({freespins, deposits, game_name, currency}) => `Активируйте этот бонус и сделайте депозит на сумму от ${currencyFormat(deposits.from, currency.key, currency.symbol, false, '0,0')} и вы получите бесплатные вращения в игре ${game_name}`
        }
    },
    buttons: {
        deposit: "Депозит",
        get: "Получить",
        wait: "Подождите..",
    },
    modals: {
        cancel: {
            title: "Вы уверены?",
            text: ({content}, language) => `Вы действительно хотите отменить бонус «<b>${content[language].name}</b>»? Вы сможете активировать его повторно позднее.`,
            confirm: "Да, отменить",
            cancel: "Нет"
        },
        cancel_cashback: {
            title: "Вы уверены?",
            text: ({content}, language) => `Вы действительно хотите удалить «<b>${content[language].name}</b>»?`,
            confirm: "Да, отменить",
            cancel: "Нет"
        },
        remove_deposit: {
            title: "Вы уверены?",
            text: ({content}, language) => `Вы действительно хотите удалить бонус «<b>${content[language].name}</b>»? Все ограничения с Вашего аккаунта будут сняты, но <b>ваш баланс будет обнулен</b>.`,
            confirm: "Да, отменить",
            cancel: "Нет"
        },
        bonus_icon: {
            wager: {
                title: 'Что это такое?',
                text: 'Эта иконка возле вашего баланса означает, что в данный момент вы отыгрываете бонусную сессию. Для подробностей перейдите в раздел «<b>Мои бонусы</b>» в вашем профиле.'
            }
        }
    },
    wager: {
        title: "Отыгрыш бонуса",
        limit: ({content, mode, wager, currency}, language) => `На вашем аккаунте установлен лимит после активации бонуса «<b>${content[language].name}</b>». Это означает, что для того, чтобы закончить бонус, ваш баланс должен достигнуть отметки в <b>${currencyFormat(wager.result, currency.key, currency.symbol)}</b>, после чего ваша бонусная сессия будет завершена, а баланс будет разблокирован.`,
        wager: ({content, mode, wager, currency}, language) => `На вашем аккаунте установлен вейджер после активации бонуса «<b>${content[language].name}</b>». Это означает, что для того, чтобы закончить бонус, вы должны совершить спинов на сумму в <b>${currencyFormat((wager.amount * wager.value), currency.key, currency.symbol)}</b>, после чего ваша бонусная сессия будет завершена, а баланс будет разблокирован.`
    },
    game: {
        bonus: {
            title: 'Упс!',
            text: `Эта игра недоступна в бонусной сессии. Вы можете посмотреть список доступных игр во вкладке «Отыгрыш бонуса»`
        },
        freespins: {
            title: 'Внимание!',
            text: `У вас имеется активный бонус, в котором остались бесплатные вращения, поэтому Ваш вейджер не будет отыгрываться. Мы рекомендуем Вам доиграть бесплатные вращения, после чего перейти в другие игры.`,
            confirm: ({count}) => `Бесплатные вращения (${count})`,
            cancel: "Перейти в игру"
        },
        fish_no_wager: {
            title: 'Внимание!',
            text: 'Cтавки в этой игре не учитываются при отыгрывании вейджера',
            confirm: 'Хорошо',
            cancel: 'Поищу другую'
        }
    },
    names: {
        redeem: "Отыгрыш",
        wager: 'Вейджер',
        bonus: 'Бонус',
        freespins: 'Фриспины',
        deposit: 'Депозит',
        deposits: ({deposits, currency}) => {
            const {from, to} = deposits;

            if(from && to)
                return `${currencyFormat(from, currency.key, currency.symbol, true)}-${currencyFormat(deposits.to, currency.key, currency.symbol, true)}`;

            if(from > 0)
                return `От ${currencyFormat(from, currency.key, currency.symbol, true)}`;

            return '[?]';
        },
        amount: ({amount, currency}) => {
            return `${currencyFormat(amount, currency.key, currency.symbol, true)}`
        }
    }
}