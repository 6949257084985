import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.main.winners,
    props.user.isAuth,
    props.main.countrys,
    props.user.currency,
    (winners, isAuth, countrys, currency) => {
        return {
            winners,
            isAuth,
            countrys,
            currency
        }
    }
)