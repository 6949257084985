import React, {createRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/profile';
import {changeAvatar} from '../../../../reducers/user/actions';
import ProfileUtil from '../../../../utils/profile';
import Toastr from '../../../../utils/toastr';
import Lang from '../../../../lang';
import {currency, rate, phoneFormat} from '../../../../utils/types';
import PresetSelector from '../../../../selectors/preset';
import {Helmet} from 'react-helmet';

const plus = createRef(),
      input = createRef(),
      form = createRef();

export default () => {
    const {user, button, stats, country} = useSelector(Selector),
          {preset, language} = useSelector(PresetSelector),
          dispatch = useDispatch();

    useEffect(() => {
        if(plus.current)
            if(button) {
                plus.current.style.transition = 'transform 10s linear';
                plus.current.style.transform = 'rotate(3600deg)';
            } else {
                plus.current.style.transition = 'transform 0ms';
                plus.current.style.transform = 'rotate(0deg)';
            }
    });

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].profile)}</title>
            </Helmet>
            <div class="profileWr__content profile">
                <div class="profile__top">
                    <div class="profile__avatarBlock">
                        <button class="profile__avatar">
                            <div class="profile__avatarImg">
                                <img 
                                    src={user.avatar ? `${window.location.origin}${user.avatar}` : `${window.location.origin}/img/avatar.svg`} 
                                    alt="" />
                                
                                <form
                                    encType="multipart/form-data"
                                    style={{display: 'none'}}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        dispatch(changeAvatar(new FormData(e.target)));
                                    }}>
                                        <input
                                            ref={input}
                                            style={{display: 'none'}}
                                            type="file"
                                            accept=".png, .jpg"
                                            name="avatar"
                                            onChange={e => {
                                                const file = e.target.files[0],
                                                    size = file.size / (2 ** 20);

                                                if(!['image/jpeg', 'image/png'].includes(file.type))
                                                    return Toastr.error('Select an image in PNG or JPEG format');

                                                if(size > 1)
                                                    return Toastr.error('The maximum file size is 1 MB');

                                                if(form.current)
                                                    form.current.click();
                                                // dispatch(changeAvatar(file));
                                            }}
                                            hidden />

                                        <button type="submit" ref={form}></button>
                                    </form>
                            </div>
                            <div 
                                class="profile__avatarPlus"
                                ref={plus}
                                style={{
                                    cursor: button ? 'no-drop' : 'pointer',
                                    opacity: button ? 0.7 : 1
                                }}
                                onMouseEnter={e => {
                                    if(!button && plus.current) {
                                        plus.current.style.transition = 'transform 500ms';
                                        plus.current.style.transform = 'rotate(180deg)';
                                    }
                                }}
                                onMouseLeave={e => {
                                    if(!button && plus.current) {
                                        plus.current.style.transition = 'transform 500ms';
                                        plus.current.style.transform = 'rotate(0deg)';
                                    }
                                }}
                                onClick={e => {
                                    if(!button)
                                        if(input.current)
                                            input.current.click();
                                }}>
                                <img 
                                    src={`${window.location.origin}/img/icons/plus.svg`} 
                                    alt="+" />
                            </div>
                        </button>
                        <p class="txt16x20 cWh w600">{user.name}</p>
                        <p class="txt14x18 cGry mt4 w400">{Lang('pages.profile.profile.registered', {timestamp: new Date(user.createdAt).getTime()})}</p>
                    </div>
                    <ul class="profile__infoBlock">
                        <li class="profile__infoItem">
                            <div class="profile__infoIcon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 5C13 6.10457 10.5376 7 7.5 7C4.46243 7 2 6.10457 2 5M13 5C13 3.89543 10.5376 3 7.5 3C4.46243 3 2 3.89543 2 5M13 5V6.5M2 5L2 17C2 18.1046 4.46243 19 7.5 19M7.5 11C7.33145 11 7.16468 10.9972 7 10.9918C4.19675 10.9 2 10.0433 2 9M7.5 15C4.46243 15 2 14.1046 2 13M22 11.5C22 12.6046 19.5376 13.5 16.5 13.5C13.4624 13.5 11 12.6046 11 11.5M22 11.5C22 10.3954 19.5376 9.5 16.5 9.5C13.4624 9.5 11 10.3954 11 11.5M22 11.5L22 19C22 20.1046 19.5376 21 16.5 21C13.4624 21 11 20.1046 11 19V11.5M22 15.25C22 16.3546 19.5376 17.25 16.5 17.25C13.4624 17.25 11 16.3546 11 15.25" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div class="profile__infoRight">
                                <div class="profile__infoNum">{currency(stats.deposits, country.currency, country.currency_symbol)}</div>
                                <div class="profile__infoDesc">{Lang('pages.profile.stats.deposits')}</div>
                            </div>
                        </li>
                        <li class="profile__infoItem">
                            <div class="profile__infoIcon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7L13.1667 7C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div class="profile__infoRight">
                                <div class="profile__infoNum">{currency(stats.cashouts, country.currency, country.currency_symbol)}</div>
                                <div class="profile__infoDesc">{Lang('pages.profile.stats.cashouts')}</div>
                            </div>
                        </li>
                        <li class="profile__infoItem">
                            <div class="profile__infoIcon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 17V16.8498C2 16.5333 2 16.3751 2.02421 16.2209C2.0457 16.084 2.08136 15.9497 2.13061 15.8202C2.18609 15.6743 2.2646 15.5369 2.42162 15.2622L6 9M2 17C2 19.2091 3.79086 21 6 21C8.20914 21 10 19.2091 10 17M2 17V16.8C2 16.52 2 16.38 2.0545 16.273C2.10243 16.1789 2.17892 16.1024 2.273 16.0545C2.37996 16 2.51997 16 2.8 16H9.2C9.48003 16 9.62004 16 9.727 16.0545C9.82108 16.1024 9.89757 16.1789 9.9455 16.273C10 16.38 10 16.52 10 16.8V17M6 9L9.57838 15.2622C9.7354 15.5369 9.81391 15.6743 9.86939 15.8202C9.91864 15.9497 9.9543 16.084 9.97579 16.2209C10 16.3751 10 16.5333 10 16.8498V17M6 9L18 7M14 15V14.8498C14 14.5333 14 14.3751 14.0242 14.2209C14.0457 14.084 14.0814 13.9497 14.1306 13.8202C14.1861 13.6743 14.2646 13.5369 14.4216 13.2622L18 7M14 15C14 17.2091 15.7909 19 18 19C20.2091 19 22 17.2091 22 15M14 15V14.8C14 14.52 14 14.38 14.0545 14.273C14.1024 14.1789 14.1789 14.1024 14.273 14.0545C14.38 14 14.52 14 14.8 14H21.2C21.48 14 21.62 14 21.727 14.0545C21.8211 14.1024 21.8976 14.1789 21.9455 14.273C22 14.38 22 14.52 22 14.8V15M18 7L21.5784 13.2622C21.7354 13.5369 21.8139 13.6743 21.8694 13.8202C21.9186 13.9497 21.9543 14.084 21.9758 14.2209C22 14.3751 22 14.5333 22 14.8498V15M12 3V8" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div class="profile__infoRight">
                                <div class="profile__infoNum">x{rate(stats.cashouts, stats.deposits)}</div>
                                <div class="profile__infoDesc">{Lang('pages.profile.stats.winrate')}</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <hr class="hr" />
                <form class="profile__form">
                    <label class="inputBox ">
                        <p class="inputBox__title ">{Lang('pages.profile.profile.uniqueId')}</p>
                        <input 
                            type="text" 
                            placeholder="Enter name" 
                            value={user.userId}
                            readOnly />
                    </label>
                    <label class="inputBox ">
                        <p class="inputBox__title ">{Lang('pages.profile.profile.nickname')}</p>
                        <input 
                            type="text" 
                            placeholder="Enter name" 
                            value={user.name}
                            readOnly />
                    </label>
                    <label class="inputBox ">
                        <p class="inputBox__title ">{Lang('pages.profile.profile.email')}</p>
                        <input 
                            type="text" 
                            placeholder="Enter email" 
                            value={user.email}
                            readOnly />
                    </label>
                    <label class="inputBox ">
                        <p class="inputBox__title ">{Lang('pages.profile.profile.phone')}</p>
                        <input 
                            type="text" 
                            placeholder="Enter number" 
                            value={phoneFormat(country.code, user.phone)}
                            readOnly />
                    </label>
                    {/* <button class="btnChange mt24">
                        <span class="icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.54661 19.7673C10.9457 20.8316 13.8032 20.7738 16.2502 19.361C20.3157 17.0138 21.7086 11.8153 19.3614 7.74983L19.1114 7.31682M4.63851 16.25C2.2913 12.1845 3.68424 6.98595 7.74972 4.63874C10.1967 3.22597 13.0542 3.16816 15.4533 4.23253M2.49341 16.3336L5.22546 17.0657L5.95751 14.3336M18.0426 9.66565L18.7747 6.9336L21.5067 7.66565" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </span>
                        <span class="text">Change password</span>
                    </button> */}
                </form>
            </div>
        </>
    )
}