import forms from './forms';
import types from './types';

const InitialState = {
    loaded: false,
    window: 'method',
    bonusId: null,
    error: null,
    btc: {
        address: null,
        qr: null
    },
    cashapp: {
        payment: null,
        form: forms.CashApp
    },
    freekassa: {
        form: forms.FreeKassa
    },
    methods: [],
    methods_map: [],
    currencies: [],
    buttons: {
        skip_bonus: false,
        cashapp_create_request: false,
        cancel_pending_order: false,
        confirm_pending_order: false,
        freekassa_payment: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.cashapp.removePayment:
            return {
                ...state,
                window: 'method',
                bonusId: null,
                cashapp: {
                    ...state.cashapp,
                    payment: null
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                methods: payload ? payload.methods : state.methods,
                methods_map: payload ? payload.methods_map : state.methods_map,
                currencies: payload ? payload.currencies : state.currencies,
                btc: payload ? payload.btc : state.btc,
                cashapp: {
                    ...state.cashapp,
                    payment: payload && payload.cashapp.payment ? {
                        ...payload.cashapp.payment,
                        time: Date.now() + payload.cashapp.payment.time
                    } : state.cashapp.payment
                }
            }
        case types.cashapp.payment:
            return {
                ...state,
                cashapp: {
                    ...state.cashapp,
                    payment: {
                        ...payload,
                        time: Date.now() + payload.time
                    }
                }
            }
        case types.cashapp.clear:
            return {
                ...state,
                cashapp: {
                    ...state.cashapp,
                    form: InitialState.cashapp.form
                }
            }
        case types.freekassa.clear:
            return {
                ...state,
                freekassa: {
                    ...state.freekassa,
                    form: InitialState.freekassa.form
                }
            }
        case types.cashapp.updateForm:
            return {
                ...state,
                cashapp: {
                    ...state.cashapp,
                    form: {
                        error: payload.error ? payload.error : state.cashapp.form.error,
                        inputs: state.cashapp.form.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;

                            if(update) {
                                return {
                                    ...input,
                                    ...update
                                }
                            }
                        
                            return input
                        })
                    }
                }
            }
        case types.freekassa.updateForm:
            return {
                ...state,
                freekassa: {
                    ...state.freekassa,
                    form: {
                        error: payload.error ? payload.error : state.freekassa.form.error,
                        inputs: state.freekassa.form.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;

                            if(update) {
                                return {
                                    ...input,
                                    ...update
                                }
                            }
                        
                            return input
                        })
                    }
                }
            }
        case types.clear:
            return {
                ...state,
                window: 'method',
                bonusId: null,
                error: null,
                cashapp: {
                    ...state.cashapp,
                    form: InitialState.cashapp.form
                }
            }
        case types.setError:
            return {
                ...state,
                error: payload
            }
        case types.setBonusId:
            return {
                ...state,
                bonusId: payload
            }
        case types.setWindow:
            return {
                ...state,
                window: payload
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}