export default {
    navigation: {
        home: "Home",
        faq: "FAQ",
        tos: "Terms Of Use",
        bonuses: "Promotions"
    },
    profile: {
        signin: "Sign In",
        signup: "Sign Up",
        deposit: "Deposit",
        cashout: "Cashout",
    }
}