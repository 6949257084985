export default () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_157_6)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 13.4141L17.6568 19.0711C17.8454 19.2533 18.098 19.3541 18.3602 19.3518C18.6224 19.3495 18.8732 19.2444 19.0586 19.059C19.2441 18.8736 19.3492 18.6227 19.3515 18.3606C19.3538 18.0984 19.253 17.8458 19.0708 17.6571L13.4138 12.0001L19.0708 6.34315C19.253 6.15455 19.3538 5.90194 19.3515 5.63975C19.3492 5.37755 19.2441 5.12674 19.0586 4.94133C18.8732 4.75592 18.6224 4.65075 18.3602 4.64848C18.098 4.6462 17.8454 4.74699 17.6568 4.92915L11.9998 10.5861L6.34282 4.92915C6.15337 4.75149 5.90224 4.65451 5.64255 4.65873C5.38287 4.66295 5.13502 4.76803 4.95143 4.95174C4.76785 5.13546 4.66294 5.38339 4.65891 5.64307C4.65488 5.90276 4.75203 6.15382 4.92982 6.34315L10.5858 12.0001L4.92882 17.6571C4.83331 17.7494 4.75713 17.8597 4.70472 17.9817C4.65231 18.1037 4.62473 18.235 4.62357 18.3677C4.62242 18.5005 4.64772 18.6322 4.698 18.7551C4.74828 18.878 4.82254 18.9897 4.91643 19.0835C5.01032 19.1774 5.12197 19.2517 5.24487 19.302C5.36777 19.3523 5.49944 19.3776 5.63222 19.3764C5.765 19.3752 5.89622 19.3477 6.01823 19.2953C6.14023 19.2428 6.25058 19.1667 6.34282 19.0711L11.9998 13.4141Z"/>
</g>
<defs>
<clipPath id="clip0_157_6">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)