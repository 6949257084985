export default {
    title: "Вход",
    username: "Имя пользователя",
    username_placeholder: "Введите имя пользователя",
    password: "Пароль",
    password_placeholder: "Введите пароль",
    tos: `By proceeding you agreed to our <a href="#" class="btnHover cYel w500">TOS</a> and the <a href="#" class="btnHover cYel w500">Privacy Policy</a>`,
    buttons: {
        login: "Войти",
        login_active: "Подождите...",
        register: "Зарегистрироваться"
    }
}