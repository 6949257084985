import {React} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/preset';

export default () => {
    const {preset} = useSelector(Selector);
  
    return (
        <div class="loader" style={{zIndex: 999}}>
            <img
                className="animate__animated animate__pulse animate__infinite" 
                style={{
                height: '40px'
                }}
                src={`${window.location.origin}/logotype`}  
                alt={preset ? preset.general.sitename : ''} />

            <img 
                src={`${window.location.origin}/img/components/loader.svg`} 
                alt={preset ? preset.general.sitename : ''}
                style={{height: '70px'}} />
        </div>
    )
}