import deposit from './deposit';
import cashout from './cashout';
import info from './info';
import jackpot from './jackpot';
import play from './play';
import login from './login';
import register from './register';
import language from './language';

export default {
    deposit,
    cashout,
    info,
    jackpot,
    play,
    login,
    register,
    language
}