export default {
    navigation: {
        home: "Главная",
        faq: "FAQ",
        tos: "Условия соглашения",
        bonuses: "Бонусы",
        support: "Поддержка"
    },
    profile: {
        signin: "Войти",
        signup: "Регистрация",
        deposit: "Внести",
        cashout: "Вывести",
    }
}