import {React, createRef, useEffect} from 'react';
import Device from '../../utils/device';

export default () => {
    const video = createRef();

    useEffect(() => {
        if(video.current)
            video.current.addEventListener('loadedmetadata', e => e.target.play());
    });

    return (
        <div class="bgVideoFull">
            <div class="bgVideoFull__after"></div>
            {Device.isMobile() ? (
                <div class="bgVideoFull__before">
                    <img
                        src={`${window.location.origin}/video/video-mobile.gif`}
                        alt="" />
                </div>
            ) : (
                <div class="bgVideoFull__before mobileNone">
                    <video loop muted ref={video}>
                        <source src={`${window.location.origin}/video/video.mp4`} />
                    </video>
                </div>
            )}
        </div>
    )
}