export default {
    loaded: "deposit.loaded",
    form: "deposit.form",
    toggleButton: "deposit.toggle.button",
    setWindow: "deposit.set.window",
    setBonusId: "deposit.set.bonud.id",
    setError: "deposit.set.error",
    clear: "deposit.clear",
    cashapp: {
        payment: "deposit.cashapp.payment",
        updateForm: "deposit.cashapp.updateForm",
        clear: "deposit.cashapp.clear",
        removePayment: 'deposit.cashapp.remove.payment'
    },
    freekassa: {
        updateForm: "deposit.freekassa.updateForm",
        clear: "deposit.freekassa.clear",
    }
}