import types from './types';
import API from '../../utils/api';

export const setProvider = provider => {
    return {
        type: types.provider,
        payload: provider
    }
}

export const showMore = () => {
    return {
        type: types.show
    }
}
