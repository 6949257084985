import React from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/pages/profile/referal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Toastr from '../../../../utils/toastr';
import Lang from '../../../../lang';
import {currency as currency_format} from '../../../../utils/types';
import PresetSelector from '../../../../selectors/preset';
import {Helmet} from 'react-helmet';

export default () => {
    const {code, stats, currency} = useSelector(Selector),
          {preset, language} = useSelector(PresetSelector);

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].referal)}</title>
            </Helmet>
            <div class="profileWr__content invite">
                <ul class="statickBlock invite__block">
                    <li class="profile__infoItem">
                        <div class="profile__infoIcon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15L8 15C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div class="profile__infoRight">
                            <div class="profile__infoNum">{stats.count}</div>
                            <div class="profile__infoDesc">{Lang('pages.profile.referral.invited')}</div>
                        </div>
                    </li>
                    <li class="profile__infoItem">
                        <div class="profile__infoIcon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7L13.1667 7C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#FFB61D" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div class="profile__infoRight">
                            <div class="profile__infoNum">{currency_format(stats.amount, currency.key, currency.symbol)}</div>
                            <div class="profile__infoDesc">{Lang('pages.profile.referral.earned')}</div>
                        </div>
                    </li>
                </ul>
                <div class="invite__block">
                    <div class="invite__info">
                        <p class="invite__title">{Lang('pages.profile.referral.link.title')}</p>
                        <p class="invite__desc">{Lang('pages.profile.referral.link.descriptions')}</p>
                        <label class="inputBox inputBox--rightSome inputBox--noTitle">
                            <input 
                                type="text" 
                                placeholder="Enter email" 
                                value={`${window.location.origin}?ref=${code}`} />

                            <CopyToClipboard
                                text={`${window.location.origin}?ref=${code}`}
                                onCopy={e => {
                                    Toastr.info(Lang('pages.profile.referral.link.copied'));
                                }}>
                                <button class="inputBox__rightSome">
                                    <img 
                                        src={`${window.location.origin}/img/icons/copy-07.svg`} 
                                        alt="" />
                                </button>
                            </CopyToClipboard>
                            <div class="inputBox__warning">
                            <div class="inputBox__warningIcon">
                                <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                                <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                                <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                            </div>
                        </div>
                        </label>
                    </div>
                    <div class="invite__img">
                        <img 
                            src={`${window.location.origin}/img/components/invite1.png`} 
                            alt="" />
                    </div>
                </div>
                {/* <hr class="hr" />
                <div class="invite__block">
                    <div class="invite__info">
                        <p class="invite__title">Invite a person by phone number</p>
                        <p class="invite__desc">$15 Bonus when depositing an invitee with $20 or more</p>
                        <button class="btn mt24 wA w600">To invite</button>
                    </div>
                    <div class="invite__img">
                        <img 
                            src={`${window.location.origin}/img/components/invite2.png`} 
                            alt="" />
                    </div>
                </div> */}
            </div>
        </>
    )
}