import {React, useState} from 'react';
import InputMask from "react-input-mask";

const getClassName = (inputStatus, isFocused) => {
    let className = 'inputBox';

    if(isFocused) className += ' inputBox--focused';
    if(inputStatus !== 'default') className += ` inputBox--${inputStatus}`;

    return className;
}

export default ({
    type="text",
    label,
    placeholder,
    name,
    updateState,
    inputs,
    defaultValue = "",
    mask=false
}) => {

    const inputData = inputs.filter(input => input.key === name)[0] || false,
          [isFocused, changeFocusState] = useState(false);

    return (
        <label class={getClassName(inputData.status, isFocused)}>
            <p class="inputBox__title">{label}</p>

            <input 
                type={type} 
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onInput={e => {
                    updateState(e.target.value);
                }}
                onFocus={e => changeFocusState(true)}
                onBlur={e => changeFocusState(false)}
                required />

            <div class="inputBox__warning">
                <div class="inputBox__warningIcon">
                    <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                    <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                    <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                </div>
                <p class="txt14x18 error valid">{inputData.error}</p>
                <p class="loading txt14x18">Loading</p>
            </div>
        </label>
    )
}