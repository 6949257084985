import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.auth.signup.token,
    props.auth.buttons.signup,
    (token, button) => {
        return {
            token,
            button
        }
    }
)