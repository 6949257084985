import {React, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../selectors/isAuth';
import {useLocation} from 'react-router-dom';
import ModalsUtil from '../../utils/modals';

import SignUp from './signup';
import SignIn from './signin';
import Game from './game';
import Jackpot from './jackpot';
import Transfer from './transfer';
import Deposit from './deposit';
import Cashout from './cashout';
import BonusInfo from './bonusInfo';
import Fail from './fail';
import Done from './done';
import Confirm from './confirm';
import Info from './info';
import Language from './language';
import BonusRules from './bonusRules';

export default () => {
    const {isAuth} = useSelector(Selector),
          location = useLocation();

    // useEffect(() => {
    //     ModalsUtil.close();
    // }, [location]);
    
    return (
        <>
            {!isAuth ? (
                <>
                    <SignUp />
                    <SignIn />
                </>
            ) : (
                <>
                    <BonusRules />
                    <BonusInfo />
                    <Transfer />
                    <Deposit />
                    <Cashout />
                </>
            )}

            <Game />
            <Jackpot />
            <Fail />
            <Done />
            <Info />
            <Confirm />
            <Language />
        </>
    )
}