import React, {useEffect} from 'react';
import LoaderUtil from '../../../utils/loader';

export default () => {
    useEffect(() => {
        LoaderUtil.hide();
    });

    return (
        <div class="blocker">
            <div class="blocker__title w700 mb24">BONUS CONTRIBUTION</div>
            <div class="blocker__text mt24">Standard game weighting applies to all bonuses. Certain games contribute different percentages to the rollover requirements.</div>
            <div class="table__wrap mt24">
                <table class="table">
                    <thead>
                        <tr>
                            <th>CONTRIBUTION</th>
                            <th>GAMES</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>100%</td>
                            <td>Slot Games (Progressive Jackpot Slots Excluded)</td>
                        </tr>
                        <tr>
                            <td>20%</td>
                            <td>Draw High-Lo And 3 Card Rummy</td>
                        </tr>
                        <tr>
                            <td>10%</td>
                            <td>Table Games (unless stated otherwise) and Video Poker</td>
                        </tr>
                        <tr>
                            <td>5%</td>
                            <td>
                                All Blackjack
                                <br />All Roulette
                                <br />Baccarat
                                <br />Pai Gow
                                <br />Red Dog
                                <br />Pontoon
                                <br />Casino War
                            </td>
                        </tr>
                        <tr>
                            <td>0%</td>
                            <td>
                                Craps
                                <br />Live Casino
                                <br />Ride’m Poker
                                <br />All Games with Progressive Jackpots
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="blocker__text cWh mt24">Examples</div>
            <div class="blocker__text mt24">
                1. A bet of $100 on Roulette or Blackjack will be calculated as $5 towards the rollover wagering requirement.
                <br />2. A bet of $100 in Slots will be calculated as $100 towards the wagering requirement.
            </div>
        </div>
    )
}