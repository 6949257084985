export default {
    // bitcoin: {
    //     text: "+15% к депозиту",
    //     descriptions: "Пополни свой баланс с помощью Bitcoin и получи бонус +15%",
    //     button: "Депозит"
    // },
    cashback: {
        text: "50-75%",
        descriptions: "Сделай депозит и получи кэшбэк в течении всего дня!",
        button: "Депозит"
    },
    live: {
        text: "24/7",
        descriptions: "Напишите нам и мы решим любой ваш вопрос",
        button: "Написать"
    },
    sports: {
        text: "",
        descriptions: "Мы собрали для Вас самые лучшие коэффициенты здесь",
        button: "Подробнее"
    },
    telegram: {
        text: "",
        descriptions: "Подпишитесь на наше сообщество, куда мы выкладываем бесплатные бонусы для Вас",
        button: "Подписаться"
    },
    welcome_bonus: {
        text: "",
        descriptions: "Сделай депозит и получи +200% к депозиту и 100 бесплатных спинов прямо сейчас!",
        button: "Депозит"
    },
    whatsapp: {
        text: "",
        descriptions: "Подключите WhatsApp к Вашему профилю и получите за это бонус",
        button: "Подключить"
    }
}