import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.data,
    props.main.countrys,
    props.profile.payments,
    props.deposit.methods,
    (user, countrys, payments, methods) => {
        return {
            payments,
            methods,
            country: (user && countrys) ? countrys.filter(c => c.code === user.country)[0] || null : null,
        }
    }
)