import {React, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/form/signin';
import {login} from '../../../reducers/auth/actions';
import ModalsUtil from '../../../utils/modals';
import Input from './input'
import Lang from '../../../lang';

export default () => {
    const {form, button} = useSelector(Selector),
          dispatch = useDispatch();

    const [data, setData] = useState({
        username: '',
        password: ''
    });

    return (
        <div class="popup" id="login_signin" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            <form class="popup__contain" onSubmit={e => {
                e.preventDefault();

                if(button)
                    return;
                    
                dispatch(login(data));
            }}>
                <div class="popup__top">
                    <p class="popup__title">{Lang('modals.login.title')}</p>
                    <button
                        type="button"
                        class="popup__close"
                        onClick={e => {
                            e.preventDefault();
                            ModalsUtil.close()
                        }}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                    </button>
                </div>

                {form.error && (
                    <div class="inputBox--error mb24 mt-24" style={{marginTop: '-10px', marginBottom: '20px'}}>
                        <div class="inputBox__warning">
                            <div class="inputBox__warningIcon">
                                <img 
                                    src={`${window.location.origin}/img/icons/x-circle.svg`}
                                    class="error" 
                                    alt="" />
                            </div>
                            <p class="txt14x18 error valid">{Lang(form.error.text, form.error.params)}</p>
                        </div>
                    </div>
                )}

                <Input
                    label={Lang('modals.login.username')}
                    placeholder={Lang('modals.login.username_placeholder')}
                    name="username"
                    updateState={state => setData({...data, username: state})}
                    inputs={form.inputs}
                    defaultValue={data.username} />

                <Input
                    type="password"
                    label={Lang('modals.login.password')}
                    placeholder={Lang('modals.login.password_placeholder')}
                    name="password"
                    updateState={state => setData({...data, password: state})}
                    inputs={form.inputs}
                    defaultValue={data.password} />

                {/* <p class="inputAgree__text" dangerouslySetInnerHTML={{__html: Lang('modals.login.tos')}}></p> */}
                <button
                    type="submit"
                    style={{
                        opacity: button ? 0.7 : 1,
                        cursor: button ? 'no-drop' : 'pointer'
                    }}
                    class="btn mt24">{!button ? Lang('modals.login.buttons.login') : Lang('modals.login.buttons.login_active')}</button>

                <p class="popup__already">
                    <a 
                        href="#" 
                        class="btnHover cYel"
                        onClick={e => {
                            e.preventDefault();
                            ModalsUtil.toggle('#login_signup')
                        }}>{Lang('modals.login.buttons.register')}</a>
                </p>
            </form>
        </div>
    )
}