import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/form/signupAccount';
import {approveAccountData} from '../../../reducers/auth/actions';
import ModalsUtil from '../../../utils/modals';
import Input from './input';
import Lang from '../../../lang';
import PresetSelector from '../../../selectors/preset';

export default ({
    data,
    setData,
    formData
}) => {
    const {button, countrys} = useSelector(Selector),
          dispatch = useDispatch(),
          [countryActive, setCountryActive] = useState(false),
          [country, setCountry] = useState(null),
          {preset} = useSelector(PresetSelector),
          merchant_countrys = countrys.filter(c => preset.currencies.includes(c.currency));

    useEffect(() => {
        if(country === null && merchant_countrys.length === 1) {
            setCountry(merchant_countrys[0]);
            setData({...data, phone: '', country: merchant_countrys[0].code});
        }
    }, [countryActive]);

    return (
        <form 
            class="popup__contain"
            onSubmit={e => {
                e.preventDefault();

                if(button)
                    return;

                dispatch(approveAccountData(data));
            }}>
            <div class="popup__top">
                <p class="popup__title">{Lang('modals.register.title')}</p>
                <button 
                    type="button"
                    class="popup__close"
                    onClick={e => {
                        e.preventDefault();
                        ModalsUtil.close()
                    }}>
                    <img 
                        src={`${window.location.origin}/img/icons/close.svg`} 
                        alt="" />
                </button>
            </div>

            <div class="popup__steps steps">
                <div class="steps__item active">
                    <div class="steps__circle">1</div>
                    <p class="steps__name">{Lang('modals.register.steps.first')}</p>
                </div>
                <div class="steps__item ">
                    <div class="steps__circle">2</div>
                </div>
            </div>

            {formData.error && (
                <div class="inputBox--error mb24 mt-24" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{Lang(formData.error.text, formData.error.params)}</p>
                    </div>
                </div>
            )}
            
            <Input
                label={Lang('modals.register.username')}
                placeholder={Lang('modals.register.username_placeholder')}
                name="username"
                updateState={state => setData({...data, username: state})}
                inputs={formData.inputs}
                defaultValue={data.username} />

            <Input
                type="password"
                label={Lang('modals.register.password')}
                placeholder={Lang('modals.register.password_placeholder')}
                name="password"
                updateState={state => setData({...data, password: state})}
                inputs={formData.inputs}
                isSpecialInput={true}
                defaultValue={data.password} />

            <label className={`inputBox ${countryActive ? 'inputBox--focused' : ''}`}>
                <p class="inputBox__title">{Lang('modals.register.country')}</p>

                <div className="selector" onClick={e => setCountryActive(!countryActive)}>
                    <div className="active-option">
                        {country ? (
                            <>
                                <img src={`${window.location.origin}/img/icons/flags/${country.icon}`} />
                                <div className="country-title">{country.name}</div>
                                <div className={`arrow-down ${countryActive ? '' : 'active'}`}> 
                                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06215 0C0.275079 0 -0.203484 0.867179 0.216099 1.53309L3.40506 6.59424C3.79732 7.21678 4.70492 7.21678 5.09718 6.59424L8.28614 1.53309C8.70572 0.867177 8.22715 0 7.44008 0H1.06215Z"/>
                                    </svg>
                                </div>
                            </>
                        ) : (
                            <>
                                <img src={`${window.location.origin}/img/icons/flags/raphael_question.svg`} />
                                <div className="country-title">{Lang('modals.register.country_select')}</div>
                                <div className={`arrow-down ${countryActive ? 'active' : ''}`}> 
                                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06215 0C0.275079 0 -0.203484 0.867179 0.216099 1.53309L3.40506 6.59424C3.79732 7.21678 4.70492 7.21678 5.09718 6.59424L8.28614 1.53309C8.70572 0.867177 8.22715 0 7.44008 0H1.06215Z"/>
                                    </svg>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={`dropdown ${countryActive ? 'active' : ''}`}>
                    <div className="dropdown-list">
                        {merchant_countrys.filter(c => c.code !== country).map((contry, key) => {
                            return (
                                <div className="option" key={key} onClick={e => {
                                    setData({...data, phone: '', country: contry.code})
                                    setCountry(contry);
                                    setCountryActive(false);
                                }}>
                                    <img src={`${window.location.origin}/img/icons/flags/${contry.icon}`} />
                                    <div className="country-title">{contry.name}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div class="inputBox__warning">
                    <div class="inputBox__warningIcon">
                        <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                        <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                        <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                    </div>
                    <p class="txt14x18 error valid">Errror</p>
                    <p class="loading txt14x18">Loading</p>
                </div>
            </label>

            {country && (
                <Input
                    label={Lang('modals.register.phone')}
                    name="phone"
                    updateState={state => setData({...data, phone: state})}
                    inputs={formData.inputs}
                    isSpecialInput={true}
                    defaultValue={data.phone}
                    _country={country} />
            )}

            <Input
                type="email"
                label={Lang('modals.register.email')}
                placeholder={Lang('modals.register.email_placeholder')}
                name="email"
                updateState={state => setData({...data, email: state})}
                inputs={formData.inputs}
                defaultValue={data.email} />

            <button 
                type="submit"
                class="btn"
                style={{
                    cursor: button ? 'no-drop' : 'pointer',
                    opacity: button ? 0.7 : 1
                }}>{!button ? Lang('modals.register.buttons.next') : Lang('modals.register.buttons.next_active')}</button>
            <p class="popup__already">
                {Lang('modals.register.buttons.login.title')}                 
                <a 
                    href="#" 
                    class="btnHover cYel"
                    onClick={e => {
                        e.preventDefault();
                        ModalsUtil.toggle('#login_signin')
                    }}>{Lang('modals.register.buttons.login.button')}</a>
            </p>
        </form>
    )
}