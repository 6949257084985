import React, {useEffect} from 'react';
import LoaderUtil from '../../../utils/loader';

export default () => {
    useEffect(() => {
        LoaderUtil.hide();
    }); 

    return (
        <div class="blocker">
            <div class="blocker__title">Terms Of Use</div>
            <ul class="blocker__ul">
                <li class="blocker__li">
                    <span class="blocker__liNum">1.</span>
                    <span class="blocker__text">The personal data of users of this website is controlled and processed by the company Galaktika N.V. (hereinafter referred to as – the "Company" or "we"), the address: Scharlooweg 39, Willemstad, Curaçao. "Company" and "User" at the joint mention hereinafter referred to as "Parties", and each separately – "Party".</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">2.</span>
                    <span class="blocker__text">We follow the principles established by the General Data Protection Regulation (Regulation (EU) 2016/679). This means that personal data is processed by us legally, honestly and transparently; the personal data is collected for certain clear and legitimate purposes and is not further processed in a manner incompatible with these goals ("limitation of goals"); it is adequate, relevant and limited by what is necessary for the purposes for which it is processed ("data minimization"); it is accurate and updated if necessary. All reasonable measures should be taken to ensure that personal data that is inaccurate, taking into account the purposes for which it is processed, is erased or corrected without delay ("accuracy"); stored in a form that allows users to be identified no longer than is necessary for the processing of personal data ("storage restriction"); it is processed in a way that provides adequate protection of personal data, including protection against unauthorized or illegal processing, as well as from accidental loss, destruction or damage using appropriate technical or organizational measures ("integrity and confidentiality").</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">3.</span>
                    <span class="blocker__text">Personal data of users that the Company collects and processes includes name, surname, patronymic, address of residence and contact information, valid email address, relevant payment information, login (username). The Company has the right to request an ID scan copy to establish the identity of the user. All data provided by you must be correct and valid. You are solely responsible for the accuracy, completeness and correctness of the data you provide.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">4.</span>
                    <span class="blocker__text">
                    We use your personal data to identify the identity of the user when registering on our website to identify you for the correctness of payments from the user to the Company and from the Company to the user. We use your payment details (such as the name of the cardholder, credit card number and card expiration date) to provide you with services on our website. We also use your personal data for the following main purposes: to provide you our services; to maintain your account and profile; to communicate with you as part of our services; to provide comments and answers to your questions; to track the dynamics of the use of our website and the quality of our services; to determine the degree of interest in our services; to improve the quality of our services and our website; to notify you of our special offers and services that may be of interest to you; to determine your experience on our website; to transfer winnings to you (if there are appropriate reasons for such an action); to receive information from you, including through surveys; to resolve disputes; for collection of fees (if there are appropriate reasons); to eliminate problems and errors on our website; to prevent potentially prohibited or illegal activities; to enforce our terms and conditions posted on this website.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">5.</span>
                    <span class="blocker__text">Your personal data may be disclosed (transferred) by the Company to any of our subsidiaries or to any business partners (regardless of their territorial location) for the purposes described above in this Policy. We guarantee that such companies are aware of the correct processing of personal data in accordance with the General Data Protection Regulation (Regulation (EU) 2016/679) and comply with the provisions of this Regulatory Act. We and the aforementioned companies may from time to time attract third parties to process your personal data for the purposes indicated above, provided that such processing will be regulated by contractual agreements in the form established by law. Your personal data may also be transferred to the appropriate government, regulatory or executive body if prescribed or permitted by law.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">6.</span>
                    <span class="blocker__text">Rights and liabilities of the Parties</span>
                    <ul class="blocker__ul blocker__ul--sub">
                        <li class="blocker__li">
                            <span class="blocker__liNum">6.1</span>
                            <span class="blocker__text">The User’s rights: the User has the right to apply to the Company with a request to correct or delete the User’s personal data or provide the Company with an objection to such processing; provide the User’s personal data that are incomplete for the Company (subject to the provision of an additional statement explaining the reasons); establish a restriction on data processing if one of the following conditions is met: the accuracy of personal data is disputed by you for a period that allows the Company to verify the accuracy of your personal data; the processing is illegal and you oppose the deletion of personal data and instead demand restrictions on its use; the Company no longer needs your personal data for processing purposes, but you need them to establish, implement or protect your legal requirements; you objected to the processing of your personal data before checking the legal basis for the processing of such data by the Company; request and receive personal data about you (provided by you to the Company) in a structured, widely used and machine-readable format (by generating an appropriate request) and transfer this data to another controller without any intervention from the Company; be informed whether the Company stores information about you; request from the Company the exact purpose (-s) of processing your personal data and information about the categories of your personal data that are processed by the Company; request access to your personal data that the Company stores; request the estimated period during which your personal data will be stored by the Company, and, if this is not possible, the criteria by which the storage period of such data is determined; file a complaint with the European Union Data Protection Supervision Organization if you believe that the Company has violated applicable laws on the protection of personal data.</span>
                        </li>
                        <li class="blocker__li">
                            <span class="blocker__liNum">6.2</span>
                            <span class="blocker__text">The User’s liabilities: the User should provide you accurate and reliable personal data in full in accordance with the Terms posted on this website; immediately provide the Company with your updated personal data if any of your personal data has been changed; immediately notify the Company of the fact of unauthorized receipt of your personal data by a third party, if you become aware of such a fact; notify the Company of any disagreement with any of the purposes of data processing or, if you wish the Company to stop processing your personal data, by sending an appropriate message. The User is fully aware that sending a notice of disagreement with any of the purposes of processing personal data and / or an intention to stop processing his personal data carried out by the Company is a legitimate reason for terminating any relationship between the Parties within the terms and conditions posted on this website. You are solely responsible for the accuracy, accuracy and timeliness of the provision of your personal data to the Company.</span>
                        </li>
                        <li class="blocker__li">
                            <span class="blocker__liNum">6.3</span>
                            <span class="blocker__text">The Company’s rights: the Company has the right to terminate any and all contractual relations (provided for by the Terms and Conditions posted on the Company's website) with you in case of failure to provide your consent to the processing of your personal data for the purposes specified in this section; make changes to the data protection rules unilaterally without receiving your prior consent for such changes.</span>
                        </li>
                        <li class="blocker__li">
                            <span class="blocker__liNum">6.4</span>
                            <span class="blocker__text cWh">The Company's liabilities</span>
                            <span class="blocker__text">
                            The Company should report any correction or deletion of personal data or restriction of the processing of the User’s personal data to every third party to whom the User’s personal data were disclosed by the Company for any of the data processing purposes established by this Policy, unless this is impossible or requires disproportionate efforts for the Company; inform you about the recipients of your personal data (third parties) if a corresponding request was received from you; provide you with your personal data (stored by the Company) in a structured, widely used and machine-readable format if you have submitted such a request; inform the supervisor of the violation of the User’s personal data no later than 72 hours after it became known of such a fact. If the notification to the supervisor is not made within 72 hours, the reasons for such a delay should be provided; immediately notify the User about the fact of a violation of his / her personal data, if such a violation can lead to a high risk for the rights and freedoms of the User. Parties also have all the rights and obligations provided for in the General Data Protection Regulation. The period of storage of your personal data by the Company is extended for the entire period of relations between the Parties, provided for by the Terms posted on the Company’s website, as well as for the next three years after the termination of relations between the Parties (to resolve possible contentious issues).</span>
                        </li>
                    </ul>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">7.</span>
                    <span class="blocker__text">The Company should comply with EU legislation on the rules for the processing and protection of personal data, in particular the General Provisions on Data Protection (Regulation (EU) 2016/679) and the Directive on Confidentiality of Electronic Messages (Directive 2002/58 / EC) as amended by Directive 2009/136/EC.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">8.</span>
                    <span class="blocker__text">We use the latest technology to ensure the safe storage of all personal data of the Website players. We use 128-bit SSL (Secure Socket Layer), a technology that is chosen by many legal and financial organizations. 128-bit SSL guarantees 100% security for all transactions made through the Website.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">9.</span>
                    <span class="blocker__text">jaowdjawidjoaijwdoaiwjdaowijd</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">10.</span>
                    <span class="blocker__text wh">Cookie policy<br /><br /></span>
                    <span class="blocker__text">When you visit the Website, our system automatically collects information about your visit, such as your browser, IP address, and the referring website. This collection may be done in conjunction with our platform providers and partners. We may receive from them general demographic or usage data of our Website visitors. We do not use automatically collected information to identify you personally without receiving additional consent.<br /><br />
                    To collect the information in question we use cookies and similar tracking tools. Cookies are small text files that are stored on your computer or equipment when you visit our web pages. Some of the cookies are essential for the Website to operate; others improve your Website experience and help us deliver a better service. Below are the types of cookies we use and their purposes.<br /><br />
                    Required cookies: enable the navigation and basic functionality of the websites, e.g., access to member areas of the Website.<br /><br />
                    Functional cookies: allow us to analyse your website usage and your selections on the website (e.g. your session key, language, or region), so we can save these settings and offer you a more personalised experience.<br /><br />
                    Advertising cookies: allow us to gauge how effective our content marketing is. These cookies are provided by our partners to track website visits and new player registrations from advertising. We do not share your personal information (such as name or email) to affiliated partners except for site visit data collected directly by such Advertising Cookies. However your site visit data may be linked with other personal information collected through other sources by the providers. The latter external data processing is governed by the privacy notices and policies of these third-party providers.<br /><br />
                    In addition to the above, we use a number of third party service providers who also set cookies on this Website, in order to deliver the services that they are providing to us. Such services include, but are not limited to, helping us to improve your experience by tracking your activity on the Website, measuring the effectiveness of the Website and the effectiveness of our marketing campaigns.<br /><br />
                    Most online browsers automatically accept cookies. If you prefer, it is possible to block some or all cookies, or to delete cookies that have already been set by modifying your browser settings. However, we recommend that you do not block or delete your cookies as this may restrict your use of our Website.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">11.</span>
                    <span class="blocker__text">NetEnt also applies <a href="#">NetEnt privacy policy</a> when playing slot machines developed by NetEnt.</span>
                </li>
                <li class="blocker__li">
                    <span class="blocker__liNum">12.</span>
                    <span class="blocker__text">If you want to view any personal data that we hold about you, or if you want to make any changes to your personal data or delete it, or if you want to receive information about how your personal data is used by the Company or how we ensure the confidentiality of your personal data, you can send a corresponding request to email <a href="#">support-en@jet.casino</a>. To maintain confidentiality, when sending a request, you also need to provide proof of your identity. To do this, you need to attach a copy of your passport to the request. We reserve the right to charge a reasonable fee for duplicate requests, requests for additional copies of the same data and / or requests that are considered manifestly unfounded or excessive. We may also refuse to respond to requests that we consider manifestly unfounded or excessive.</span>
                </li>
            </ul>
        </div>
    )
}