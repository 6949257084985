export default {
    title: "Регистрация",
    steps: {
        first: "Аккаунт",
        second: "Пользователь"
    },
    username: "Имя пользователя",
    username_placeholder: "Введите имя пользователя",
    password: "Пароль",
    password_placeholder: "Введите пароль",
    country: "Страна",
    country_select: "Выберите страну",
    email: "e-Mail",
    email_placeholder: "Введите e-Mail",
    phone: "Номер телефона",

    first_name: "Имя",
    first_name_placeholder: "Введите имя",
    last_name: "Фамилия",
    last_name_placeholder: "Достаточно ввести первую букву",
    female: "Женщина",
    male: "Мужчина",
    dob: "Дата рождения",

    sms: "I want to receive personalized offers and bonuses via email and SMS",
    agree: `I confirm that I'm at least 18 years old and that I have read, understood and agree to the <a href="#" class="btnHover cYel w500">Terms and Conditions</a> and the <a href="#" class="btnHover cYel w500">Privacy Policy</a>`,

    buttons: {
        next: "Далее",
        next_active: "Подождите...",
        back: "Назад",
        register: "Создать аккаунт",
        register_active: "Подождите...",
        login: {
            title: "У вас уже есть аккаунт?",
            button: "Войдите в него"
        }
    }
}