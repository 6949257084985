import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.provider.provider,
    props.user.isAuth,
    props.main.games,
    props.user.bonuses,
    props.user.restrictions.wager,
    (provider, isAuth, games, bonuses, wager) => {
        games = games ? games.filter(game => game.title.toLowerCase() == provider.toLowerCase()) : [];

        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            provider,
            isAuth,
            games: games ? games : [],
            active_bonus
        }
    }
)