import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/deposit/cashapp-payment';
import {cancelPendingOrder, confirmPendingOrder} from '../../../reducers/deposit/actions';
import ModalsUtil from '../../../utils/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Toastr from '../../../utils/toastr';
import Timer from './timer';

export default () => {
    const {
        payment,
        confirmButton,
        cancelButton
    } = useSelector(Selector);

    const dispatch = useDispatch();

    return (
        <form class="popup__contain cashApp" onSubmit={e => {
            e.preventDefault();
        }}>
            <div class="popup__top">
                <p class="popup__title">CashApp</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>
            {payment.status === 1 && (
                <div class="cashApp__processing txt14x18">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM8 4.1C7.50294 4.1 7.1 4.50294 7.1 5C7.1 5.49706 7.50294 5.9 8 5.9H8.0075C8.50456 5.9 8.9075 5.49706 8.9075 5C8.9075 4.50294 8.50456 4.1 8.0075 4.1H8ZM8.9 8C8.9 7.50294 8.49706 7.1 8 7.1C7.50294 7.1 7.1 7.50294 7.1 8V11C7.1 11.4971 7.50294 11.9 8 11.9C8.49706 11.9 8.9 11.4971 8.9 11V8Z" fill="white"></path>
                    </svg>
                    Processing payment id <span class="w700">:  {payment.paymentId}</span>
                </div>
            )}
            <div class="cashApp__transfer">
                <p class="txt14x18 cGry mb16 w400">Transfer <span class="cWh">${payment.amount}</span> to <span class="cWh">{payment.receiver.tag}</span> within 30 minutes or cancel it </p>
                <p class="cashApp__sum">${payment.amount}</p>
                <div class="cashApp__next">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99984 1.33317L5.99984 10.6665M5.99984 10.6665L10.6665 5.99984M5.99984 10.6665L1.33317 5.99984" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                <a href="#" class="log__avatar ma mt20 mb8">
                    <img 
                        src={`http://cashapp.payex.space/images/${payment.receiver.tag.replace('$', '')}_avatar`} 
                        alt="" />
                </a>
                <div class="cashApp__user">
                    <p class="log__name">{payment.receiver.tag}</p>
                    <CopyToClipboard
                        text={payment.receiver.tag}
                        onCopy={e => Toastr.info('Copied to clipboard')}>
                        <button type="button" class="btnHover">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.3335 5.3335L5.3335 3.46683C5.3335 2.72009 5.3335 2.34672 5.47882 2.06151C5.60665 1.81063 5.81063 1.60665 6.06151 1.47882C6.34672 1.3335 6.72009 1.3335 7.46683 1.3335L12.5335 1.3335C13.2802 1.3335 13.6536 1.3335 13.9388 1.47882C14.1897 1.60665 14.3937 1.81063 14.5215 2.06151C14.6668 2.34672 14.6668 2.72009 14.6668 3.46683V8.5335C14.6668 9.28023 14.6668 9.6536 14.5215 9.93882C14.3937 10.1897 14.1897 10.3937 13.9388 10.5215C13.6536 10.6668 13.2802 10.6668 12.5335 10.6668H10.6668M3.46683 14.6668H8.5335C9.28023 14.6668 9.6536 14.6668 9.93882 14.5215C10.1897 14.3937 10.3937 14.1897 10.5215 13.9388C10.6668 13.6536 10.6668 13.2802 10.6668 12.5335L10.6668 7.46683C10.6668 6.72009 10.6668 6.34672 10.5215 6.06151C10.3937 5.81063 10.1897 5.60665 9.93882 5.47882C9.6536 5.3335 9.28023 5.3335 8.5335 5.3335L3.46683 5.3335C2.72009 5.3335 2.34672 5.3335 2.06151 5.47882C1.81063 5.60665 1.60665 5.81063 1.47882 6.06151C1.3335 6.34672 1.3335 6.72009 1.3335 7.46683L1.3335 12.5335C1.3335 13.2802 1.3335 13.6536 1.47882 13.9388C1.60665 14.1897 1.81063 14.3937 2.06151 14.5215C2.34672 14.6668 2.72009 14.6668 3.46683 14.6668Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </CopyToClipboard>
                </div>
                <p class="txt12x15 cGry mt2">{payment.receiver.name}</p>
                <hr class="cashApp__hr hr" />
                <div class="cashApp__time">
                    <Timer time={payment.time} />
                </div>
                {(payment.status === 0) && (
                    <button 
                        class="btn mt24 cashApp__paid"
                        onClick={e => {
                            if(confirmButton)
                                return;

                            dispatch(confirmPendingOrder(payment.id))
                        }}>{!confirmButton ? 'I paid' : 'Please wait..'}</button>
                )}
            </div>
            <button 
                type="button"
                class="btn btn--transparent mt24 cGry"
                style={{
                    opacity: cancelButton ? 0.7 : 1,
                    cursor: cancelButton ? 'no-drop' : 'pointer'
                }}
                onClick={e => {
                    if(cancelButton)
                        return;

                    dispatch(cancelPendingOrder(payment.id));
                }}>{!cancelButton ? 'Cancel' : 'Please wait..'}</button>
        </form>
    )
}