export default () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_255_8)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.95 3.99989C17.1322 3.81129 17.233 3.55868 17.2307 3.29649C17.2284 3.03429 17.1232 2.78348 16.9378 2.59807C16.7524 2.41266 16.5016 2.30749 16.2394 2.30521C15.9772 2.30294 15.7246 2.40373 15.536 2.58589L11.646 6.47489C11.5522 6.56862 11.4251 6.62128 11.2925 6.62128C11.1599 6.62128 11.0328 6.56862 10.939 6.47489L8.464 3.99989C8.37175 3.90438 8.26141 3.8282 8.1394 3.77579C8.0174 3.72338 7.88618 3.69579 7.7534 3.69464C7.62062 3.69348 7.48894 3.71879 7.36605 3.76907C7.24315 3.81935 7.1315 3.8936 7.0376 3.98749C6.94371 4.08139 6.86946 4.19304 6.81918 4.31593C6.7689 4.43883 6.7436 4.57051 6.74475 4.70329C6.7459 4.83607 6.77349 4.96729 6.8259 5.08929C6.87831 5.2113 6.95449 5.32164 7.05 5.41389L8.636 6.99989H5C4.46957 6.99989 3.96086 7.2106 3.58579 7.58567C3.21071 7.96075 3 8.46945 3 8.99989V18.9999C3 19.5303 3.21071 20.039 3.58579 20.4141C3.96086 20.7892 4.46957 20.9999 5 20.9999H19C19.5304 20.9999 20.0391 20.7892 20.4142 20.4141C20.7893 20.039 21 19.5303 21 18.9999V8.99989C21 8.46945 20.7893 7.96075 20.4142 7.58567C20.0391 7.2106 19.5304 6.99989 19 6.99989H13.95L16.95 3.99989ZM9.463 11.9199C9.48106 11.757 9.53718 11.6006 9.62682 11.4635C9.71646 11.3263 9.83711 11.2121 9.97903 11.1301C10.1209 11.0482 10.2802 11.0007 10.4438 10.9917C10.6074 10.9826 10.7709 11.0121 10.921 11.0779C11.276 11.2319 11.949 11.5449 12.789 12.0299C13.629 12.5149 14.238 12.9419 14.549 13.1719C14.6809 13.269 14.7881 13.3958 14.862 13.5419C14.9359 13.6881 14.9744 13.8496 14.9744 14.0134C14.9744 14.1772 14.9359 14.3387 14.862 14.4848C14.7881 14.631 14.6809 14.7578 14.549 14.8549C14.239 15.0839 13.635 15.5069 12.789 15.9949C11.942 16.4849 11.274 16.7949 10.921 16.9489C10.7709 17.0147 10.6074 17.0442 10.4438 17.0351C10.2802 17.0261 10.1209 16.9786 9.97903 16.8967C9.83711 16.8147 9.71646 16.7005 9.62682 16.5633C9.53718 16.4261 9.48106 16.2698 9.463 16.1069C9.38836 15.4114 9.35198 14.7124 9.354 14.0129C9.354 13.0469 9.42 12.3049 9.464 11.9209"/>
</g>
<defs>
<clipPath id="clip0_255_8">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
)