import {React, useState, useEffect} from 'react';
import InputMask from "react-input-mask";
import MaskedInput from 'react-text-mask';
import Lang from '../../../lang';

const getClassName = (inputStatus, isFocused, isSpecialInput) => {
    let className = 'inputBox';

    if(isSpecialInput) className += ' inputBox--rightSome';
    if(isFocused) className += ' inputBox--focused';
    if(inputStatus !== 'default') className += ` inputBox--${inputStatus}`;

    return className;
}

export default ({
    type="text",
    label,
    placeholder,
    name,
    updateState,
    inputs,
    isSpecialInput = false,
    defaultValue = "",
    _country = false
}) => {

    const inputData = inputs.filter(input => input.key === name)[0] || false,
          [isFocused, changeFocusState] = useState(false);

    // Additionally states for SpecialInput
    const [_type, setType] = useState(type);
    
    return (
        <label class={getClassName(inputData.status, isFocused, isSpecialInput)}>
            <p class="inputBox__title">{label}</p>

            {['phone', 'birth'].includes(name) ? (
                <>
                    {name === 'phone' && (
                        <MaskedInput
                            key={_country ? _country.code : 0}
                            mask={_country ? _country.phone_mask.split('').map(c => c === "N" ? /[0-9]/ : c) : ''}
                            showMask={true}
                            guide={true}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            onInput={e => {
                                updateState(e.target.value)
                            }}
                            onFocus={e => changeFocusState(true)}
                            onBlur={e => changeFocusState(false)}
                            required />
                    )}

                    {name === 'birth' && (
                        <InputMask
                            mask={'99/99/9999'}
                            alwaysShowMask={false}
                            name={name}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            onInput={e => {
                                updateState(e.target.value);
                            }}
                            onFocus={e => changeFocusState(true)}
                            onBlur={e => changeFocusState(false)}
                            required />
                    )}
                </>
            ) : (
                <input 
                    type={_type} 
                    name={name}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onInput={e => {
                        updateState(e.target.value);
                    }}
                    onFocus={e => changeFocusState(true)}
                    onBlur={e => changeFocusState(false)}
                    required={name === 'last_name' ? false : 'required'} />
            )}

            {isSpecialInput && (
                <>
                    {name === 'phone' && (
                        <button class="inputBox__rightSome" type="button">
                            <img 
                                src={`${window.location.origin}/img/icons/flags/${_country.icon}`} 
                                alt={_country.name}
                                style={{
                                    width: '24px',
                                    marginTop: '4px'
                                }} />
                        </button>
                    )}

                    {name === 'password' && (
                        <button 
                            type="button"
                            class="inputBox__rightSome"
                            onClick={e => {
                                e.preventDefault();

                                setType(_type === 'text' ? 'password' : 'text')
                            }}>
                            <img 
                                src={`${window.location.origin}/img/icons/eye.svg`}
                                alt="" />
                        </button>
                    )}
                </>
            )}

            <div class="inputBox__warning">
                <div class="inputBox__warningIcon">
                    <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                    <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                    <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                </div>
                {inputData.error && <p class="txt14x18 error valid">{Lang(inputData.error.text, inputData.error.params)}</p>}
                <p class="loading txt14x18">Loading</p>
            </div>
        </label>
    )
}