import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/cashout/freekassa';
import {createFreeKassaCashout} from '../../../reducers/cashout/actions';
import ModalsUtil from '../../../utils/modals';
import Input from '../../modals/signin/input';
import Lang from '../../../lang';

const formats = {
    "RUB": amount => parseFloat(amount.toFixed(2)),
    "USD": amount => parseFloat(amount.toFixed(2)),
    "EUR": amount => parseFloat(amount.toFixed(2)),
    "UAH": amount => parseFloat(amount.toFixed(2)),
    "KZT": amount => parseFloat(amount.toFixed(2)),
    "BTC": amount => parseFloat(amount.toFixed(8)),
    "LTC": amount => parseFloat(amount.toFixed(8)),
    "ETH": amount => parseFloat(amount.toFixed(8)),
    "TRX": amount => parseFloat(amount.toFixed(8)),
    "USDT": amount => parseFloat(amount.toFixed(2))
}

const calculateCurrencyRate = (currencies, currency) => {
    const cur = currencies.filter(cur => cur.code === currency.key)[0] || false;
    return cur ? cur.course : 'NAN';
}

const calculateAmountToReceive = (amount, currencies, currency, method) => {
    const currency_rate = calculateCurrencyRate(currencies, currency),
          method_currency = currencies.filter(cur => cur.id === method.curid)[0] || false;

    if(!method_currency)
        return 0;

    let total_currency_rate = method_currency.course / currency_rate,
        total_amount = parseFloat(amount) / total_currency_rate;    

    if(method.comissions.cashout > 0)
        total_amount = total_amount * (1 - (method.comissions.cashout / 100));

    if(method.comissions.cashout_amount > 0)
        total_amount -= method.comissions.cashout_amount;

    if(total_amount < 0)
        total_amount = 0;
          
    return {
        amount: formats[method_currency.code]([NaN, Infinity].includes(total_amount) ? 0 : total_amount),
        string: formats[method_currency.code]([NaN, Infinity].includes(total_amount) ? 0 : total_amount) + ' ' + (method.symbol ? method.symbol : currency.key) 
    }
}

export default ({method, currencies}) => {
    const {form, button, currency} = useSelector(Selector),
          dispatch = useDispatch();

    const [data, setData] = useState({
        method: method.key,
        amount: "",
        details: ""
    });

    const [receive, setReceive] = useState(null),
          [rate, setRate] = useState(0);

    const calculateAvgComissionInCurrency = (amount) => {
        if(amount > 0) {
            const method_currency = currencies.filter(cur => cur.id === method.curid)[0] || false;

            if(!method_currency)
                return '[NC]';

            const currency_rate = calculateCurrencyRate(currencies, currency),
                  _amount = method_currency.course * amount;

            return ' ≈ ' + parseFloat((_amount / currency_rate).toFixed(2)) + ` ${currency.key}`;
        }

        return '';
    }

    const calculateRate = () => {
        const method_currency = currencies.filter(cur => cur.id === method.curid)[0] || false,
              user_currency = currencies.filter(cur => cur.code === currency.key)[0] || false;
              
        if(!method_currency || !user_currency) return '[NC]';

        const currency_rate = method_currency.course / user_currency.course,
              amount = parseFloat(data.amount) / currency_rate;

        if([NaN, Infinity].includes(amount))
            return 0;  
        
        return formats[method_currency.code](amount < 0 ? 0 : amount);
    }

    const getComissionText = () => {
        let text = '';

        if(method.comissions.cashout > 0) text += `${method.comissions.cashout}%`;

        if(method.comissions.cashout_amount > 0) {
            if(text.length < 1)
                text += `${method.comissions.cashout_amount} ${method.symbol ? `${method.symbol}${calculateAvgComissionInCurrency(method.comissions.cashout_amount)}` : currency.key}`;
            else
                text += ` + ${method.comissions.cashout_amount} ${method.symbol ? `${method.symbol}${calculateAvgComissionInCurrency(method.comissions.cashout_amount)}` : currency.key}`;
        }

        return text;
    }

    useEffect(() => {
        setReceive(calculateAmountToReceive(data.amount, currencies, currency, method))
        setRate(calculateRate());
    }, [data.amount]);

    return (
        <form class="popup__contain" onSubmit={e => {
            e.preventDefault();

            if((receive && receive.amount <= 0) || button) return;

            if(button)
                return;

            dispatch(createFreeKassaCashout(data));
        }}>
            <div class="popup__top">
                <p class="popup__title">{Lang(`pages.profile.transactions.table.method.${method.key}`)}</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>
            <p class="inputAgree__text cGry mb24" dangerouslySetInnerHTML={{__html: Lang(`modals.cashout.freekassa.methods.${method.key}.text`)}}></p>
            {method.type === 'crypto' && (
                <p class="inputAgree__text cGry mb24">
                    {Lang(`modals.cashout.freekassa.course`)}: <b>1 {method.symbol} {calculateAvgComissionInCurrency(1)}</b>
                </p>
            )}
            <p class="inputAgree__text cGry mb24">
                {Lang(`modals.cashout.freekassa.comission`)}: <b>{getComissionText()}</b><br/>
                {Lang(`modals.cashout.freekassa.minimal`)}: <b>{method.rules[currency.key].cashout.minimal} {method.symbol ? method.symbol : currency.key}</b><br/>
                {Lang(`modals.cashout.freekassa.maximal`)}: <b>{method.rules[currency.key].cashout.maximal} {method.symbol ? method.symbol : currency.key}</b>
            </p>

            {form.error !== null && (
                <div class="inputBox--error" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{Lang(form.error.text, form.error.params)}</p>
                    </div>
                </div>
            )}

            <Input
                type="text"
                label={Lang(`modals.cashout.freekassa.methods.${method.key}.title`)}
                placeholder={Lang(`modals.cashout.freekassa.methods.${method.key}.placeholder`)}
                name="details"
                updateState={value => setData({...data, details: value})}
                inputs={form.inputs}
                defaultValue={data.details} />

            <Input
                type="number"
                label={Lang('modals.cashout.freekassa.amount')}
                placeholder="100"
                name="amount"
                updateState={value => setData({...data, amount: parseInt(value)})}
                inputs={form.inputs}
                defaultValue={data.amount} />

            {method.symbol && (
                <label class="inputBox">
                    <p class="inputBox__title">{Lang('modals.cashout.freekassa.amountIn', method.symbol)}</p> 
                    <input 
                        type="text" 
                        placeholder="0"
                        value={rate + ` ${method.symbol}`}
                        readOnly
                        disabled />
                </label>
            )}

            {receive && (
                <label class="inputBox">
                    <p class="inputBox__title">{Lang('modals.cashout.freekassa.receive')}</p>
                    <input 
                        type="text" 
                        placeholder="0"
                        value={receive.string}
                        readOnly
                        disabled />
                </label>
            )}

            <p class="inputAgree__text cGry mb24" style={{textAlign: 'center', fontSize: '13px'}}>{Lang('modals.cashout.freekassa.processing')}</p>

            <button 
                class="btn"
                style={{
                    opacity: (!button && (receive && receive.amount > 0)) ? 1 : 0.7,
                    cursor: (!button && (receive && receive.amount > 0)) ? 'pointer' : 'no-drop'
                }}>{!button ? Lang(`modals.cashout.freekassa.button`) : Lang(`modals.cashout.freekassa.button_active`)}</button>
        </form>
    )
}