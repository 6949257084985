import {createStore, applyMiddleware} from 'redux';
import Thunk from 'redux-thunk';
import Reducers from '../reducers';

const Store = createStore(Reducers, applyMiddleware(Thunk)),
      dispatch = Store.dispatch,
      getState = Store.getState;

export {
    dispatch,
    getState
}

export default Store;