import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.deposit.btc,
    (btc) => {
        return {
            btc
        }
    }
)