import $ from 'jquery';

const clickHandler = index => {
    const items = document.querySelectorAll('.accordion__item');

    for(let i = 0; i < items.length; i++) {
        if(i === index) {
            if(items[i].classList.contains('active'))
                items[i].classList.remove('active');
            else
                items[i].classList.add('active');
        } else {
            items[i].classList.remove('active');
        }
    }
}

export default {
    clickHandler
}