import types from './types';
import API from '../../utils/api';
import ModalsUtil from '../../utils/modals';
import Lang from '../../lang';

export const skipMethod = method => async dispatch => {
    dispatch({
        type: types.error,
        payload: null
    });

    dispatch({
        type: types.toggleButton,
        payload: 'next'
    });

    const response = await API.call({
        path: '/cashout/method',
        body: {
            method
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'next'
    });

    if(response.success)
        dispatch({
            type: types.window,
            payload: method
        });
    else
        dispatch({
            type: types.error,
            payload: response.error
        });
}

export const cashoutCashApp = data => async dispatch => {
    dispatch({
        type: types.forms.clear,
        payload: 'cashapp'
    });

    dispatch({
        type: types.toggleButton,
        payload: 'cashapp'
    });

    const response = await API.call({
        path: '/cashout/cashapp',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'cashapp'
    });

    if(!response.success) {
        dispatch({
            type: types.forms.cashapp,
            payload: {
                error: response.error,
                inputs: response.inputs || []
            }
        });
    } else {
        ModalsUtil.close(0);

        dispatch({
            type: types.clear
        });

        ModalsUtil.done(response.data.title, response.data.text);
    }
}

export const createFreeKassaCashout = data => async dispatch => {
    dispatch({
        type: types.forms.clear,
        payload: 'freekassa'
    });

    dispatch({
        type: types.toggleButton,
        payload: 'freekassa'
    });

    const response = await API.call({
        path: '/cashout/freekassa',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'freekassa'
    });

    if(!response.success) {
        dispatch({
            type: types.forms.freekassa,
            payload: {
                error: response.error,
                inputs: response.inputs || []
            }
        });
    } else {
        ModalsUtil.close(0);

        dispatch({
            type: types.clear
        });

        ModalsUtil.done(Lang('notifications.cashout.done'), Lang(response.message.text, response.message.params));
    }
}

export const clear = () => {
    return {
        type: types.clear
    }
}