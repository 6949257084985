export default {
    window: 'cashout.window',
    error: 'cashout.error',
    toggleButton: 'cashout.toggle.button',
    clear: 'cashout.clear',
    forms: {
        cashapp: 'cashout.forms.cashapp',
        freekassa: 'cashout.forms.freekassa',
        clear: 'cashout.forms.clear'
    }
}