import React from 'react';
import ModalsUtil from '../../../utils/modals';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/fail';
import Lang from '../../../lang';

export default () => {
    const {title, text} = useSelector(Selector);

    return (
        <div class="popup" id="fail-modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            <form class="popup__contain cashApp" onSubmit={e => {
                e.preventDefault();
            }}>
                <div class="resultDone resultDone--fail">
                    <div class="resultDone__icon">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1667 31.3333C24.9907 31.3333 31.3333 24.9907 31.3333 17.1667C31.3333 9.34263 24.9907 3 17.1667 3C9.34263 3 3 9.34263 3 17.1667C3 24.9907 9.34263 31.3333 17.1667 31.3333ZM14.5085 11.8215C13.7665 11.0795 12.5635 11.0795 11.8215 11.8215C11.0795 12.5635 11.0795 13.7665 11.8215 14.5085L14.478 17.165L11.8215 19.8215C11.0795 20.5635 11.0795 21.7665 11.8215 22.5085C12.5635 23.2505 13.7665 23.2505 14.5085 22.5085L17.165 19.852L19.8215 22.5085C20.5635 23.2505 21.7665 23.2505 22.5085 22.5085C23.2505 21.7665 23.2505 20.5635 22.5085 19.8215L19.852 17.165L22.5085 14.5085C23.2505 13.7665 23.2505 12.5635 22.5085 11.8215C21.7665 11.0795 20.5635 11.0795 19.8215 11.8215L17.165 14.478L14.5085 11.8215Z" fill="#E83434"></path>
                        </svg>
                    </div>
                    <p class="txt24x30 w600">{title}</p>
                    <p class="txt14x18 w400" dangerouslySetInnerHTML={{__html: text}}></p>
                </div>
                <button 
                    class="btn btn--transparent cGry mt24"
                    onClick={() => ModalsUtil.close()}>{Lang('modals.info.close')}</button>
            </form>
        </div>
    )
}