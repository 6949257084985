const PreviousArrow = ({sliderRef}) => (
    <button 
        class="headSlider__btnPrew headSlider__btn"
        onClick={e => {
            if(sliderRef.current)
                sliderRef.current.slickPrev();
        }}>
        <svg width="27" height="46" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 1.5L1.75 6L6.25 10.5" stroke="#FFAA04" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>
)

const NextArrow = ({sliderRef}) => (
    <button 
        class="headSlider__btnNext headSlider__btn"
        onClick={e => {
            if(sliderRef.current)
                sliderRef.current.slickNext();
        }}>
        <svg width="27" height="46" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 1.5L1.75 6L6.25 10.5" stroke="#FFAA04" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>
)

export default {
    NextArrow,
    PreviousArrow
}