export default {
    loaded: ({user}) => user.loaded,
    isAuth: ({user}) => user.data !== null,
    data: ({user}) => user.data,
    bonuses: ({user}) => user.bonuses,
    balance: ({user}) => user.data ? user.data.balance : 0,
    stats: ({user}) => user.stats,
    country: ({user}) => user.country,
    currency: ({user}) => user.currency,
    referral: ({user}) => user.referral,
    bonuses_states: ({user}) => {
        return {
            welcome_free: user.bonuses ? user.bonuses.filter(b => b.type === "welcome_free")[0] || false : false,
            welcome_deposit: user.bonuses ? user.bonuses.filter(b => b.type === "welcome_deposit")[0] || false : false,
            phone_bonus: user.bonuses ? user.bonuses.filter(b => b.type === "phone_bonus")[0] || false : false,
            whatsapp_bonus: user.bonuses ? user.bonuses.filter(b => b.type === "whatsapp_bonus")[0] || false : false
        }
    },
    restrictions: {
        wager: ({user}) => user.restrictions.wager,
        bonus_session: ({user}) => user.restrictions.bonus_session
    },
    buttons: {
        changeAvatar: ({user}) => user.buttons.changeAvatar,
        activateVoucher: ({user}) => user.buttons.activateVoucher
    }
}