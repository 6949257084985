import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/pages/profile/tabs/bonuses/input';
import {activateVoucher} from '../../../../../reducers/user/actions';
import Lang from '../../../../../lang';

export default () => {
    const {button} = useSelector(Selector),
          dispatch = useDispatch(),
          [input, setInput] = useState('');

    return (
        <div class="profileWr__content invite">
            <div class="profile-invite">
                <div class="head-invite-info">
                    <div class="invite-details">
                        <h1>{Lang('pages.profile.bonuses.input.title')}</h1>
                        <p>{Lang('pages.profile.bonuses.input.descriptions')}</p>
                    </div>
                </div>
                <div class="invite-redem">
                    <label class="inputBox inputBox--rightSome inputBox--noTitle">
                        <input 
                            type="text" 
                            placeholder={Lang('pages.profile.bonuses.input.placeholder')}
                            onInput={e => setInput(e.target.value)} 
                            defaultValue={input} />
                    </label>
                    <button class="btn" style={{
                        opacity: button ? 0.7 : 1,
                        cursor: button ? 'no-drop' : 'pointer'
                    }} onClick={e => {
                        if(button)
                            return;

                        dispatch(activateVoucher(input));
                    }}>{!button ? Lang('pages.profile.bonuses.input.button') : Lang('pages.profile.bonuses.input.wait')}</button>
                </div>
            </div>
        </div>
    )
}