import Cookies from 'react-cookies';
import Types from '../reducers/types';
import {dispatch} from '../utils/store';
import ModalsUtil from '../utils/modals';
import Lang from '../lang';

let connection = null;
let state = {
    initialized: false,
    authorized: false
}

const log = log => console.log(`[WS] ${log}`),
      send = data => connection.send(JSON.stringify(data));

const connect = () => {
    log(`Connecting....`);

    connection = new WebSocket(`${window.location.origin.replace('http', 'ws')}/ws`);

    connection.onopen = () => {
        log(`Connection open`);

        if(!state.authorized)
            authorize();
    }

    connection.onmessage = res => {
        try {
            res = JSON.parse(res.data);
        } catch(e) {
            return log(`Wrong response`, res);
        }

        switch(res.type) {
            case 'authorized':
                return log(`Successfully logged in [${res.payload.userId}]`);
            case 'unauthorized':
                return log(`Successfully logout`);
            case 'wager':
                return dispatch({
                    type: Types.user.restrictions.wager,
                    payload: res.payload
                });
            case 'bonuses.new':
                return dispatch({
                    type: Types.user.bonuses.new,
                    payload: res.payload
                });
            case 'bonuses.remove':
                return dispatch({
                    type: Types.user.bonuses.remove,
                    payload: res.payload
                });
            case 'bonuses.update':
                return dispatch({
                    type: Types.user.bonuses.update,
                    payload: res.payload
                });
            case 'balance':
                return dispatch({
                    type: Types.user.balance,
                    payload: res.payload
                });
            case 'stats':
                return dispatch({
                    type: Types.user.stats,
                    payload: res.payload
                });
            case 'stats':
                return dispatch({
                    type: Types.user.stats,
                    payload: res.payload
                });
            case 'winners':
                return dispatch({
                    type: Types.main.winner,
                    payload: res.payload
                });
            case 'jackpots.update':
                return dispatch({
                    type: Types.main.jackpot,
                    payload: res.payload
                });
            case 'cashapp.payment.clear':
                return dispatch({
                    type: Types.deposit.cashapp.removePayment,
                    payload: null
                });
            case 'done':
                return ModalsUtil.done(Lang(res.payload.title), Lang(res.payload.text, res.payload.params));
            case 'fail':
                return ModalsUtil.error(Lang(res.payload.title), Lang(res.payload.text, res.payload.params));
            case 'payments.update':
                return dispatch({
                    type: Types.profile.payments.update,
                    payload: res.payload
                });
            case 'payments.new':
                return dispatch({
                    type: Types.profile.payments.new,
                    payload: res.payload
                });
            case 'payments.remove':
                return dispatch({
                    type: Types.profile.payments.remove,
                    payload: res.payload
                });
        }
    }

    connection.onclose = () => {
        log('Connection closed')

        state.authorized = false;
        return setTimeout(connect, 3e3);
    }
}

function init() {
    if(state.initialized)
        return;

    state.initialized = true;
    connect();
}

function logout() {
    state.authorized = false;
    
    send({
        type: 'logout'
    });
}

function authorize(action=false) {
    if(state.authorized && !action)
        return;

    const session = Cookies.load('session');
   
    if(session)
        send({
            type: 'authorize',
            payload: session
        });
}

export default {
    init,
    authorize,
    logout
}