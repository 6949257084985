import types from './types';
import API from '../../utils/api';
import ModalsUtil from '../../utils/modals';
import Lang from '../../lang';

export const loadBonuses = () => async dispatch => {
    const resposne = await API.call({
        path: '/bonuses/getList'
    });

    if(resposne.success) {
        dispatch({
            type: types.loaded,
            payload: {
                bonuses: resposne.bonuses
            }
        });
    }
}

export const activateBonus = (bonusId, navigate, currency) => async dispatch => {
    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });

    const response = await API.call({
        path: '/bonuses/activate',
        body: {
            id: bonusId
        }
    });

    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });

    if(!response.success) {
        ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error, response.params));
    } else {
        navigate('/profile/bonuses');
        
        setTimeout(() => {
            ModalsUtil.done(Lang('notifications.system.titles.success'), Lang(response.message, {...response.params, details: {...response.params.details, currency}}));
        }, 100);
    }
}