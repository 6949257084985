import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.main.preset,
    props.user.loaded,
    props.main.loaded,
    props.user.isAuth,
    props.deposit.loaded,
    props.bonuses.loaded,
    props.main.countrys,
    props.main.background,
    props.main.language,
    (preset, user, main, isAuth, deposit, bonuses, countrys, background, language) => {
        return {
            isAuth,
            loaded: preset && user && main && deposit && bonuses && countrys !== null,
            states: {
                preset,
                user,
                main,
                deposit,
                countrys,
                bonuses
            },
            background,
            language
        }
    }
)