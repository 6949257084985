import React from 'react';
import ModalsUtil from '../../../../utils/modals';

export default () => {
    return (
        <div class="profileWr__content tranfer">
            <div class="tranfer__top">
                <p class="txt24x30 w700 cWh btnWrap">
                    <span class="mr8">Transfer</span> 
                    <span 
                        style={{
                            cursor: 'pointer',
                            paddingTop: '4px'
                        }}
                        onClick={e => ModalsUtil.toggle('#transfer_modal')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                </p>
                <button class="cashApp__processing txt14x18 btnHover">
                    <span class="hide"><span class="text">Your id</span> <span class="w700"><span class="text">:</span>  1NU1YLVCLITXW7D</span></span>
                    <span class="show">Code</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: '10px'}}>
                        <path d="M5.3335 5.3335L5.3335 3.46683C5.3335 2.72009 5.3335 2.34672 5.47882 2.06151C5.60665 1.81063 5.81063 1.60665 6.06151 1.47882C6.34672 1.3335 6.72009 1.3335 7.46683 1.3335L12.5335 1.3335C13.2802 1.3335 13.6536 1.3335 13.9388 1.47882C14.1897 1.60665 14.3937 1.81063 14.5215 2.06151C14.6668 2.34672 14.6668 2.72009 14.6668 3.46683V8.5335C14.6668 9.28023 14.6668 9.6536 14.5215 9.93882C14.3937 10.1897 14.1897 10.3937 13.9388 10.5215C13.6536 10.6668 13.2802 10.6668 12.5335 10.6668H10.6668M3.46683 14.6668H8.5335C9.28023 14.6668 9.6536 14.6668 9.93882 14.5215C10.1897 14.3937 10.3937 14.1897 10.5215 13.9388C10.6668 13.6536 10.6668 13.2802 10.6668 12.5335L10.6668 7.46683C10.6668 6.72009 10.6668 6.34672 10.5215 6.06151C10.3937 5.81063 10.1897 5.60665 9.93882 5.47882C9.6536 5.3335 9.28023 5.3335 8.5335 5.3335L3.46683 5.3335C2.72009 5.3335 2.34672 5.3335 2.06151 5.47882C1.81063 5.60665 1.60665 5.81063 1.47882 6.06151C1.3335 6.34672 1.3335 6.72009 1.3335 7.46683L1.3335 12.5335C1.3335 13.2802 1.3335 13.6536 1.47882 13.9388C1.60665 14.1897 1.81063 14.3937 2.06151 14.5215C2.34672 14.6668 2.72009 14.6668 3.46683 14.6668Z" stroke="#EEEEFF" stroke-opacity="0.5" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </div>
            <form>
                <label class="inputBox inputBox--moreTitle">
                    <div class="inputBox__mrTitle">
                        <p class="inputBox__title">Recipient id</p>
                        <p class="right w500 txt14x18"><span class="cGry">Balance: </span> <span class="cYel"> $100</span></p>
                    </div>
                    <input 
                        type="text" 
                        placeholder="Enter id" />
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                            <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                            <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                        </div>
                    </div>
                </label>
                <label class="inputBox">
                    <p class="inputBox__title">Amount</p>
                    <input 
                        type="text" 
                        placeholder="100" />
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img src={`${window.location.origin}/img/icons/info-hexagon.svg`} class="valid" alt="" />
                            <img src={`${window.location.origin}/img/icons/x-circle.svg`} class="error" alt=""/>
                            <img src={`${window.location.origin}/img/icons/loading-01.svg`} class="loading" alt="" />
                        </div>
                    </div>
                </label>
                <button class="btn mt24 mla wA">Transfer</button>
            </form>
        </div>
    )
}