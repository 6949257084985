import React, {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../../selectors/components/audio';

class BackgroundAuido extends Component {
    constructor() {
        super();
    }

    render() {
        return <></>;
    }

    componentDidMount() {
        window.backgroundMusicInitialized = false;
        window.backgroundMusic = new Audio();
        window.backgroundMusic.src = `${window.location.origin}/audio/theme.mp3`;
        window.backgroundMusic.volume = 0.4;

        window.backgroundMusic.onended = e => {
            window.backgroundMusic.pause();
            window.backgroundMusic.currentTime = 0;
            window.backgroundMusic.play();
        }

        // window.backgroundMusic.oncanplaythrough = () => {
        //     try {
        //         window.backgroundMusic.play();
        //         window.backgroundMusicInitialized = true;
        //     } catch(e) {
        //         window.alert("Can't play");
        //     }
        // }

        // window.backgroundMusic.oncanplaythrough = () => {
        //     try {
        //         window.backgroundMusic.play();
        //         window.backgroundMusicInitialized = true;
        //     } catch(e) {
        //         window.alert('Exception');
        //         console.log(e);
                // document.addEventListener('click', e => {
                //     if(!window.backgroundMusicInitialized) {
                //         window.backgroundMusicInitialized = true;
    
                //         if(this.props.sounds)
                //             window.backgroundMusic.play();
                //     }
                // });
        //     }
        // }

        document.addEventListener('click', e => {
            if(!window.backgroundMusicInitialized) {
                window.backgroundMusicInitialized = true;

                if(this.props.sounds)
                    window.backgroundMusic.play();
            }
        });
    }
}

export default connect(Selector) (BackgroundAuido);