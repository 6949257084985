import {React, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/game';
import {setGameId, loadGame} from '../../../reducers/game/actions';
import Loader from '../../components/loader/page';
import LoaderUtil from '../../../utils/loader';
import Device from '../../../utils/device';

import NotFound from '../notFound';
import Content from './content';

export default () => {
    // Getting current gameId
    const {game_id: currentGameId} = useParams(),
          location = useLocation();

    // Getting reducer gameId
    const {gameId, loaded, sounds} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        window.backgroundMusic.pause();
        return () => {
            if(sounds)
                window.backgroundMusic.play();
        }
    }, [location]);

    useEffect(() => {
        if(gameId == currentGameId) {
            if(!loaded)
                dispatch(loadGame(gameId));
            else
                LoaderUtil.hide();
        } else {
            dispatch(setGameId(currentGameId));
        }

        if(Device.isMobile()) {
            const body = document.querySelector('body');

            if(!body.classList.contains('body-mobile-game'))
                body.classList.add('body-mobile-game');
        }

        return () => {
            if(Device.isMobile()) {
                const body = document.querySelector('body');
    
                if(body.classList.contains('body-mobile-game'))
                    body.classList.remove('body-mobile-game');
            }
        }
    });

    if(currentGameId === null)
        return <NotFound />;

    if(!loaded || gameId != currentGameId)
        return <Loader />;

    return <Content />;
}