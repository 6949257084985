import React from 'react';
import ModalsUtil from '../../../utils/modals';

export default () => {
    return (
        <div class="popup" id="bonus_info_modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            <div class="popup__contain">
                <div class="popup__top">
                    <p class="popup__title">How bonus works?</p>
                    <button 
                        class="popup__close"
                        onClick={e => ModalsUtil.close()}>
                            <img 
                                src={`${window.location.origin}/img/icons/close.svg`} 
                                alt="" />
                    </button>
                </div>
                <p class="txt14x18 cWh w400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            </div>
        </div>
    )
}