import React, {useEffect, useState} from 'react';

export default ({name, image: imageSrc}) => {
    const [loaded, setLoaded] = useState(typeof window.game_assets !== 'undefined' && window.game_assets[name]);

    useEffect(() => {
        if(typeof window.game_assets === 'undefined')
            window.game_assets = {};

        window.game_assets[name] = false;

        const image = new Image();
              image.src = imageSrc;
              image.onload = () => {
                window.game_assets[name] = true;
                setLoaded(true);
              }

              image.onerror = () => console.log(`Can't load image ${imageSrc}`);
    }, [loaded]);

    return (
        <img 
            src={loaded ? imageSrc : `${window.location.origin}/img/games/game-preload.png`}
            alt={loaded ? name : `Game Preloader`} />
    )
}