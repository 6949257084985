const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

const getRegisterDate = timestamp => {
    const date = new Date(timestamp),
          month = months[date.getMonth()];

    return `${month} ${date.getDate()}`;
}

const phoneFormat = phoneString => {
    const pattern = '+n (nnn) nnn-nn-nn';

    let phone = '';
    for(let i = 0; i < pattern.length; i++) {
        if(pattern[i] === 'n') {
            phone += phoneString[0] || '_';
            phoneString = phoneString.substr(1);
        } else {
            phone += pattern[i];
        }
    }

    return phone;
}

export default {
    getRegisterDate,
    phoneFormat
}