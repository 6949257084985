export default {
    failed: "Не удалось подключить к серверу. Попробуй чуть позже...",
    sww: "Что-то пошло не так! Попробуйте чуть позже...",
    auth: "Вы должны быть авторизованным",
    currency: {
        country: {
            notFound: ({code}) => `Не удалось найти страну по ключу «${code}»`
        }
    },
    titles: {
        error: "Ошибка!",
        success: "Готово!"
    }
}