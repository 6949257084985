import React from 'react';
import {useLocation} from 'react-router-dom';

import Main from './main';
import Invite from './invite';
import Transfer from './transfer';
import Transactions from './transactions';
import Bonuses from './bonuses';
import Vouchers from './vouchers';

export default () => {
    const location = useLocation();

    if(location.pathname === '/profile/bonuses')
        return <Bonuses />;

    if(location.pathname === '/profile/transactions')
        return <Transactions />;

    if(location.pathname === '/profile/transfer')
        return <Transfer />;

    if(location.pathname === '/profile/referal')
        return <Invite />;

    if(location.pathname === '/profile/vouchers')
        return <Vouchers />;

    if(location.pathname === '/profile')
        return <Main />;

    return <></>;
}