import React from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/language';
import ModalsUtil from '../../../utils/modals';
import HeaderUtil from '../../../utils/header';

export default () => {
    const {country} = useSelector(Selector);

    return (
        <div className="toolbar-select language-select" onClick={e => {
            HeaderUtil.close();
            ModalsUtil.toggle('#language_modal');
        }}>
            <img 
                src={country ? `${window.location.origin}/img/icons/flags/${country.icon}` : ``}
                alt={country ? country.name : 'Select language'} />
        </div>
    )
}