import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.main.modals.confirm.title,
    props.main.modals.confirm.text,
    props.main.modals.confirm.confirm,
    props.main.modals.confirm.onConfirm,
    props.main.modals.confirm.cancel,
    props.main.modals.confirm.onCancel,
    (title, text, confirm, onConfirm, cancel, onCancel) => {
        return {
            title, 
            text,
            confirm, 
            onConfirm,
            cancel, 
            onCancel
        }
    }
)