import promotions from './promotions';
import profile from './profile';
import faq from './faq';
import main from './main';
import game from './game';
import notFound from './notFound';

export default {
    promotions,
    profile,
    faq,
    main,
    game,
    notFound
}