import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.isAuth,
    props.main.header,
    props.user.bonuses,
    props.user.restrictions.wager,
    props.main.type,
    (isAuth, header_active, bonuses, wager, type) => {
        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            isAuth,
            header_active,
            active_bonus,
            type
        }
    }
)