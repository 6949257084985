import Device from '../../../utils/device';
import GameImage from '../game-image';
import GameWager from '../game-wager';
import ModalsUtil from '../../../utils/modals';
import {useDispatch} from 'react-redux';
import {toggleDemo} from '../../../reducers/game/actions';
import Lang from '../../../lang';
import {useNavigate} from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import Slider from 'react-slick';


const getElementsCount = (sliderRef) => {
    if(sliderRef.current) {
        const width = sliderRef.current.offsetWidth + 8,
            item = sliderRef.current.querySelector('.listGames__slide').offsetWidth + 8;

        return Math.floor(width / item) < 2 ? 2 : Math.floor(width / item);
    } else {
        return 5;
    }
}

export default ({
    icon: Icon,
    name,
    games,
    active_bonus,
    isAuth
}) => {
    const dispatch = useDispatch(),
          navigate = useNavigate(),
          [slidesCount, setSlidesCount] = useState(null),
          sliderBlockRef = useRef(),
          slider = useRef();

    useEffect(() => {
        if(slidesCount === null)
            updateSlider();

        window.addEventListener('resize', updateSlider);
        return () => window.removeEventListener('resize', updateSlider)
    });

    function updateSlider() {
        const elements = getElementsCount(sliderBlockRef);
        setSlidesCount(elements);
    }

    return (
        <div class="listGames">
            <div class="listGames__top">
                <p class="listGames__title txt32x39">
                    <Icon />
                    {name}
                </p>
                <div class="listGames__btns">
                    <button
                        onClick={e => {
                            if(slider.current)
                                slider.current.slickPrev();
                        }} 
                        class="llistGames__btn--prew listGames__btn">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.25 1.5L1.75 6L6.25 10.5" stroke="#0D0E26" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </button>
                    <button
                        onClick={e => {
                            if(slider.current)
                                slider.current.slickNext();
                        }}  
                        class="listGames__btn--next listGames__btn">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.25 1.5L1.75 6L6.25 10.5" stroke="#0D0E26" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="listGames__bottom" ref={sliderBlockRef}>
                <Slider
                    ref={slider}
                    dots={false}
                    infinite={true}
                    slidesToShow={slidesCount}
                    slidesToScroll={slidesCount}
                    arrows={false}
                    speed={1000}
                    autoplay={true}
                    className="listGames__slider listGames__sliderDelete">
                    {games.map((game, key) => {
                        return (
                            <div>
                                <div 
                                    class="listGames__slide" 
                                    key={key}>
                                    <div class="listGames__link listGames__link--more">
                                        <GameImage name={game.name} image={game.image} />
                                        <GameWager game={game} />
                                            
                                        <div class="listGames__hovBtn">
                                            <button 
                                                class="btn"
                                                onClick={e => {
                                                    if(!isAuth)
                                                        return ModalsUtil.toggle('#login_signin');

                                                    if(active_bonus) {
                                                        if(active_bonus.details.games_restrictions.enabled && !active_bonus.details.games_restrictions.list.includes(game.gameId)) {
                                                            return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                        } else if(!active_bonus.details.games_restrictions.enabled && !game.is_wager) {
                                                            return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                        }
                                                    }

                                                    if(active_bonus && active_bonus.freespins_session && active_bonus.freespins_session.left > 0)
                                                        return ModalsUtil.confirm({
                                                            title: Lang('bonuses.game.freespins.title'),
                                                            text: Lang('bonuses.game.freespins.text'),
                                                            confirm: Lang('bonuses.game.freespins.confirm', {count: active_bonus.freespins_session.left}),
                                                            cancel: Lang('bonuses.game.freespins.cancel'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                navigate(`/bonus/${active_bonus.id}`);
                                                            },
                                                            onCancel: () => {
                                                                ModalsUtil.close();
                                                                dispatch(toggleDemo(false));
                                                                navigate(`/game/${game.id}`);
                                                            }
                                                        });

                                                    if(active_bonus && active_bonus.wager.type === "wager" && game.title === "spadegaming")
                                                        return ModalsUtil.confirm({
                                                            title: Lang('bonuses.game.fish_no_wager.title'),
                                                            text: Lang('bonuses.game.fish_no_wager.text'),
                                                            confirm: Lang('bonuses.game.fish_no_wager.confirm'),
                                                            cancel: Lang('bonuses.game.fish_no_wager.cancel'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                dispatch(toggleDemo(false));
                                                                navigate(`/game/${game.id}`);
                                                            },
                                                            onCancel: () => {
                                                                ModalsUtil.close();
                                                            }
                                                        });

                                                    dispatch(toggleDemo(false));
                                                    navigate(`/game/${game.id}`);
                                                }}>{Lang('modals.play.play')}</button>
                                            {game.demo && (
                                                <button 
                                                    class="btn btn--outside"
                                                    onClick={e => {
                                                        dispatch(toggleDemo(true));
                                                        navigate(`/game/${game.id}`);
                                                    }}>{Lang('modals.play.demo')}</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}