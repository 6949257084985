export default () => (
<svg xmlns="http://www.w3.org/2000/svg" width="37" height="52" viewBox="0 0 37 52" fill="none">
    <g clip-path="url(#clip0_121_2)">
        <path d="M18.1998 36.4C20.9572 36.3971 23.6008 35.3005 25.5505 33.3507C27.5003 31.401 28.5969 28.7574 28.5998 26V10.4C28.5998 7.64175 27.5041 4.99647 25.5537 3.04609C23.6033 1.09571 20.9581 0 18.1998 0C15.4416 0 12.7963 1.09571 10.8459 3.04609C8.89552 4.99647 7.7998 7.64175 7.7998 10.4V26C7.80267 28.7574 8.89931 31.401 10.8491 33.3507C12.7988 35.3005 15.4424 36.3971 18.1998 36.4Z" />
        <path d="M36.4 26V20.8C36.4 20.1104 36.1261 19.4491 35.6385 18.9615C35.1509 18.4739 34.4896 18.2 33.8 18.2C33.1104 18.2 32.4491 18.4739 31.9615 18.9615C31.4739 19.4491 31.2 20.1104 31.2 20.8V26C31.2 29.4478 29.8304 32.7544 27.3924 35.1923C24.9544 37.6303 21.6478 39 18.2 39C14.7522 39 11.4456 37.6303 9.00761 35.1923C6.56964 32.7544 5.2 29.4478 5.2 26V20.8C5.2 20.1104 4.92607 19.4491 4.43848 18.9615C3.95088 18.4739 3.28956 18.2 2.6 18.2C1.91044 18.2 1.24912 18.4739 0.761522 18.9615C0.273928 19.4491 0 20.1104 0 20.8L0 26C0.00737841 30.355 1.57503 34.5632 4.41872 37.8616C7.26241 41.16 11.1939 43.3303 15.5003 43.979L15.6 43.992V46.8H7.8C7.45856 46.8 7.12047 46.8672 6.80502 46.9979C6.48958 47.1285 6.20295 47.32 5.96152 47.5615C5.72009 47.8029 5.52858 48.0895 5.39791 48.405C5.26725 48.7204 5.2 49.0585 5.2 49.3999C5.2 49.7414 5.26725 50.0795 5.39791 50.3949C5.52858 50.7104 5.72009 50.997 5.96152 51.2384C6.20295 51.4799 6.48958 51.6714 6.80502 51.802C7.12047 51.9327 7.45856 51.9999 7.8 51.9999H28.6C28.9414 51.9999 29.2795 51.9327 29.595 51.802C29.9104 51.6714 30.197 51.4799 30.4385 51.2384C30.6799 50.997 30.8714 50.7104 31.0021 50.3949C31.1328 50.0795 31.2 49.7414 31.2 49.3999C31.2 49.0585 31.1328 48.7204 31.0021 48.405C30.8714 48.0895 30.6799 47.8029 30.4385 47.5615C30.197 47.32 29.9104 47.1285 29.595 46.9979C29.2795 46.8672 28.9414 46.8 28.6 46.8H20.8V43.992C29.6617 42.6725 36.3848 35.1238 36.4 26.0021V26Z"/>
    </g>
    <defs>
        <clipPath id="clip0_121_2">
            <rect width="36.8333" height="52" fill="white"/>
        </clipPath>
    </defs>
</svg>
)