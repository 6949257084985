import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Selector from '../../../selectors/pages/provider';
import {setProvider} from '../../../reducers/provider/actions';
import {loadMain} from '../../../reducers/main/actions';
import LoaderPage from '../../components/loader/page';
import LoaderUtil from '../../../utils/loader';
import GamesList from './games_list';
import Providers from '../main/providers';

export default () => {
    const {provider: urlProvider} = useParams(),
          {provider} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!provider || provider !== urlProvider)
            dispatch(setProvider(urlProvider));

        LoaderUtil.hide();
    });

    return (
        <>
            {(provider !== urlProvider) ? <LoaderPage /> : <GamesList />}
            <Providers />
        </>
    )
}