import {React, useEffect} from 'react';
import {Link} from 'react-router-dom';
import LoaderUtil from '../../../utils/loader';
import {useSelector} from 'react-redux';
import PresetSelector from '../../../selectors/preset';
import {Helmet} from 'react-helmet';
import Lang from '../../../lang';

export default () => {
    useEffect(() => {
        LoaderUtil.hide();
    });

    const {preset, language} = useSelector(PresetSelector);

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', '404')}</title>
            </Helmet>
            <div class="notFounded">
                <div class="notFounded__block">
                    <img 
                        src={`${window.location.origin}/img/components/404.png`} 
                        alt="" />
                    <p class="txt36x44">{Lang('pages.notFound.title')}</p>
                    <Link to={'/'} class="btn">{Lang('pages.notFound.button')}</Link>
                </div>
            </div>
        </>
    )
}