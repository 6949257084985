export default {
    error: null,
    inputs: [
        {
            key: "cashtag",
            status: "default",
            error: null
        },
        {
            key: "amount",
            status: "default",
            error: null
        }
    ]
}