import $ from 'jquery';

const hide = (time=300) => {
    $('.loader').fadeOut(time)
    $('body').removeClass('body-loaded');
}; 
const show = (time=300) => {
    $('body').addClass('body-loaded');
    $('.loader').fadeIn(time); 
}

export default {
    hide,
    show
}