import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Selector from '../../../selectors/pages/main/games';
import {setSearchQuery} from '../../../reducers/main/actions';
import Lang from '../../../lang';
import Icons from './icons';
import GamesCointainer from '../../components/games-container';

export default () => {
    const {type, games, show, isAuth, has_more, more_count, search, active_bonus} = useSelector(Selector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    const string_to_camel_case = string => `${string[0].toUpperCase()}${string.substr(1)}`;

    return (
        <>
            {type === 'search' && (
                <div class="searchLine__wrapper wrapper">
                    <form class="searchLine">
                        <button class="searchLine__btn">
                            <img 
                                src={`${window.location.origin}/img/icons/search.svg`} 
                                alt="" />
                        </button>
                        <label class="searchLine__input">
                            <input 
                                type="text" 
                                placeholder="Search game"
                                onInput={e => dispatch(setSearchQuery(e.target.value))}
                                defaultValue={search} />
                        </label>
                    </form>
                </div>
            )}
            <section class="blockListGames">
                <div class="wrapper">
                    {['home', 'live'].includes(type) ? (
                        <>
                            {type === 'home' && (
                                <>
                                    <GamesCointainer
                                        icon={Icons.New}
                                        name={Lang('pages.main.sliders.new')}
                                        games={games.new}
                                        isSlider={false}
                                        active_bonus={active_bonus}
                                        isAuth={isAuth} />

                                    <GamesCointainer
                                        icon={Icons.Popular}
                                        name={Lang('pages.main.sliders.popular')}
                                        games={games.top}
                                        isSlider={false}
                                        active_bonus={active_bonus}
                                        isAuth={isAuth} />

                                    <GamesCointainer
                                        icon={Icons.Like}
                                        name={Lang('pages.main.sliders.recommend')}
                                        games={games.recommend.sort(() => Math.random() - 0.5)}
                                        isSlider={false}
                                        active_bonus={active_bonus}
                                        isAuth={isAuth}
                                        defaultCountToShow={25}
                                        countToShowNextStep={25} />

                                    <GamesCointainer
                                        icon={Icons.Game}
                                        name={Lang('pages.main.sliders.all')}
                                        games={games.all.sort(() => Math.random() - 0.5)}
                                        active_bonus={active_bonus}
                                        isAuth={isAuth}
                                        isSlider={false}
                                        defaultCountToShow={25}
                                        countToShowNextStep={25} />
                                </>
                            )}

                            {type === 'live' && (
                                <>
                                    {['poker', 'dice', 'blackjack', 'baccarat'].map((games_key, key) => {
                                        if(games[games_key] && games[games_key].length > 0)
                                            return <GamesCointainer
                                                        icon={Icons[string_to_camel_case(games_key)]}
                                                        name={Lang(`pages.main.sliders.${games_key}`)}
                                                        games={games[games_key]}
                                                        active_bonus={active_bonus}
                                                        isAuth={isAuth}
                                                        defaultCountToShow={25}
                                                        countToShowNextStep={25}
                                                        isSlider={false} />;
                                    })}
                                </>
                            )}
                        </>
                    ) : (
                        <GamesCointainer
                            icon={Icons[string_to_camel_case(type)]}
                            name={Lang(`pages.main.categorys.${type}`)}
                            games={games}
                            active_bonus={active_bonus}
                            isAuth={isAuth}
                            defaultCountToShow={25}
                            countToShowNextStep={25}
                            type={type} />
                    )}
                </div>
            </section>
        </>
    )
}