import $ from 'jquery';

const close = () => document.dispatchEvent(new CustomEvent('header.close'));

const outClickHandler = e => {
    const element = $('header .menu'),
          button = $('.mobile-nav .header__btnPopup');

    if($(e.target).is(button) || $(button).has(e.target).length <= 0)
        return;

    if(!$(e.target).is(element))
        if($(element).has(e.target).length <= 0)
            document.dispatchEvent(new CustomEvent('header.close'));
}

const setActive = () => {
    const links = document.querySelectorAll('.menu__link');

    for(let i = 0; i < links.length; i++)
        if(links[i].getAttribute('href') === window.location.pathname)
            links[i].classList.add('active');
        else
            links[i].classList.remove('active');
}

export default {
    outClickHandler,
    setActive,
    close
}