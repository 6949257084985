import auth from './auth';
import user from './user';
import main from './main';
import game from './game';
import provider from './provider';
import deposit from './deposit';
import cashout from './cashout';
import profile from './profile';
import bonuses from './bonuses';

export default {
    auth,
    user,
    main,
    game,
    provider,
    deposit,
    cashout,
    profile,
    bonuses
}