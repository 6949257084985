export default {
    loaded: 'main.loaded',
    preset: 'main.preset',
    type: 'main.type',
    show: 'main.show',
    winner: 'main.winner',
    jackpot: 'main.jackpot',
    search: 'main.search',
    countrys: 'main.countrys',
    language: 'main.language',
    sounds: 'main.sounds',
    background: 'main.background',
    bonus: "main.bonus",
    header: "main.header",
    modals: {
        error: 'main.modals.error',
        done: 'main.modals.done',
        confirm: 'main.modals.confirm',
        info: 'main.modals.info'
    }
}