import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.game.game,
    props.game.buttons.favorite,
    props.user.isAuth,
    props.game.demo,
    props.game.session,
    props.user.bonuses,
    props.user.restrictions.wager,
    (game, favoriteButton, isAuth, isDemo, session, bonuses, wager) => {
        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            game,
            favoriteButton,
            isAuth,
            isDemo,
            session,
            active_bonus
        }
    }
)