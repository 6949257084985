import {currency_short} from '../../../utils/types';
import Lang from '../../../lang';

export default {
    done: {
        title: "Ваш платеж обработан!",
        text: ({amount, currency}) => `Ваш платеж успешно обработан! На ваш баланс зачислено ${currency_short(amount, currency.key, currency.symbol)}`
    },
    bonus: "Вы не можете вывести средства, поскольку находитесь в активной бонусной сессии",
    sww: "Что-то пошло не так, попробуйте чуть позже...",
    balanceNotLoad: "Не смогли загрузить Ваш баланс, скорее всего у нас технические работы. Попробуйте чуть позже...",
    notEnoughBalance: "Недостаточно средств на балансе для совершения вывода на указанную сумму",
    cantCheckRules: "Не смогли проверить, подходит ли Ваш вывод по нашим параметрам. Попробуйте чуть позже...",
    method_not_found: "Выбранная платежная система не найдена",
    method_currency_not_found: "Не удалось найти валюту выбранного метода",
    method_user_currency_not_found: "Не удалось найти валюту",
    method_currency_not_supported: "Не удалось найти выбранный метод для Вашей игровой валюты",
    method_not_allowed_to_cashout: "Выбранная платежная система не подходит для вывода",
    minimal_cashout: ({amount, currency}) => `Минимальная сумма вывода для выбранного метода - ${amount} ${currency}`,
    maximal_cashout: ({amount, currency}) => `Максимальная сумма вывода для выбранного метода - ${amount} ${currency}`,
    cantWithdraw: "Не удалось снять средства с Вашего баланса. Попробуйте чуть позже...",
    done: "Готово!",
    mapNotLoaded: "Не удалось загрузить список доступных платежных систем! Попробуйте чуть позже...",
    success: ({amount, method, currency, amount_to_send, symbol}) => `Запрос на вывод на сумму ${amount} ${currency}${currency !== symbol ? ` (${amount_to_send} ${symbol})` : ''} через систему ${Lang(`pages.profile.transactions.table.method.${method}`)} успешно создан! Ваш вывод будет обработан в самое ближайшее время.`,
    freekassa: {
        rejected: {
            title: "Вывод отменен!",
            text: ({paymentId, amount, currency}) => `Ваш запрос на вывод <b>${paymentId}</b> был отменен. Мы вернули ${amount} ${currency} на ваш игровой баланс.`
        },
        done: {
            title: "Готово!",
            text: ({paymentId, amount, symbol, details}) => `Ваш запрос на вывод <b>${paymentId}</b> успешно обработан! <b>${amount} ${symbol}</b> были переведены на ваш счет <b>${details}</b>.`
        }
    }
} 