import React from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../../../selectors/pages/profile/tabs/bonuses/wager';
import Lang from '../../../../../lang';
import {currency as currencyFormat} from '../../../../../utils/types';

export default () => {
    const {wager, currency, bonuses, balance} = useSelector(Selector);

    if(!wager)
        return <></>;

    const bonus = bonuses.filter(bonus => bonus.id === wager.details.bonus)[0] || false;

    if(!bonus)
        return <></>;

    return (
        <div class="bonus-wager">
            <div class="image-wager">
                <img src={`${window.location.origin}/img/wager.png`} alt="" />
            </div>
            {wager && (
                <div class="wager-content">
                    <div class="wager-info">
                        <h1>{Lang('bonuses.wager.title')}</h1>
                        {wager.type === 'limit' && (
                            <p dangerouslySetInnerHTML={{__html: Lang('bonuses.wager.limit', {
                                content: bonus.content,
                                mode: bonus.mode,
                                wager,
                                currency
                            })}}></p>
                        )}

                        {wager.type === 'wager' && (
                            <p dangerouslySetInnerHTML={{__html: Lang('bonuses.wager.wager', {
                                content: bonus.content,
                                mode: bonus.mode,
                                wager,
                                currency
                            })}}></p>
                        )}
                    </div>
                    {['limit', 'limit_fixed'].includes(wager.type) && (
                        <div class="wager-progress">
                            <div class="head-wager-progress">
                                <p class="item">{currencyFormat(balance, currency.key, currency.symbol)}</p>
                                <p class="item">{currencyFormat(wager.result, currency.key, currency.symbol)}</p>
                            </div>
                            <div class="wager-progress-content">
                                <div style={{width: ((balance / wager.result) * 1e2) + '%'}} class="wager-status"></div>
                            </div>
                        </div>
                    )}

                    {wager.type === 'wager' && (
                        <div class="wager-progress">
                            <div class="head-wager-progress">
                                <p class="item">{currencyFormat(wager.result, currency.key, currency.symbol)}</p>
                                <p class="item">{currencyFormat((wager.amount * wager.value), currency.key, currency.symbol)}</p>
                            </div>
                            <div class="wager-progress-content">
                                <div style={{width: ((wager.result / (wager.amount * wager.value)) * 1e2) + '%'}} class="wager-status"></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}