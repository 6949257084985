import $ from 'jquery';
import {dispatch} from '../utils/store';
import globalTypes from '../reducers/types';

const logger = log => console.log(`[MODALS] ${log}`);

const close = (ms=300) => {
    const modals = document.querySelectorAll('.popup');

    for(const element of modals)
        if(element.style.display !== 'none') {
            $(element).fadeOut(ms);

            element
                .querySelector('.popup__bg')
                .removeEventListener('click', outClickHandler);

            logger(`${element.getAttribute('id')} has been closed`);
            document.dispatchEvent(new CustomEvent('modal.closed', {detail: element.getAttribute('id')}));
        }
}

const toggle = (trigger, state = null) => {
    close();

    const element = document.querySelector(trigger);

    if(!element)
        return;

    const currentState = element.style.display === 'none' ? false : true,
          nextState = state === null ? !currentState : state;

    if(nextState)
        $(element).fadeIn(300);
    else
        $(element).fadeOut(300);

    element
        .querySelector('.popup__bg')
        .addEventListener('click', outClickHandler);

    logger(`${trigger} change state to ${nextState ? 'OPEN' : 'CLOSE'}`);
}

const error = (title, text) => {
    close(0);

    dispatch({
        type: globalTypes.main.modals.error,
        payload: {
            title,
            text
        }
    });

    toggle("#fail-modal");
}

const done = (title, text) => {
    close(0);

    dispatch({
        type: globalTypes.main.modals.done,
        payload: {
            title,
            text
        }
    });

    toggle("#done-modal")
}

const info = (title, text) => {
    close(0);

    dispatch({
        type: globalTypes.main.modals.info,
        payload: {
            title,
            text
        }
    });

    toggle("#info-modal")
}

const confirm = ({
    title,
    text,
    confirm,
    onConfirm,
    cancel,
    onCancel
}) => {
    close(0);

    dispatch({
        type: globalTypes.main.modals.confirm,
        payload: {
            title,
            text,
            confirm,
            onConfirm,
            cancel,
            onCancel
        }
    });

    toggle("#confirm-modal")
}

function outClickHandler(e) {
    close();
}

export default {
    toggle,
    close,
    outClickHandler,
    confirm,
    error,
    done,
    info
}