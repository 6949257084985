import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.main.sounds,
    (sounds) => {
        return {
            sounds
        }
    }
)