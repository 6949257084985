import React from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/deposit/btc';
import ModalsUtil from '../../../utils/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Toastr from '../../../utils/toastr';

export default () => {
    const {btc} = useSelector(Selector);

    return (
        <form 
            class="popup__contain cashApp"
            onSubmit={e => {
                e.preventDefault();
            }}>
            <div class="popup__top">
                <p class="popup__title">Bitcoin</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>
            <ul class="mb24">
                <li class="txt12x15 cGry mt2 w400">
                    1. Habitant nibh velit urna, parturient suscip.
                </li>
                <li class="txt12x15 cGry mt2 w400">
                    2. Sed interdum eget diam nunc eu. 
                </li>
                <li class="txt12x15 cGry mt2 w400">
                    3. Morbi proin sed cras eget odio dolor. 
                </li>
                <li class="txt12x15 cGry mt2 w400">
                    4. A duis integer massa mattis. 
                </li>
                <li class="txt12x15 cGry mt2 w400">
                    5. Vulputate nunc vitae aliquet proin in volut.
                </li>
            </ul>
            <div class="quar mb24">
                <img
                    style={{
                        width: '100%',
                        borderRadius: '30px'
                    }} 
                    src={btc.qr} 
                    alt="QR Code" />
            </div>
            <div class="txt12x15 w500 mb8">BTC Address</div>
            <div class="adressMidn txt16x24 w500">
                {btc.address}
            </div>
            <CopyToClipboard text={btc.address} onCopy={e => {
                Toastr.info('Copied to clipboard');
            }}>
                <button class="btn mt24 btnWrap">
                    Copy address 
                    <svg class="ml8" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3335 5.3335L5.3335 3.46683C5.3335 2.72009 5.3335 2.34672 5.47882 2.06151C5.60665 1.81063 5.81063 1.60665 6.06151 1.47882C6.34672 1.3335 6.72009 1.3335 7.46683 1.3335L12.5335 1.3335C13.2802 1.3335 13.6536 1.3335 13.9388 1.47882C14.1897 1.60665 14.3937 1.81063 14.5215 2.06151C14.6668 2.34672 14.6668 2.72009 14.6668 3.46683V8.5335C14.6668 9.28023 14.6668 9.6536 14.5215 9.93882C14.3937 10.1897 14.1897 10.3937 13.9388 10.5215C13.6536 10.6668 13.2802 10.6668 12.5335 10.6668H10.6668M3.46683 14.6668H8.5335C9.28023 14.6668 9.6536 14.6668 9.93882 14.5215C10.1897 14.3937 10.3937 14.1897 10.5215 13.9388C10.6668 13.6536 10.6668 13.2802 10.6668 12.5335L10.6668 7.46683C10.6668 6.72009 10.6668 6.34672 10.5215 6.06151C10.3937 5.81063 10.1897 5.60665 9.93882 5.47882C9.6536 5.3335 9.28023 5.3335 8.5335 5.3335L3.46683 5.3335C2.72009 5.3335 2.34672 5.3335 2.06151 5.47882C1.81063 5.60665 1.60665 5.81063 1.47882 6.06151C1.3335 6.34672 1.3335 6.72009 1.3335 7.46683L1.3335 12.5335C1.3335 13.2802 1.3335 13.6536 1.47882 13.9388C1.60665 14.1897 1.81063 14.3937 2.06151 14.5215C2.34672 14.6668 2.72009 14.6668 3.46683 14.6668Z" stroke="#000000" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </CopyToClipboard>
        </form>
    )
}