import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.deposit.bonusId,
    props.deposit.bonus,
    props.deposit.buttons.skip_bonus,
    props.deposit.error,
    (bonusId, bonus, button, error) => {
        return {
            bonusId,
            bonus,
            button,
            error
        }
    }
)