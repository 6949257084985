import user from './user/types';
import main from './main/types';
import deposit from './deposit/types';
import profile from './profile/types';

export default {
    user,
    main,
    deposit,
    profile
}