import {getState} from '../utils/store';
import ru from './ru';
import us from './us';

const languages = {
    ru,
    us
}

export default (path, params) => {
    const {main} = getState(),
          language = typeof languages[main.language.toLowerCase()] === 'undefined' ? languages.us : languages[main.language.toLowerCase()];

    let string = language;
    for(const key of path.split('.')) {
        if(typeof string[key] === 'undefined')
            return language.notFound(path);
        
        string = string[key];
    }

    return typeof string === 'function' ? string(params, main.language) : string;
}

