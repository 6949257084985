import system from './system';
import bonuses from './bonuses';
import deposit from './deposit';
import cashout from './cashout';
import auth from './auth';
import user from './user';

export default {
    system,
    bonuses,
    deposit,
    cashout,
    auth,
    user
}