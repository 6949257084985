import ModalsUtil from '../../../utils/modals';

export default {
    ru: [
        // {
        //     orientation: "left",
        //     image: "/source/sliders/ru/bitcoin.png",
        //     text: "components.slider.bitcoin.text",
        //     descriptions: "components.slider.bitcoin.descriptions",
        //     button: "components.slider.bitcoin.button",
        //     buttonClick: ({isAuth, lang}) => {
        //         if(isAuth) {
        //             ModalsUtil.toggle('#deposit_modal');
        //         } else {
        //             ModalsUtil.toggle('#login_signin');
        //         }
        //     }
        // },
        {
            orientation: "right",
            image: "/source/sliders/ru/cashback.png",
            text: "components.slider.cashback.text",
            descriptions: "components.slider.cashback.descriptions",
            button: "components.slider.cashback.button",
            buttonClick: ({isAuth, lang}) => {
                if(isAuth) {
                    ModalsUtil.toggle('#deposit_modal');
                } else {
                    ModalsUtil.toggle('#login_signin');
                }
            }
        },
        {
            orientation: "left",
            image: "/source/sliders/ru/live.png",
            text: "components.slider.live.text",
            descriptions: "components.slider.live.descriptions",
            button: "components.slider.live.button",
            buttonClick: ({isAuth, lang}) => {
                window.open('https://t.me/casinos_support_bot?start=azimut', '_blank');
            }
        },
        // {
        //     orientation: "right",
        //     image: "/source/sliders/ru/sports.png",
        //     text: "components.slider.sports.text",
        //     descriptions: "components.slider.sports.descriptions",
        //     button: "components.slider.sports.button",
        //     buttonClick: ({isAuth, lang, navigate}) => {
        //         if(!isAuth) {
        //             ModalsUtil.toggle('#login_signin');
        //         } else {
        //             navigate('/game/9448');
        //         }
        //     }
        // },
        {
            orientation: "left",
            image: "/source/sliders/ru/telegram.png",
            text: "components.slider.telegram.text",
            descriptions: "components.slider.telegram.descriptions",
            button: "components.slider.telegram.button",
            buttonClick: ({isAuth, lang, navigate}) => {
                window.open('https://t.me/+afv7fEdZc7Q0YTRh', '_blank');
            }
        },
        {
            orientation: "right",
            image: "/source/sliders/ru/welcome_bonus.png",
            text: "components.slider.welcome_bonus.text",
            descriptions: "components.slider.welcome_bonus.descriptions",
            button: "components.slider.welcome_bonus.button",
            buttonClick: ({isAuth, lang, navigate}) => {
                navigate('/promotions');
            },
            bonus_key: 'welcome_deposit'
        },
        // {
        //     orientation: "left",
        //     image: "/source/sliders/ru/whatsapp.png",
        //     text: "components.slider.whatsapp.text",
        //     descriptions: "components.slider.whatsapp.descriptions",
        //     button: "components.slider.whatsapp.button",
        //     buttonClick: ({isAuth, lang, navigate}) => {
                
        //     }
        // }
    ]
}