import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.user.bonuses,
    props.main.currency,
    (bonuses, currency) => {
        return {
            bonuses,
            currency
        }
    }
)