import {React, useEffect, useLayoutEffect} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {useSelector, useDispatch} from 'react-redux';
import Selector from '../selectors/layout';
import {getUser} from '../reducers/user/actions';
import {loadMain, loadCountrys, getPreset} from '../reducers/main/actions';
import {loadDeposit} from '../reducers/deposit/actions';
import {loadBonuses} from '../reducers/bonuses/actions';
import Device from '../utils/device';

import Header from './components/header';
import MobileNavigate from './components/movile-nav';
import Audio from './components/audio';
import Pages from './pages';

import Footer from './components/footer';
import Modals from './modals';
import Loader from './components/loader';
import Video from './components/video';

import ModalsUtil from '../utils/modals';
import HeaderUtil from '../utils/header';

import Socket from '../utils/websocket';
import Cookies from 'react-cookies';
import WaitElement from '../utils/waitElement';
import Toolbar from './components/toolbar';

export default () => {
    const location = useLocation(),
          dispatch = useDispatch();

    const {isAuth, loaded, states, background, language} = useSelector(Selector);

    useLayoutEffect(() => { 
        window.ModalsUtil = ModalsUtil;

        const searchParams = new URLSearchParams(location.search),
              referalCode = searchParams.get('ref');

        if(referalCode)
            Cookies.save('ref', referalCode, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            });

        if(!loaded) {
            if(!states.preset)
                dispatch(getPreset());
            else if(!states.user)
                dispatch(getUser());
            else if(!states.main)
                dispatch(loadMain());
            else if(!states.deposit)
                dispatch(loadDeposit());
            else if(!states.countrys)
                dispatch(loadCountrys());
            else if(!states.bonuses)
                dispatch(loadBonuses());
        }

        Socket.init();
    }); 

    useEffect(() => {
        if(loaded)
            HeaderUtil.setActive();
    }, [loaded]);

    useEffect(() => {
        if(isAuth)
            document.body.classList.add('user-auth');

        document.addEventListener('mobile_main', () => {
            const element = document.querySelector('.addMenu');

            if(element)
                element.scrollIntoView();
        })

        document.addEventListener('profile_bonus', () => {
            const element = document.querySelector('.profile-scroll');

            if(element)
                element.scrollIntoView();
        })

        document.addEventListener('main_favorites', () => {
            const element = document.querySelector('.addMenu');

            if(element)
                element.scrollIntoView();
        })

        return () => document.body.classList.remove('user-auth');
    }, [isAuth]);

    useEffect(() => {
        if(!Device.isMobile())
            window.scrollTo(0, 0);
        // ModalsUtil.close();
        HeaderUtil.setActive();
        document.dispatchEvent(new CustomEvent('header.close'));
    }, [location]);

    return (
        <> 
            {states.preset && (
                <Helmet>
                    <title>{states.preset.sitename}</title>
                    <meta name="descriptions" content={states.preset.general.descriptions} />
                    <meta name="keywords" content={states.preset.general.keywords} />
                </Helmet>
            )}

            {background && <Video />}
            <Loader />
            {loaded && (
                <>
                    <Header />
                    <Toolbar />
                    <MobileNavigate />

                    {/* Pages */}
                    <Routes>
                        <Route exact path='/' element={<Pages.Main />} />
                        <Route exact path='/game/:game_id' element={<Pages.Game />} />
                        <Route exact path='/bonus/:bonusId' element={<Pages.Bonus />} />
                        <Route exact path='/provider/:provider' element={<Pages.Provider />} />
                        <Route exact path='/games/:type' element={<Pages.Games />} />
                        <Route exact path='/profile' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/invite' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/transfer' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/referal' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/transactions' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/bonuses' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/profile/vouchers' element={isAuth ? <Pages.Profile /> : <Navigate to={'/'} />} />
                        <Route exact path='/promotions' element={<Pages.Promotions.Main />} />
                        <Route exact path='/promotions/example' element={<Pages.Promotions.Bonuses.Example />} />
                        <Route exact path='/faq' element={<Pages.FAQ />} />
                        <Route exact path='/tos' element={<Pages.TOS />} />
                        <Route path='*' element={<Pages.NotFound />} />
                    </Routes>

                    <Audio />
                    <Modals />
                    <Footer />
                </>
            )}
        </>
    )
}