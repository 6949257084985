const names = {
    "evolution": "Evolution Gaming",
    "kagaming": "KAGaming",

    "ainsworth": "Ainsworth",
    "amatic": "Amatic",
    "apex": "Apex",
    "apollo": "Apollo",
    "arcade": "Arcade",
    "aristocrat": "Aristocrat",
    "bally": "Bally",
    "bingo": "Bingo",
    "caleta": "Caleta",
    "cq9": "CQ9 Gaming",
    "dlv": "DLV",
    "egt": "EGT",
    "fast_games": "Fast Games",
    "fish": "Fish",
    "gclub": "GClub",
    "habanero": "Habanero",
    "igrosoft": "Igrosoft",
    "greentube": "Greentube",
    "igt": "IGT",
    "keno": "KENO",
    "merkur": "Merkur Gaming",
    "microgaming": "Microgaming",
    "netent": "NETENT",
    "novomatic": "Novomatic",
    "pragmatic": "Pragmatic Play",
    "quickspin": "Quickspin",
    "scientific_games": "SG",
    "sg": "SG",
    "ssg": "SSG",
    "tomhorn": "Tom Horn Gaming",
    "vegas": "Vegas",
    "wazdan": "WAZDAN",
    "yggdrasil": "YGGDRASIL",
    "roulette": "Roulette",
    "live_dealers": "Live Dealers",
    "table_games": "Table Games",
    "playngo": "Play`n GO",
    "redrake": "Red Rake Gaming",
    "fishing": "Fishing",
    "playtech": "Playtech",
    "relaxgaming": "RelaxGaming",
    "playson": "Playson",
    "betsoft": "Betsoft",
    "elbet": "Elbet",
    "hacksaw": "Hacksaw",
    "spadegaming": "SpadeGaming",
    "konami": "Konami",
    "gaminator": "Gaminator",
    "booongo": "Booongo",
    "kajot": "Kajot",
    "nolimit": "NoLimit",
    "spribe": "Spribe",
    "pushgaming": "Push Gaming",
    "cqgaming": "CQ Gaming",
    "austria": "Austria"
}

const getName = slug => {
    const name = names[slug] || false;
    return name ? name : slug;
}

const getTitle = slug => {
    const name = {
        "demo": "Demo Games",
        "new": "New Games",
        "popular": "Top 100 Popular Games",
        "wins": "Top 100 Best wins",
        "favorites": "Favorites",
        "search": "Search",
        "slots": "Slots",
        "live_dealers": "Live Dealers",
        "cards": "Cards",
        "all": "All Games"
    }[slug] || false;

    return name ? name : `[*${slug}]`;
}

export default {
    getName,
    getTitle
}