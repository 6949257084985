export default () => (
<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_79_57)">
<path d="M83.3332 16.6666C87.9582 16.6666 91.6666 20.375 91.6666 25V75C91.6666 79.625 87.9582 83.3333 83.3332 83.3333H16.6666C12.0416 83.3333 8.33325 79.625 8.33325 75V25C8.33325 20.375 12.0416 16.6666 16.6666 16.6666H83.3332ZM35.4166 62.5V37.5H30.2083V52.0833L19.7916 37.5H14.5833V62.5H19.7916V47.9166L30.4166 62.5H35.4166ZM56.2499 42.75V37.5H39.5833V62.5H56.2499V57.2916H45.8333V52.6666H56.2499V47.4166H45.8333V42.75H56.2499ZM85.4166 58.3333V37.5H80.2082V56.25H75.5416V41.6666H70.3332V56.25H65.6249V37.5H60.4166V58.3333C60.4166 59.4384 60.8556 60.4982 61.637 61.2796C62.4184 62.061 63.4782 62.5 64.5832 62.5H81.2499C82.355 62.5 83.4148 62.061 84.1962 61.2796C84.9776 60.4982 85.4166 59.4384 85.4166 58.3333Z"/>
</g>
<defs>
<filter id="filter0_d_79_57" x="-4" y="0" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_79_57"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_79_57" result="shape"/>
</filter>
</defs>
</svg>

)