export default {
    bonus: {
        title: "Бонусная сессия",
        text: "Вы не можете делать депозит во время бонусной сессии. Перейдите в раздел «<b>Мои бонусы</b>» для получения большей информации."
    },
    method: {
        title: "Депозит",
        method: "Выберите метод пополнения баланса:",
        next: "Дальше",
        wait: "Пожалуйста, подождите.."
    },
    freekassa: {
        text: "Введите желаемую сумму и нажмите «Пополнить» для перехода на страницу оплаты",
        comission: "Комиссия обработки",
        amount: "Сумма пополнения",
        receive: "Вы получите",
        button: "Пополнить",
        button_active: "Пожалуйста, подождите..."
    }
}