import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.game.modalGame,
    props.user.isAuth,
    props.user.bonuses,
    props.user.restrictions.wager,
    (game, isAuth, bonuses, wager) => {
        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            game,
            isAuth,
            active_bonus
        }
    }
)