import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.isAuth,
    props.main.type,
    props.user.bonuses,
    props.user.restrictions.wager,
    (isAuth, type, bonuses, wager) => {
        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            isAuth,
            type,
            active_bonus
        }
    }
)