import types from './types';
import Forms from './forms';

const InitialState = {
    window: 'method',
    error: null,
    forms: {
        cashapp: Forms.CashApp,
        freekassa: Forms.FreeKassa,
        bitcoin: {}
    },
    buttons: {
        next: false,
        cashapp: false,
        freekassa: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.forms.clear:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [payload]: InitialState.forms[payload]
                }
            }
        case types.forms.cashapp:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    cashapp: {
                        error: payload.error ? payload.error : state.forms.cashapp.error,
                        inputs: state.forms.cashapp.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;

                            if(update) 
                                return {
                                    ...input,
                                    ...update
                                }
                        
                            return input
                        })
                    }
                }
            }
        case types.forms.freekassa:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    freekassa: {
                        error: payload.error ? payload.error : state.forms.freekassa.error,
                        inputs: state.forms.freekassa.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;

                            if(update) 
                                return {
                                    ...input,
                                    ...update
                                }
                        
                            return input
                        })
                    }
                }
            }
        case types.clear:
            return {
                ...InitialState,
                buttons: state.buttons
            }
        case types.error:
            return {
                ...state,
                error: payload
            }
        case types.window:
            return {
                ...state,
                window: payload
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}