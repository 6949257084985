import types from './types';

const InitialState = {
    gameId: null,
    game: null,
    demo: false,
    session: null,
    modalGame: null,
    buttons: {
        favorite: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.modalGame:
            return {
                ...state,
                modalGame: payload
            }
        case types.session:
            return {
                ...state,
                session: payload.session,
                demo: payload.demo
            }
        case types.toggleDemo:
            return {
                ...state,
                demo: payload,
                session: null
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.favorite:
            return {
                ...state,
                game: (state.game && state.game.id == payload.game) ? {
                    ...state.game,
                    favorite: payload.state
                } : state.game
            }
        case types.setGameId:
            return {
                ...state,
                gameId: payload,
                game: null
            }
        case types.loaded:
            return {
                ...state,
                game: payload.game,
                session: null
            }
        default:
            return state;
    }
}