import types from './types';

const InitialState = {
    show: 50,
    provider: null
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.show:
            return {
                ...state,
                show: state.show + InitialState.show
            }
        case types.provider:
            return {
                ...state,
                provider: payload,
                show: InitialState.show
            }
        default:
            return {
                ...state
            }
    }
}