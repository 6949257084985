export default {
    signup: {
        stage: ({auth}) => auth.signup.stage,
        form: ({auth}) => auth.signup.form,
        token: ({auth}) => auth.signup.token
    },
    signin: {
        form: ({auth}) => auth.signin.form
    },
    buttons: {
        approveAccountInformation: ({auth}) => auth.buttons.approveAccountInformation,
        signup: ({auth}) => auth.buttons.signup,
        signin: ({auth}) => auth.buttons.signin
    }
}