import React, {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../../../selectors/components/jackpot';
import ModalsUtil from '../../../utils/modals';
import AnimatedNumbers from "react-animated-numbers";

class Jackpot extends Component {
    constructor() {
        super();
    }

    getEmptySlots() {
        let numLength = Math.floor(this.props.jackpot).toString().length;

        const slots = [];
        for(let i = 0; i < (7 - numLength); i++)
            slots.push(i);

        return slots;
    }

    render() {
        const {jackpot, currency} = this.props;

        return (
            <div 
                class="loop__jackpot" 
                style={{
                    cursor: 'pointer'
                }}
                onClick={e => {
                    ModalsUtil.toggle('#jackpot_modal');
                }}>
                    
                <p class="numbers txt18x23 w800">
                    <span class="numbers__num numbers__num--black">{currency.symbol}</span>
                    {this.getEmptySlots().map((slot, key) => {
                        return <span class="numbers__num" key={key}> </span>
                    })}
                    <AnimatedNumbers
                        includeComma={false}
                        animateToNumber={Math.floor(jackpot)}
                        fontStyle={{ fontSize: 32, height: '48px', lineHeight: '47px' }}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    ></AnimatedNumbers>
                </p>
                <p class="loop__jackpotText txt18x23 w800">
                    JACKPOT
                </p>
            </div>
        )
    }
}

export default connect(Selector) (Jackpot);