export default {
    bonus: {
        title: "Бонусная сессия",
        text: "Вы не можете совершать вывод во время бонусной сессии. Перейдите в раздел «<b>Мои бонусы</b>» для получения большей информации."
    },
    method: {
        title: "Вывод средств",
        method: "Выберите способ вывода средств:",
        next: "Дальше",
        wait: "Пожалуйста, подождите.."
    },
    freekassa: {
        methods: {
            umoney: {
                title: "Номер кошелька",
                text: "Введите желаемую сумму и номер кошелька <b>YooMoney</b> для вывода средств. В случае, если вы указали неверный номер кошелька - <b>средства будут утеряны</b>",
                placeholder: "0000 0000 0000 0000"
            },
            visa: {
                title: "Номер карты",
                text: "Введите желаемую сумму и номер карты <b>Visa</b> для вывода средств. В случае, если вы указали неверный номер карты - <b>средства будут утеряны</b>",
                placeholder: "0000 0000 0000 0000"
            },
            mir: {
                title: "Номер карты",
                text: "Введите желаемую сумму и номер карты <b>МИР</b> для вывода средств. В случае, если вы указали неверный номер карты - <b>средства будут утеряны</b>",
                placeholder: "0000 0000 0000 0000"
            },
            mc: {
                title: "Номер карты",
                text: "Введите желаемую сумму и номер карты <b>MasterCard</b> для вывода средств. В случае, если вы указали неверный номер карты - <b>средства будут утеряны</b>",
                placeholder: "0000 0000 0000 0000"
            },
            erc20: {
                title: "Адрес кошелька",
                text: "Введите желаемую сумму и укажите адрес вашего <b>USDT</b> кошелька в сети <b>ERC20</b>. В случае, если вы указали неверный адрес кошелька - <b>средства будут утеряны</b>",
                placeholder: "0x5aAeb6053F3E94C9b9A***33669435E7Ef1BeAed"
            },
            trc20: {
                title: "Адрес кошелька",
                text: "Введите желаемую сумму и укажите адрес вашего <b>USDT</b> кошелька в сети <b>TRC20</b>. В случае, если вы указали неверный адрес кошелька - <b>средства будут утеряны</b>",
                placeholder: "TR7NHqjeKQxGTC***8ZY4pL8otSzgjLj6t"
            },
            litecoin: {
                title: "Адрес кошелька",
                text: "Введите желаемую сумму и укажите адрес вашего <b>Litecoin</b> кошелька. В случае, если вы указали неверный адрес кошелька - <b>средства будут утеряны</b>",
                placeholder: "LgyH6Wjait98hjxLp***yzx3WtGzJL5994"
            },
            ethereum: {
                title: "Адрес кошелька",
                text: "Введите желаемую сумму и укажите адрес вашего <b>Ethereum</b> кошелька в сети <b>ERC20</b>. В случае, если вы указали неверный адрес кошелька - <b>средства будут утеряны</b>",
                placeholder: "0x5aAeb6053F3E94C9b9A***33669435E7Ef1BeAed"
            },
            trx: {
                title: "Адрес кошелька",
                text: "Введите желаемую сумму и укажите адрес вашего <b>Tron</b> кошелька в сети <b>TRC20</b>. В случае, если вы указали неверный адрес кошелька - <b>средства будут утеряны</b>",
                placeholder: "TR7NHqjeKQxGTC***8ZY4pL8otSzgjLj6t"
            }
        },
        amountIn: symbol => `Сумма в ${symbol}`,
        comission: "Комиссия обработки",
        minimal: "Минимальная сумма",
        maximal: "Максимальная сумма",
        course: "Курс",
        amount: "Сумма",
        receive: "Вы получите",
        button: "Вывести средства",
        button_active: "Пожалуйста, подождите...",
        processing: "Обработка вывода занимает от 5 до 20 минут. В редких случаях обработка может занимать до 48 часов"
    }
}