export default (selector, area = null) => new Promise((resolve) => {
    if(area === null)
        area = document;

    const element = area.querySelector(selector);

    if(element)
        return resolve(element);

    const observer = new MutationObserver(mutations => {
        if(area.querySelector(selector)) {
            observer.disconnect();
            return resolve(area.querySelector(selector));
        }
    });

    observer.observe(area, {
        childList: true,
        subtree: true
    });
});