import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.main.modals.info.title,
    props.main.modals.info.text,
    (title, text) => {
        return {
            title, text
        }
    }
)