export default {
    title: "Мои транзакции",
    table: {
        type: {
            title: "Тип",
            deposit: "Депозит",
            cashout: "Вывод"
        },
        paymentId: "ID платежа",
        method: {
            title: "Метод",
            cashapp: "CashApp",
            bitcoin: "Bitcoin",
            umoney: "Ю.Money",
            steam: "Steam Pay",
            perfect_money: "Perfect Money",
            gpay: "Google Pay",
            apay: "Apple Pay",
            webmoneyz: "WebMoney WMZ",
            visa: "Visa",
            mir: "МИР",
            mc: "MasterCard",
            megafon: "Мегафон",
            mts: "МТС",
            tele2: "Tele2",
            beeline: "Билайн",
            sbp: "СБП",
            erc20: "USDT [ERC20]",
            trc20: "USDT [TRC20]",
            litecoin: "Litecoin",
            ethereum: "Ethereum",
            trx: "TRX"
        },
        amount: "Сумма",
        status: {
            title: "Статус",
            wait: "Ожидается..",
            pending: "Обрабатывается...",
            moderate: "На модерации",
            done: "Обработан",
            done_auto: "Обработан",
            rejected: "Отменен"
        },
        date: "Дата"
    },
    show_more: ({count}) => `Показать еще (${count})`
}