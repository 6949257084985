import bonuses from './bonuses';
import navigation from './navigation';
import transactions from './transactions';
import referral from './referral';

export default {
    bonuses,
    navigation,
    transactions,
    referral,
    stats: {
        deposits: "Сумма депозитов",
        cashouts: "Сумма выводов",
        winrate: "Коэффициент выигрыша"
    },
    profile: {
        registered: ({timestamp}) => {
            const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
            const date = new Date(timestamp);

            return `Зарегистрирован ${date.getUTCDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
        },
        uniqueId: "Уникальный идентификатор",
        nickname: "Имя пользователя",
        email: "e-Mail",
        phone: "Номер телефона"
    }
}