import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/promotions';
import PresetSelector from '../../../selectors/preset';
import {activateBonus} from '../../../reducers/bonuses/actions';
import {useNavigate} from 'react-router-dom';
import LoaderUtil from '../../../utils/loader';
import ModalsUtil from '../../../utils/modals';
import Lang from '../../../lang';
import {currency_short as currency_format, getMaximal} from '../../../utils/types';
import {Helmet} from 'react-helmet';

import Cashback from './cashback';
import Phone from './phone';
import WhatsApp from './whatsapp';

export default () => {
    const {bonuses, isAuth, currency, active_bonus, bonuses_states} = useSelector(Selector),
          {preset, language} = useSelector(PresetSelector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    useEffect(() => {
        LoaderUtil.hide();
    });

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].bonuses)}</title>
            </Helmet>
            <section class="promotion">
                <div class="wrapper">
                    <div class="listGames__top">
                        <p class="listGames__title txt32x39">
                            {Lang('bonuses.title')}
                        </p>
                    </div>
                    <ul class="promotion__list">
                        <Cashback currency={currency} active_bonus={active_bonus} isAuth={isAuth} />
                        {bonuses.map((bonus, key) => {
                            const type_exist = typeof bonuses_states[bonus.type] !== 'undefined',
                                  bonus_show = (type_exist && ['whatsapp_bonus', 'phone_bonus'].includes(bonus.type)) ? !bonuses_states[bonus.type] : true;

                            if(!bonus_show)
                                return <></>;

                            if(!bonus)
                                return <></>;

                            return (
                                <li class="promotion__item" key={key}>
                                    <div class="promotion__block">
                                        <div class="promotion__img">
                                            <img 
                                                src={bonus.game ? bonus.game.image : bonus.image} 
                                                alt={bonus.content[language].name} />
                                        </div>

                                        <p class="promotion__title txt20x26 cWh">
                                            {bonus.content[language].name}
                                        </p>

                                        <div 
                                            class="invite-details" 
                                            style={{marginBottom: "10px"}}>
                                                <p>{bonus.content[language].description}</p>
                                        </div>

                                        {bonus.details.deposit_include && (
                                            <div className="promotion__rules promotion__rules-freespins">
                                                <span>{Lang('bonuses.names.deposit')}</span>
                                                <div className="promotion__rules__value">{Lang('bonuses.names.deposits', {deposits: bonus.details.deposits, currency})}</div>
                                            </div>
                                        )}

                                        {bonus.details.bonus.amount > 0 && (
                                            <>
                                                {bonus.details.bonus.amount_type === "multiplier" && (
                                                    <div className="promotion__rules promotion__rules-bonus">
                                                        <span>{Lang('bonuses.names.bonus')}</span>
                                                        <div className="promotion__rules__value">X{bonus.details.bonus.amount}</div>
                                                    </div>
                                                )}

                                                {bonus.details.bonus.amount_type === "percent" && (
                                                    <div className="promotion__rules promotion__rules-bonus">
                                                        <span>{Lang('bonuses.names.bonus')}</span>
                                                        <div className="promotion__rules__value">+{bonus.details.bonus.amount}%</div>
                                                    </div>
                                                )}

                                                {bonus.details.bonus.amount_type === "amount" && (
                                                    <div className="promotion__rules promotion__rules-bonus">
                                                        <span>{Lang('bonuses.names.bonus')}</span>
                                                        <div className="promotion__rules__value">{currency_format(bonus.details.bonus.amount, currency.key, currency.symbol)}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {bonus.details.bonus.freespins_enabled && (
                                            <>
                                                {bonus.details.bonus.freespins_type === "fixed" && (
                                                    <div className="promotion__rules promotion__rules-bonus">
                                                        <span>{Lang('bonuses.names.freespins')}</span>
                                                        <div className="promotion__rules__value">{bonus.details.bonus.freespins_count}</div>
                                                    </div>
                                                )}

                                                {bonus.details.bonus.freespins_type === "devider" && (
                                                    <div className="promotion__rules promotion__rules-bonus">
                                                        <span>{Lang('bonuses.names.freespins')}</span>
                                                        <div className="promotion__rules__value">{getMaximal(Math.round(bonus.details.deposits.from / bonus.details.bonus.freespins_count), bonus.details.bonus.freespins_minimal_count)}-{getMaximal(Math.round(bonus.details.deposits.to / bonus.details.bonus.freespins_count), bonus.details.bonus.freespins_maximal_count)}</div>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {bonus.wager.type === 'limit' ? (
                                            <>
                                                {bonus.wager.fixed ? (
                                                    <div className="promotion__rules promotion__rules-wager">
                                                        <span>{Lang('bonuses.names.redeem')}</span>
                                                        <div className="promotion__rules__value">{currency_format(bonus.wager.value, currency.key, currency.symbol)}</div>
                                                    </div>
                                                ) : (
                                                    <div className="promotion__rules promotion__rules-wager">
                                                        <span>{Lang('bonuses.names.redeem')}</span>
                                                        <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="promotion__rules promotion__rules-wager">
                                                <span>{Lang('bonuses.names.wager')}</span>
                                                <div className="promotion__rules__value">X{bonus.wager.value}</div>
                                            </div>
                                        )}

                                        <button 
                                            class="btn"
                                            onClick={e => {
                                                if(!isAuth)
                                                    return ModalsUtil.toggle('#login_signin');

                                                if(bonus.disabled)
                                                    return;

                                                if(bonus.type === 'whatsapp_bonus')
                                                    return window.alert('Under maintance [whatsapp]');

                                                if(bonus.type === 'phone_bonus')
                                                    return window.alert('Under maintance [phone]');

                                                dispatch(activateBonus(bonus.id, navigate, currency));
                                            }}
                                            style={{
                                                color: "#14143d",
                                                cursor: bonus.disabled ? 'no-drop' : 'pointer',
                                                opacity: bonus.disabled ? 0.7 : 1
                                            }}
                                            >
                                                {['whatsapp_bonus', 'phone_bonus'].includes(bonus.type) ? (
                                                    <>
                                                        {bonus.type === "whatsapp_bonus" && (!bonus.disabled ? Lang('pages.promotions.whatsapp') : Lang('pages.promotions.wait'))}
                                                        {bonus.type === "phone_bonus" && (!bonus.disabled ? Lang('pages.promotions.phone') : Lang('pages.promotions.wait'))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {!bonus.disabled ? Lang('pages.promotions.activate') : Lang('pages.promotions.wait')}
                                                    </>
                                                )}
                                            </button>

                                        <button 
                                            class="btn btn--outside"
                                            onClick={e => navigate('/promotions/example')}
                                            style={{marginTop: "10px"}}
                                            >{Lang('pages.promotions.details')}</button>
                                    </div>
                                </li>
                            )
                        })}
                        {/* {!bonuses_states.phone_bonus && <Phone currency={currency} active_bonus={active_bonus} isAuth={isAuth} />} */}
                        {/* {!bonuses_states.whatsapp_bonus && <WhatsApp currency={currency} active_bonus={active_bonus} isAuth={isAuth} />} */}
                    </ul>
                </div>
            </section>
        </>
    )
}