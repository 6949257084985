export default {
    loaded: ({deposit}) => deposit.loaded,
    window: ({deposit}) => deposit.window,
    error: ({deposit}) => deposit.error,
    bonusId: ({deposit}) => deposit.bonusId,
    methods: ({deposit}) => deposit.methods,
    methods_map: ({deposit}) => deposit.methods_map,
    currencies: ({deposit}) => deposit.currencies,
    bonus: ({deposit}) => {
        if(deposit.bonusId !== null)
            return true;

        return deposit.bonusId
    },
    btc: ({deposit}) => deposit.btc,
    cashapp: {
        payment: ({deposit}) => deposit.cashapp.payment,
        form: ({deposit}) => deposit.cashapp.form
    },
    freekassa: {
        form: ({deposit}) => deposit.freekassa.form
    },
    buttons: {
        cashapp_create_request: ({deposit}) => deposit.buttons.cashapp_create_request,
        skip_bonus: ({deposit}) => deposit.buttons.skip_bonus,
        cancel_pending_order: ({deposit}) => deposit.buttons.cancel_pending_order,
        confirm_pending_order: ({deposit}) => deposit.buttons.confirm_pending_order,
        freekassa_payment: ({deposit}) => deposit.buttons.freekassa_payment
    }
}