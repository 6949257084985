import {number, currency_short} from '../../../utils/types';

export default {
    notFound: ({id}) => `Не удалось найти бонус #${id}`,
    noSession: ({id}) => `Не удалось загрузить игровую сессию для бонуса #${id}. Свяжитесь с техничкской поддержкой для решения этого вопроса или попробуйте чуть позднее..`,
    activate: {
        activated: ({content, details, currency}, language) => `Бонус «<b>${content[language].name}</b>» успешно активирован! На ваш баланс зачислено ${number(details.bonus.amount)} ${currency}`,
        wait_deposit: ({content, details, currency}, language) => `Бонус «<b>${content[language].name}</b>» успешно активирован и ожидает депозит в размере ${number(details.deposits.from)}${details.deposits.to ? ` - ${number(details.deposits.to)}` : ''} ${currency}`,
        haveBonus: `Вы не можете активировать бонус, поскольку у вас уже имеется бонус, ожидающий депозит. Для подробностей перейдите в раздел «<b>Мои бонусы</b>» в вашем профиле.`,
        haveActiveBonus: `Вы не можете активировать бонус, поскольку вы находитесь в бонусной сессии.`,
        balance: "Не удалось получить Ваш текущий баланс! Попробуйте чуть позже..",
        balance_amount: ({amount, currency}) => `Для активации бонуса на Вашем балансе должно быть не более ${amount} ${currency}`,
        wager: `Не удалось открыть отыгрыш для бонуса! Попробуйте чуть позже или свяжитесь с технической поддержкой для решения этого вопроса.`,
        refill: `Не удалось зачислить бонусный средства на ваш баланс. Попробуйте чуть позже или свяжитесь с технической поддержкой для решения этого вопроса.`
    },
    remove: {
        cantResetBalance: `Не удалось обнулить ваш баланс при удалении бонуса. Попробуйте чуть позднее или свяжитесь с технической поддержкой для решения этого вопроса...`,
        success: ({content, details}, language) => `Бонус «<b>${content[language].name}</b>» успешно удален! Все ограничения с Вашего аккаунта были сняты, а баланс обнулен.`
    },
    canceled: ({content, details}, language) => `Бонус «<b>${content[language].name}</b>» успешно отменен!`,
    voucher: {
        success: ({content, details, currency}, language) => `Ваучер «<b>${content[language].name}</b>» успешно активирован!`,
        wait_deposit: ({content, details, currency}, language) => `Ваучер «<b>${content[language].name}</b>» успешно активирован и ожидает депозит в размере ${number(details.deposits.from)}${details.deposits.to ? ` - ${number(details.deposits.to)}` : ''} ${currency}`,
        haveBonus: `Вы не можете активировать промокод, поскольку у вас уже имеется бонус, ожидающий депозит. Для подробностей перейдите в раздел «<b>Мои бонусы</b>» в вашем профиле.`,
        haveActiveBonus: `Вы не можете активировать промокод, поскольку вы находитесь в бонусной сессии.`,
        empty: "Вы не ввели промокод для активации",
        notFound: ({voucher}) => `Промокод «${voucher}» не найден`,
        expired: ({voucher}) => `Промокод «${voucher}» закончился`,
        already: ({voucher}) => `Вы уже активировали промокод «${voucher}»`,
        countryNotFound: 'Не удалось найти Вашу страну',
        country: ({voucher}) => `Промокод «${voucher}» недоступен в вашей стране`,
        balance: "Не удалось получить Ваш текущий баланс! Попробуйте чуть позже..",
        balance_amount: ({amount, currency}) => `Для активации ваучера на Вашем балансе должно быть не более ${amount} ${currency}`,
        deposits: "Не удалось загрузить сумму Ваших депозитов! Попробуйте чуть позже...",
        deposit_rule: ({voucher, amount, days, currency}) => `Промокод «${voucher}» доступен только для игроков, кто сделал депозитов на сумму ${currency_short(amount, currency.key, currency.symbol)} в течении последних ${days} дней`,
        multi_error: "Нам не удалось проверить Вас на мультиаккаунт. Попробуйте чуть позже..",
        multi: "Наша система заметила, что один из Ваших аккаунтов уже активировал этот промокод",
        restrictions: "Не удалось открыть отыгрыш для Вас. Свяжитесь с технической поддержкой для решения этого вопроса",
        cantBeLoaded: "Не удалось зачислить бонус на Ваш аккаунт. Попробуйте еще раз или свяжитесь с технической поддержкой для решения этого вопроса"
    }
}