import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.main.bonus,
    props.main.sounds,
    (bonus, sounds) => {
        return {
            bonus,
            sounds
        }
    }
)