export default {
    signup: {
        stage: 'signup.stage',
        form: 'signup.update.form',
        formReset: 'signup.reset.form',
        token: 'signup.token'
    },
    signin: {
        form: 'signin.update.form',
        formReset: 'signin.reset.form'
    },

    toggleButton: 'auth.toggle.button'
}