import Poker from './cards';
import Popular from './cup';
import Fish from './fish';
import Home from './home';
import Livedealers from './live';
import New from './new';
import Search from './search';
import Favorites from './star';
import Instant from './instant';
import Bonus_buy from './bonus-buy';
import Wagering from './wagering';
import Profile from './profile';
import Deposit from './deposit';
import Game_show from './mic';
import Dice from './dice';
import Baccarat from './baccarat';
import Blackjack from './blackjack';
import Slots from './slots';
import Roulette from './roulette';
import GamesOne from './games-one';
import GamesTwo from './games-two';
import GamesThree from './games-three';
import Like from './like';
import Game from './game';
import Wallet from './wallet';
import Close from './close';
import Operator from './operator';
import Jackpots from './jackpots';
import Fastgames from './fast-games';


export default {
    Poker,
    Popular,
    Fish,
    Home,
    Livedealers,
    New,
    Search,
    Favorites,
    Instant,
    Bonus_buy,
    Wagering,
    Profile,
    Deposit,
    Game_show,
    Dice,
    Baccarat,
    Blackjack,
    Slots,
    Roulette,
    GamesOne,
    GamesTwo,
    GamesThree,
    Like,
    Game,
    Wallet,
    Close,
    Operator,
    Jackpots,
    Fastgames
}