import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.deposit.cashapp.form,
    props.deposit.buttons.cashapp_create_request,
    (form, button) => {
        return {
            form, 
            button
        }
    }
)
