import {currency} from '../../../../utils/types';

export default {
    input: {
        title: "У вас есть промокод?",
        descriptions: "Введите ваш промокод здесь и нажмите кнопку «Активировать» для получения бонуса",
        placeholder: "Введите промокод",
        button: "Активировать",
        wait: "Подождите..."
    },
    bonuses: {
        title: "Мои бонусы",
        buttons: {
            won: ({amount, currency: _currency}) => `Выигрыш ${currency(amount || 0, _currency.key, _currency.symbol)}`,
            lose: "Проигрыш",
            rules: "Условия не выполнены",
            canceled: "Отменен",
            freespins: ({count}) => `Фриспины (${count})`,
            deposit: "Депозит",
            remove: "Удалить бонус",
            bonus_info: "Условия бонуса"
        }
    }
}