import React, {useEffect} from 'react';
import LoaderUtil from '../../../utils/loader';
import Sidebar from './sidebar';
import Tabs from './tabs';

export default () => {
    useEffect(() => {
        LoaderUtil.hide();
    });

    return (
        <main class="profileWr">
            <div class="profileWr__wrapper wrapper">
                <Sidebar />
                <Tabs />
            </div>
        </main>
    )
}