export default () => (
<svg xmlns="http://www.w3.org/2000/svg" width="641" height="512" viewBox="0 0 641 512" fill="none">
    <g clip-path="url(#clip0_125_9)">
        <path d="M592.833 192H474.093C486.783 221.59 481.213 257.2 457.093 281.32L320.833 417.58V464C320.833 490.51 342.323 512 368.833 512H592.833C619.343 512 640.833 490.51 640.833 464V240C640.833 213.49 619.343 192 592.833 192ZM480.833 376C467.583 376 456.833 365.25 456.833 352C456.833 338.74 467.583 328 480.833 328C494.083 328 504.833 338.74 504.833 352C504.833 365.25 494.083 376 480.833 376ZM434.463 189.3L259.533 14.37C240.373 -4.79 209.303 -4.79 190.143 14.37L15.203 189.3C-3.95699 208.46 -3.95699 239.53 15.203 258.69L190.133 433.63C209.293 452.79 240.363 452.79 259.523 433.63L434.463 258.7C453.623 239.53 453.623 208.46 434.463 189.3ZM96.833 248C83.583 248 72.833 237.25 72.833 224C72.833 210.74 83.583 200 96.833 200C110.083 200 120.833 210.74 120.833 224C120.833 237.25 110.083 248 96.833 248ZM224.833 376C211.583 376 200.833 365.25 200.833 352C200.833 338.74 211.583 328 224.833 328C238.083 328 248.833 338.74 248.833 352C248.833 365.25 238.083 376 224.833 376ZM224.833 248C211.583 248 200.833 237.25 200.833 224C200.833 210.74 211.583 200 224.833 200C238.083 200 248.833 210.74 248.833 224C248.833 237.25 238.083 248 224.833 248ZM224.833 120C211.583 120 200.833 109.25 200.833 96C200.833 82.74 211.583 72 224.833 72C238.083 72 248.833 82.74 248.833 96C248.833 109.25 238.083 120 224.833 120ZM352.833 248C339.583 248 328.833 237.25 328.833 224C328.833 210.74 339.583 200 352.833 200C366.083 200 376.833 210.74 376.833 224C376.833 237.25 366.083 248 352.833 248Z"/>
    </g>
    <defs>
        <clipPath id="clip0_125_9">
            <rect width="640" height="512" fill="white" transform="translate(0.833008)"/>
        </clipPath>
    </defs>
</svg>
)