import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.data,
    props.main.countrys,
    props.user.restrictions.wager,
    (user, countrys, wager) => {
        return {
            user,
            country: (user && countrys) ? countrys.filter(c => c.code === user.country)[0] || null : null,
            wager
        }
    }
)