import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.bonuses.list,
    props.user.isAuth,
    props.main.currency,
    props.user.bonuses,
    props.user.restrictions.wager,
    props.user.bonuses_states,
    (bonuses, isAuth, currency, user_bonuses, wager, bonuses_states) => {
        const active_bonus = wager ? user_bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            bonuses,
            isAuth,
            currency,
            active_bonus,
            bonuses_states
        }
    }
)