import {createSelector} from 'reselect';
import props from '../../props';

const words_list = {
    baccarat: ['baccarat', 'bacar'],
    blackjack: ['blackjack'],
    poker: ['poker', 'holdem'],
    roulette: ['roulette', 'ruletka', 'ruletti', 'roleta', 'ruleta', 'rulet'],
    dice: ['craps', 'dice', 'sicbo', 'bacbo']
}

const getAllWords = () => {
    let words = [];
    for(const key of Object.keys(words_list))
        words = words.concat(words_list[key])

    return words;
}

const checkWords = (string, words) => {
    for(const word of words)
        if(string.toLowerCase().replace(/[^a-z]/gi, '').indexOf(word) >= 0) {
            return true;
        }

    return false;
}

export default createSelector(
    props.main.type,
    props.main.games,
    props.main.show,
    props.main.search,
    props.user.isAuth,
    props.user.bonuses,
    props.user.restrictions.wager,
    (type, games, show, search, isAuth, bonuses, wager) => {
        // Make games list
        if(type === 'home') {
            const words = getAllWords();

            games = {
                new: games.filter(game => game.new),
                game_show: games.filter(game => {
                    return game.gameId[0] === 'P' && !checkWords(game.name, words);
                }).sort((a, b) => a.name.localeCompare(b.name)),
                recommend: games.filter(game => game),
                all: games,
                top: games.filter(game => game.picks >= 0).sort((a, b) => {
                    if(a.cross_rating < b.picks) return -1;
                    if(a.cross_rating > b.picks) return 1;
                    return 0;
                }).filter((a, key) => key < 50)
            }
        } else if(type === 'new') {
            games = games.filter(game => game.new);
        } else if(type === 'popular') {
            games = games.filter(game => game.picks >= 0).sort((a, b) => {
                if(a.cross_rating < b.picks) return -1;
                if(a.cross_rating > b.picks) return 1;
                return 0;
            }).filter((_, key) => key < 150);
        } else if(type === 'favorites') {
            games = games.filter(game => game.favorite);
        } else if(type === 'search') {
            games = games.filter(game => game.name.replace(/ /gi, '').toLowerCase().indexOf(search.replace(/ /gi, '').toLowerCase()) >= 0);
        } else if(type === "fish") {
            games = games.filter(game => ['T609', 'T610', 'T760', 'T761', "K3","K6","K875","K788","K833","K1078","K900","K781","K796","K2","K360","K353","K379","K777","K374","K8","K1075","K341","K4","K854","K1045","K906","K808","K1110","K336","K335","K891","K1","K839","K5","K914","K868","K359","K1087","K955","K938","K324","K923","K329","K347","K301","K1126","K365","K816","K348","K961","K947","K1059","K982","K373","K7","K991","K259","K884","K260","K393"].includes(game.gameId));
        } else if(type === "slots") {
            games = games.filter(game => {
                return game.gameId[0] === 'T' && !['T609', 'T610', 'T760', 'T761', 'T915', 'T951', 'T896', 'T887', 'T914', 'T48', 'T331', 'T332', 'T443'].includes(game.gameId)
            })
        } else if(type === "game_show") {
            const words = getAllWords();

            games = games.filter(game => {
                return game.gameId[0] === 'P' && !checkWords(game.name, words);
            }).sort((a, b) => a.name.localeCompare(b.name));
        } else if(type === "live") {
            let games_list = {};

            for(const key of Object.keys(words_list)) {
                if(key === "roulette")
                    continue;

                const games_type = games.filter(game => game.gameId[0] === 'P' && checkWords(game.name, words_list[key]));

                if(games_type.length > 0)
                    games_list[key] = games_type.sort((a,b) => a.name.localeCompare(b.name));
            }

            games = games_list;
        } else if(type === "roulette") {
            games = games.filter(game => checkWords(game.name, words_list.roulette));
        } else if(type === "instant") {
            games = games.filter(game => ['T915', 'T951', 'T896', 'T887', 'T914', 'K1111', 'K1080', 'K1095'].includes(game.gameId));
        } else if(type === "bonus_buy") {
            games = games.filter(game => ['T912', 'T842', 'T868', 'T817', 'T841', 'T774', 'T895', 'T878', 'T885', 'T886', 'T894', 'T889', 'T858', 'T820', 'T856', 'T819', 'T813', 'T757', 'T759', 'T823', 'T768', 'T771', 'T822', 'T800', 'T821', 'T805', 'T758', 'T767', 'T812', 'T875', 'T843', 'T876', 'T844', 'T845', 'T272', 'T877', 'T884', 'T799', 'T744', 'T803', 'T818', 'T796', 'T553', 'T629', 'T525', 'T526', 'T852', 'T853'].includes(game.gameId));
        } else if(type === "jackpots") {
            games = games.filter(game => ['wazdan', 'amatic', 'pragmatic', 'netent', 'greentube'].includes(game.title));
        } else if(type === "fastgames") {
            games = games.filter(game => ['T896', 'K928', 'K924', 'K287', 'K876', 'K78', 'K901', 'K240', 'K886', 'K869', 'K887', 'K930', 'K65', 'K907', 'K962', 'K782', 'K892', 'K915', 'K939', 'K885', 'K983', 'K1049', 'K1127', 'K966', 'K948', 'K974', 'K956'].includes(game.gameId))
        }

        const active_bonus = wager ? bonuses.filter(bonus => bonus.id == wager.details.bonus)[0] || null : null;

        return {
            type,
            games,
            isAuth,
            has_more: games.length ? games.length > show : false,
            more_count: games.length ? games.length - show : 0,
            show,
            search,
            active_bonus
        }
    }
)