import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/deposit';
import {clear} from '../../../reducers/deposit/actions';

import Method from './method';
import Bitcoin from './bitcoin';
import CashApp from './cashapp';
import CashAppPayment from './cashapp-payment';
import FreeKassa from './freekassa';

export default () => {
    const {method, methods, _methods, hasPayment} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('modal.closed', e => {
            if(e.detail === 'deposit_modal')
                dispatch(clear());
        });
    });

    const method_json = _methods.filter(m => m.key === method)[0] || false;

    return (
        <div class="popup" id="deposit_modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            {(method === 'method' || !method_json) ? <Method methods={methods} /> : (
                <>
                    {method_json.processing === 'freekassa' ? <FreeKassa method={method_json} /> : (
                        <>
                            {/* {method === 'bitcoin' && <Bitcoin />}
                            {method === 'cashapp' && (hasPayment ? <CashAppPayment /> : <CashApp />)}
                            {method === 'cashapp-request' && <CashAppPayment />} */}
                        </>
                    )}
                </>
            )}
        </div>
    )
}