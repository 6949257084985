import React from 'react';
import Language from './language';
import Sounds from './sounds';
import Background from './background';

export default () => (
    <div className="toolbar">
        <Language />
        <hr />
        <Sounds />
        <hr />
        <Background />
    </div>
)