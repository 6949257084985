export default ({game, active_bonus, modal = false}) => {
    if(!active_bonus || ['T609', 'T610', 'T760', 'T761'].includes(game.gameId))
        return <></>;   

    if(active_bonus.details.games_restrictions.enabled && !active_bonus.details.games_restrictions.list.includes(game.gameId))
        return <></>;

    if(!active_bonus.details.games_restrictions.enabled && !game.is_wager)
        return <></>;

    if(modal)
        return (
            <>
                <div className="game-wager">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect width="24" height="24" rx="12" fill="#FF6868"/>
                        <path d="M8.3335 7.41675V16.5834L11.771 12.0001L15.2085 16.5834V7.41675" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    В этой игре вы можете отыгрывать бонусные сессии
                </div>  
            </>
        )

    return (
        <div className="game-wager">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" rx="12" fill="#FF6868"/>
                <path d="M8.3335 7.41675V16.5834L11.771 12.0001L15.2085 16.5834V7.41675" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    )
}