import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/cashout';
import {clear} from '../../../reducers/cashout/actions';

import Method from './method';
import FreeKassa from './freekassa';


export default () => {
    const {method, methods, _methods, currencies} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('modal.closed', e => {
            if(e.detail === 'cashout_modal')
                dispatch(clear());
        });
    });

    const method_json = _methods.filter(m => m.key === method)[0] || false;

    return (
        <div class="popup" id="cashout_modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            {(method === 'method' || !method_json) ? <Method methods={methods} /> : (
                <>
                    {method_json.processing === 'freekassa' ? <FreeKassa method={method_json} currencies={currencies} /> : (
                        <>
                        
                        </>
                    )}
                </>
            )}
        </div>
    )
}