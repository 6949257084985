export const SignUp = {
    stage: 1,
    token: null,
    form: {
        error: null,
        inputs: [
            {
                key: 'username',
                status: 'default',
                error: null
            },
            {
                key: 'email',
                status: 'default',
                error: null
            },
            {
                key: 'phone',
                status: 'default',
                error: null
            },
            {
                key: 'password',
                status: 'default',
                error: null
            },
    
            // Personal Information
            {
                key: 'first_name',
                status: 'default',
                error: null
            },
            {
                key: 'last_name',
                status: 'default',
                error: null
            },
            {
                key: 'birth',
                status: 'default',
                error: null
            },
        ]
    }
}

export const SignIn = {
    form: {
        error: null,
        inputs: [
            {
                key: 'username',
                status: 'default',
                error: null
            },
            {
                key: 'password',
                status: 'default',
                error: null
            }
        ]
    }
}