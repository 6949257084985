import types from './types';

const InitialState = {
    loaded: false,
    list: []
}

export default (state = InitialState, {type, payload}) => {
    switch(type) { 
        case types.toggleBonus:
            return {
                ...state,
                list: state.list.map(b => b.id === payload ? {...b, disabled: !b.disabled} : b)
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                list: payload.bonuses.map(bonus => {
                    return {
                        ...bonus,
                        disabled :false
                    }
                })
            }
        default:
            return state;
    }
} 