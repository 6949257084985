import types from './types';
import API from '../../utils/api';
import Cookies from 'react-cookies';
import Socket from '../../utils/websocket';
import Toastr from '../../utils/toastr';
import ModalsUtil from '../../utils/modals';
import Lang from '../../lang';

export const getUser = () => async dispatch => {
    const response = await API.call({
        path: '/user/get'
    });

    if(response.success) {
        if(response.data)
            window.authorizeBundle(response.data.userId);

        dispatch({
            type: types.loaded,
            payload: response
        });
    }
}

export const logout = navigate => dispatch => {
    Cookies.remove('session', {
        path: '/'
    });

    dispatch({
        type: types.logout
    });

    Socket.logout();

    // navigate('/');

    window.location.pathname = '/';
}

export const changeAvatar = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'changeAvatar'
    });

    const response = await API.call({
        path: '/user/avatar',
        body: data,
        file: true
    });

    if(!response.success)
        Toastr.error(Lang(response.error.text));
    else
        dispatch({
            type: types.avatar,
            payload: response.data
        });

    dispatch({
        type: types.toggleButton,
        payload: 'changeAvatar'
    });
}

export const activateVoucher = voucher => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: "activateVoucher"
    });

    const response = await API.call({
        path: "/user/vouchers/activate",
        body: {
            voucher
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: "activateVoucher"
    });

    if(!response.success) {
        ModalsUtil.error(Lang('error'), Lang(response.error.text, response.error.params));
    } else {
        ModalsUtil.done(Lang('notifications.system.titles.success'), Lang(response.message.text, response.message.params));
    }
}

export const getCashbackBonus = (bonus, currency) => async dispatch => {
    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    const response = await API.call({
        path: '/user/bonuses/cashback',
        body: {
            id: bonus.id
        }
    });

    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    if(response.success) {
        ModalsUtil.done(Lang('notifications.system.titles.success'), Lang(response.message, {...response.params, details: {...response.params.details, currency}}));
    } else {
        ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error, response.params));
    }
}


export const cancelBonus = (bonus, currency) => async dispatch => {
    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    const response = await API.call({
        path: '/user/bonuses/cancel',
        body: {
            id: bonus.id
        }
    });

    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    console.log(response);

    if(response.success) {
        ModalsUtil.done(Lang('notifications.system.titles.success'), Lang(response.message, {...response.params, details: {...response.params.details, currency}}));
    } else {
        ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error, response.params));
    }
}

export const removeBonus = (bonus, currency) => async dispatch => {
    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    const response = await API.call({
        path: '/user/bonuses/remove',
        body: {
            id: bonus.id
        }
    });

    dispatch({
        type: types.bonuses.toggle,
        payload: bonus.id
    });

    if(response.success) {
        ModalsUtil.done(Lang('notifications.system.titles.success'), Lang(response.message, {...response.params, details: {...response.params.details, currency}}));
    } else {
        ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error, response.params));
    }
}