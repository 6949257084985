import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.main.jackpots,
    props.user.currency,
    (jackpots, currency) => {
        jackpots = jackpots.filter(j => j.currency === currency.key);
        
        const map = {};
        for(const jackpot of jackpots)
            map[jackpot.slug] = jackpot.amount;

        return {
            jackpots: map,
            currency
        }
    }
)