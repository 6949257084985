import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/main/navigation';
import {setType} from '../../../reducers/main/actions';
import Icons from './icons';
import Lang from '../../../lang';

export default () => {
    const {isAuth, active_bonus, type} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <section class="addMenu">
            <div className="addMenu__fix">
                <ul class="addMenu__list">
                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link no-text ${type === 'home' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('home'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Home />
                                    {/* {Lang('pages.main.categorys.home')} */}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link no-text ${type === 'search' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('search'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Search />
                                    {/* {Lang('pages.main.categorys.search')} */}
                                </div>
                            </a>
                    </li>

                    {active_bonus && (
                        <li class="addMenu__item">
                            <a 
                                href="#" 
                                class={`addMenu__link ${type === 'wagering' ? 'active' : ''}`}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch(setType('wagering'));
                                }}
                                >
                                    <div className="addMenu__link__content">
                                        <Icons.Wagering />
                                        {Lang('pages.main.categorys.wagering')}
                                    </div>
                                </a>
                        </li>
                    )}

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'favorites' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('favorites'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Favorites />
                                    {Lang('pages.main.categorys.favorites')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'new' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('new'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.New />
                                    {Lang('pages.main.categorys.new')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'popular' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('popular'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Popular />
                                    {Lang('pages.main.categorys.popular')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'jackpots' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('jackpots'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Jackpots />
                                    {Lang('pages.main.categorys.jackpots')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'slots' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('slots'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Slots />
                                    {Lang('pages.main.categorys.slots')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'fish' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('fish'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Fish />
                                    {Lang('pages.main.categorys.fish')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'fastgames' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('fastgames'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Fastgames />
                                    {Lang('pages.main.categorys.fastgames')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'game_show' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('game_show'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Game_show />
                                    {Lang('pages.main.categorys.game_show')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'live' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('live'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Livedealers />
                                    {Lang('pages.main.categorys.live')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'roulette' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('roulette'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Roulette />
                                    {Lang('pages.main.categorys.roulette')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'instant' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('instant'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Instant />
                                    {Lang('pages.main.categorys.instant')}
                                </div>
                            </a>
                    </li>

                    <li class="addMenu__item">
                        <a 
                            href="#" 
                            class={`addMenu__link ${type === 'bonus_buy' ? 'active' : ''}`}
                            onClick={e => {
                                e.preventDefault();
                                dispatch(setType('bonus_buy'));
                            }}
                            >
                                <div className="addMenu__link__content">
                                    <Icons.Bonus_buy />
                                    {Lang('pages.main.categorys.bonus_buy')}
                                </div>
                            </a>
                    </li>
                </ul>
            </div>
        </section>
    )
}