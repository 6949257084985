import React, {useState, useEffect} from 'react';

export default ({time}) => {
    const [currentTime, setCurrentTime] = useState(null);
    
    const calculateTime = () => {
        let timestamp = Math.floor((time - Date.now()) / 1e3);

        if(timestamp < 0)
            return '00:00';

        const minutes = Math.floor(timestamp / 60),
              seconds = timestamp - (minutes * 60);

        return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    useEffect(() => {
        if(currentTime === null)
            setCurrentTime(calculateTime());

        const interval = setInterval(() => setCurrentTime(calculateTime()), 500);

        return () => clearInterval(interval);
    });


    return currentTime;
}