import {combineReducers} from 'redux';
import auth from './auth/reducer';
import user from './user/reducer';
import main from './main/reducer';
import game from './game/reducer';
import provider from './provider/reducer';
import deposit from './deposit/reducer';
import cashout from './cashout/reducer';
import profile from './profile/reducer';
import bonuses from './bonuses/reducer';

export default combineReducers({
    auth,
    user,
    main,
    game,
    provider,
    deposit,
    cashout,
    profile,
    bonuses
});