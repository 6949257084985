import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.auth.signup.stage,
    props.auth.signup.form,
    (stage, form) => {
        return {
            stage,
            form
        }
    }
)