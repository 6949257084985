import types from './types';

const InitialState = {
    payments: null
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.payments.remove:
            return {
                ...state,
                payments: state.payments ? state.payments.filter(payment => payment.uniqueId !== payload) : state.payments
            }
        case types.payments.new:
            return {
                ...state,
                payments: state.payments ? [
                    {
                        ...payload,
                        date: Date.now() + payload.date
                    },
                    ...state.payments
                ] : state.payments
            }
        case types.payments.update:
            return {
                ...state,
                payments: state.payments ? state.payments.map((payment) => {
                    return payment.uniqueId === payload.uniqueId ? {
                        ...payment,
                        ...payload
                    } : payment;
                }) : state.payments
            }
        case types.payments.loaded:
            return {
                ...state,
                payments: payload.map(payment => {
                    return {
                        ...payment,
                        date: Date.now() - payment.date
                    }
                }).sort((a,b) => {
                    if(a.date > b.date) return -1;
                    if(a.date < b.date) return 1;
                    return 0;
                })
            }
        default:
            return state;
    }
}