import {React, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/components/header';
import PresetSelector from '../../../selectors/preset';
import {toggleHeader} from '../../../reducers/main/actions';
import {Link} from 'react-router-dom';
import Toolbar from '../toolbar';
import HeaderUtil from '../../../utils/header';
import Language from '../../../lang';
import {useLocation} from 'react-router-dom';

import User from './user';

export default () => {
    const {header_active} = useSelector(Selector),
          {preset} = useSelector(PresetSelector),
          dispatch = useDispatch(),
          location = useLocation();

    useEffect(() => {
        dispatch(toggleHeader());
    }, [location]);

    return (
        <header class={`header ${header_active ? 'active' : ''}`}>
            <div class="header__wrapper wrapper">
                <div class={`header__btnPopup ${header_active ? 'active' : ''}`} onClick={e => {
                    dispatch(toggleHeader(!header_active))
                }}>
                    <div class="burger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <Link to={'/'} class="logo">
                    <img 
                        src={`${window.location.origin}/logotype`} 
                        class="mobileNone" 
                        style={{height: '50px'}} 
                        alt={preset.general.sitename} />

                    <img 
                            src={`${window.location.origin}/logotype`} 
                            class="desctopNone"
                            style={{width: '90px'}} 
                            alt={preset.general.sitename} />
                </Link>
                <div className="mobile-nav-fix"></div>
                <ul class="menu">
                    <li class="menu__item">
                        <Link to={'/'} class="menu__link">{Language('components.header.navigation.home')}</Link>
                    </li>
                    {/* <li class="menu__item">
                        <Link to={'/faq'} class="menu__link">{Language('components.header.navigation.faq')}</Link>
                    </li> */}
                    <li class="menu__item">
                        <Link to={'/promotions'} class="menu__link">{Language('components.header.navigation.bonuses')}</Link>
                    </li>
                    <li class="menu__item">
                        <a href={preset.links.support} target="_blank" class="menu__link">{Language('components.header.navigation.support')}</a>
                    </li>

                    <Toolbar />
                </ul>
                <User />
                <div class="header__bg" onClick={e => {
                    dispatch(toggleHeader(!header_active))
                }}></div>
            </div>
        </header>
    )
}