import Main from './main';
import Game from './game';
import Bonus from './bonus';
import NotFound from './notFound';
import Provider from './provider';
import Profile from './profile';
import TOS from './tos';
import FAQ from './faq';
import Promotions from './promotions'

export default {
    Main,
    Game,
    Bonus,
    NotFound,
    Provider,
    Profile,
    TOS,
    FAQ,
    Promotions
}