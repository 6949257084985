import {React, useLayoutEffect} from 'react';
import {useSelector} from 'react-redux';
import PresetSelector from '../../../selectors/preset';
import {Helmet} from 'react-helmet';
import LoaderUtil from '../../../utils/loader';

import Slider from '../../components/slider';
import Winners from '../../components/winners';
import Navigation from './navigation';
import Games from './games';
import Guarante from './guarante';
import Providers from './providers';
import Jackpots from './jackpots';

export default () => {
    useLayoutEffect(() => {
        LoaderUtil.hide(); 
    });

    const {preset, language} = useSelector(PresetSelector);

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].main)}</title>
            </Helmet>

            <Slider />
            <Winners />
            <Navigation />
            <Jackpots />
            <Games />
            <Guarante />
            <Providers />
        </>
    )
}