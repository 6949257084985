export default {
    error: null,
    inputs: [
        {
            key: 'amount',
            status: 'default',
            error: null
        },
        {
            key: 'details',
            status: 'default',
            error: null
        }
    ]
}