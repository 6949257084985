import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/profile/payments';
import {loadPayments} from '../../../../reducers/profile/actions';
import {showPendingDeposit} from '../../../../reducers/deposit/actions';
import PageLoader from '../../../components/loader/page';
import Lang from '../../../../lang';
import {currency_short} from '../../../../utils/types';
import PresetSelector from '../../../../selectors/preset';
import {Helmet} from 'react-helmet';

export default () => {
    const {payments, country, methods} = useSelector(Selector),
          {preset, language} = useSelector(PresetSelector),
          [countToShow, setCountToShow] = useState(10),
          dispatch = useDispatch();

    useEffect(() => {
        if(payments === null)
            dispatch(loadPayments());
    });

    if(payments === null)
        return <PageLoader style={{width: '100%'}} />; 

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', preset.titles[language].transactions)}</title>
            </Helmet>
            <div class="profileWr__content transactions">
                <p class="txt24x30 w700 cWh btnWrap">{Lang('pages.profile.transactions.title')}</p>
                <div class="mt24 table__wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{Lang('pages.profile.transactions.table.type.title')}</th>
                                <th>{Lang('pages.profile.transactions.table.paymentId')}</th>
                                <th>{Lang('pages.profile.transactions.table.method.title')}</th>
                                <th>{Lang('pages.profile.transactions.table.amount')}</th>
                                <th>{Lang('pages.profile.transactions.table.status.title')}</th>
                                <th>{Lang('pages.profile.transactions.table.date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.filter((p, key) => key < countToShow).map((payment, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            {payment.type === 'deposit' ? Lang('pages.profile.transactions.table.type.deposit') : Lang('pages.profile.transactions.table.type.cashout')}
                                        </td>
                                        <td>{payment.paymentId}</td>
                                        <td>{Lang(`pages.profile.transactions.table.method.${payment.method}`)}</td>
                                        {
                                            payment.type === 'deposit'
                                            ? <td class="cGreen">+{currency_short(payment.amount, country.currency, country.currency_symbol)}</td>
                                            : <td class="cRed">-{currency_short(payment.amount, country.currency, country.currency_symbol)}</td>    
                                        }
                                        {payment.status === 'wait' && (
                                            <td class="cGry">{Lang('pages.profile.transactions.table.status.wait')}</td>
                                        )}
                                        {payment.status === 'moderate' && (
                                            <td class="cGry">{Lang('pages.profile.transactions.table.status.moderate')}</td>
                                        )}
                                        {payment.status === 'pending' && (
                                            <td class="cGry">{Lang('pages.profile.transactions.table.status.pending')}</td>
                                        )}
                                        {payment.status === 'pending+' && (
                                            <td>
                                                <span 
                                                    class="row cYel"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => dispatch(showPendingDeposit())}>
                                                    <span class="mr4">{Lang('pages.profile.transactions.table.status.pending')}</span>
                                                    <span>
                                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.5 11L10.5 4M10.5 4H5.83333M10.5 4V8.66667" stroke="url(#paint0_linear_616_35265)" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_616_35265" x1="7.99091" y1="4" x2="7.99091" y2="11" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#FFAA04"></stop>
                                                                    <stop offset="1" stop-color="#FFBC04"></stop>
                                                                </linearGradient>
                                                            </defs>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </td>
                                        )}
                                        {(payment.status === 'done' || payment.status === "done_auto") && (
                                            <td>{Lang('pages.profile.transactions.table.status.done')}</td>
                                        )}
                                        {payment.status === 'canceled' && (
                                            <td class="cGry">{Lang('pages.profile.transactions.table.status.rejected')}</td>
                                        )}
                                        <td>{new Date(payment.date).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>


                {(payments.length > countToShow) && (
                    <button class="btn mt24 mla wA" style={{margin: '0 auto'}} onClick={e => setCountToShow(countToShow + 10)}>{Lang('pages.profile.transactions.show_more', {count: payments.length - countToShow})}</button>
                )}
            </div>
        </>
    )
}