import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.main.background,
    (background) => {
        return {
            background
        }
    }
)