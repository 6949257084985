export default () => (
<svg xmlns="http://www.w3.org/2000/svg" width="98" height="98" viewBox="0 0 98 98" fill="none">
<g clip-path="url(#clip0_132_27)">
<rect width="30" height="30" fill="#FFAA04"/>
<rect x="34" width="30" height="30" fill="#FFAA04"/>
<rect x="68" width="30" height="30" fill="#FFAA04"/>
<rect y="34" width="30" height="30" fill="#FFAA04"/>
<rect x="34" y="34" width="30" height="30" fill="#FFAA04"/>
<rect x="68" y="34" width="30" height="30" fill="#FFAA04"/>
<rect y="68" width="30" height="30" fill="#FFAA04"/>
<rect x="34" y="68" width="30" height="30" fill="#FFAA04"/>
<rect x="68" y="68" width="30" height="30" fill="#FFAA04"/>
</g>
<defs>
<clipPath id="clip0_132_27">
<rect width="98" height="98" fill="white"/>
</clipPath>
</defs>
</svg>
)