import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/language';
import PresetSelector from '../../../selectors/preset';
import {setLanguage} from '../../../reducers/main/actions';
import ModalsUtil from '../../../utils/modals';
import Lang from '../../../lang';
import preset from '../../../selectors/preset';

export default () => {
    const {country: defaultCountry, countrys} = useSelector(Selector),
          {preset} = useSelector(PresetSelector),
          [country, setCountry] = useState(defaultCountry),
          [countryActive, setCountryActive] = useState(false),
          dispatch = useDispatch();

    return (
        <div class="popup" id="language_modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            
            <form 
                class="popup__contain"
                onSubmit={e => {
                    e.preventDefault();
                    dispatch(setLanguage(country.code));
                    ModalsUtil.close();
                }}>
                <div class="popup__top">
                    <p class="popup__title">{Lang('modals.language.title')}</p>
                    <button 
                        class="popup__close"
                        onClick={e => {
                            e.preventDefault();
                            ModalsUtil.close()
                        }}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                    </button>
                </div>

                <label className={`inputBox ${countryActive ? 'inputBox--focused' : ''}`}>
                    {/* <p class="inputBox__title">{Lang('modals.language.language')}</p> */}

                    <div className="selector" onClick={e => {
                        setCountryActive(!countryActive);
                    }}>
                        <div className="active-option">
                            {country ? (
                                <>
                                    <img src={`${window.location.origin}/img/icons/flags/${country.icon}`} />
                                    <div className="country-title">{Lang(`modals.language.langs.${country.code}`)}</div>
                                    <div className={`arrow-down ${countryActive ? '' : 'active'}`}> 
                                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06215 0C0.275079 0 -0.203484 0.867179 0.216099 1.53309L3.40506 6.59424C3.79732 7.21678 4.70492 7.21678 5.09718 6.59424L8.28614 1.53309C8.70572 0.867177 8.22715 0 7.44008 0H1.06215Z"/>
                                        </svg>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <img src={`${window.location.origin}/img/icons/flags/raphael_question.svg`} />
                                    <div className="country-title">{Lang(`modals.language.select`)}</div>
                                    <div className={`arrow-down ${countryActive ? 'active' : ''}`}> 
                                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06215 0C0.275079 0 -0.203484 0.867179 0.216099 1.53309L3.40506 6.59424C3.79732 7.21678 4.70492 7.21678 5.09718 6.59424L8.28614 1.53309C8.70572 0.867177 8.22715 0 7.44008 0H1.06215Z"/>
                                        </svg>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={`dropdown ${countryActive ? 'active' : ''}`}>
                        <div className="dropdown-list">
                            {countrys.filter(c => c.code !== country).filter(c => preset ? preset.languages.includes(c.code) : false).map((contry, key) => {
                                return (
                                    <div className="option" key={key} onClick={e => {
                                        setCountry(contry);
                                        setCountryActive(false);
                                    }}>
                                        <img src={`${window.location.origin}/img/icons/flags/${contry.icon}`} />
                                        <div className="country-title">{Lang(`modals.language.langs.${contry.code}`)}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </label>

                <button 
                    class="btn"
                    style={{
                        cursor: 'pointer',
                        opacity: 1
                    }}>{Lang(`modals.language.button`)}</button>
            </form>
        </div>
    )
    
}