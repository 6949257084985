import React, {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/cashout/method';
import {skipMethod} from '../../../reducers/cashout/actions';
import ModalsUtil from '../../../utils/modals';
import Lang from '../../../lang';

export default ({methods}) => {
    const [method, setMethod] = useState(null),
          {error, button} = useSelector(Selector),
          dispatch = useDispatch();

    const modalCloseHandler = e => {
        if(e.detail === 'cashout_modal') {
            setMethod(null);
        }
    }

    useEffect(() => {
        document.addEventListener('modal.closed', modalCloseHandler);
        return () => document.removeEventListener('modal.closed', modalCloseHandler);
    });

    return (
        <form 
            class="popup__contain"
            onSubmit={e => {
                e.preventDefault();

                if(button || !method)
                    return;

                dispatch(skipMethod(method));
            }}>
            <div class="popup__top">
                <p class="popup__title">{Lang('modals.cashout.method.title')}</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>

            {error !== null && (
                <div class="inputBox--error" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{error}</p>
                    </div>
                </div>
            )}

            <p class="inputBox__title ">{Lang('modals.cashout.method.method')}</p>

            <div class="inputBox inputPays" style={{
                display: 'block',
                maxHeight: '35vh',
                overflow: 'auto'
            }}>
                {methods.map((_methods, _key) => {
                    return (
                        <>
                            {_methods.map((method,key) => {
                                return (
                                    <label class="inputPays__item" key={key}>
                                        <input 
                                            type="radio" 
                                            name="paysType" 
                                            value={method.key}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setMethod(method.key);
                                            }} />
                                        <div class="inputPays__block">
                                            <img 
                                                src={`${window.location.origin}/img/pays/${method.key}.png`} 
                                                alt={method.key} />
                                        </div>
                                    </label>
                                )
                            })}
                            <div style={{width: '100%', height: '20px', float: "left"}}></div>
                        </>
                    )
                })}
            </div>

            <button 
                class="btn" 
                type="submit"
                style={{
                    opacity: (button || !method) ? 0.7 : 1,
                    cursor: (button || !method) ? 'no-drop' : 'pointer'
                }}>{!button ? Lang('modals.cashout.method.next') : Lang('modals.cashout.method.wait')}</button>
        </form>
    )
}