import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/deposit/freekassa';
import {createFreeKassaPayment} from '../../../reducers/deposit/actions';
import ModalsUtil from '../../../utils/modals';
import Input from '../../modals/signin/input';
import Lang from '../../../lang';

export default ({method}) => {
    const {form, button} = useSelector(Selector),
          dispatch = useDispatch();

    const [data, setData] = useState({
        method: method.key,
        amount: ""
    });

    const [receive, setReceive] = useState(1000);

    useEffect(() => {
        let result = parseFloat((data.amount * (1 - (method.comissions.deposit / 1e2))).toFixed(2))
        setReceive(isNaN(result) || result === Infinity ? 0 : result);
    }, [data.amount]);

    return (
        <form class="popup__contain" onSubmit={e => {
            e.preventDefault();

            if(data.amount < 1 || button) return;

            if(button)
                return;

            dispatch(createFreeKassaPayment(data));
        }}>
            <div class="popup__top">
                <p class="popup__title">{Lang(`pages.profile.transactions.table.method.${method.key}`)}</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>
            <p class="inputAgree__text cGry mb24">{Lang(`modals.deposit.freekassa.text`)}</p>
            <p class="inputAgree__text cGry mb24">{Lang(`modals.deposit.freekassa.comission`)}: <b>{method.comissions.deposit}%</b></p>

            {form.error !== null && (
                <div class="inputBox--error" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{Lang(form.error.text, form.error.params)}</p>
                    </div>
                </div>
            )}

            <Input
                type="number"
                label={Lang('modals.deposit.freekassa.amount')}
                placeholder="100"
                name="amount"
                updateState={value => setData({...data, amount: parseInt(value)})}
                inputs={form.inputs}
                defaultValue={data.amount} />

            <label class="inputBox">
                <p class="inputBox__title">{Lang('modals.deposit.freekassa.receive')}</p>
                <input 
                    type="text" 
                    placeholder="0"
                    value={receive}
                    readOnly
                    disabled />
            </label>

            <button 
                class="btn"
                style={{
                    opacity: (!button && data.amount >= 1) ? 1 : 0.7,
                    cursor: (!button && data.amount >= 1) ? 'pointer' : 'no-drop'
                }}>{!button ? Lang(`modals.deposit.freekassa.button`) : Lang(`modals.deposit.freekassa.button_active`)}</button>
        </form>
    )
}