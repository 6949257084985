import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/components/toolbar/sounds';
import {setSoundsState} from '../../../reducers/main/actions';

export default () => {
    const {sounds} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="toolbar-select" onClick={e => dispatch(setSoundsState(!sounds))}>
            <img 
                src={`${window.location.origin}/img/icons/${sounds ? 'sound_on' : 'sound_off'}.svg`}
                alt={`${sounds ? 'Sounds On' : 'Sound Off'}`} />
        </div>
    )
}