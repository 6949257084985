import Lang from '../../../lang';
import ModalsUtil from '../../../utils/modals';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PresetSelector from '../../../selectors/preset';
import {currency_short as currency_format, getMaximal} from '../../../utils/types';

export default ({currency, active_bonus, isAuth}) => {
    const navigate = useNavigate(),
          {preset} = useSelector(PresetSelector),
          cashback_preset = preset.currency_presets[currency.key].cashback;

    if(!cashback_preset.active)
        return <></>;

    return (
        <li class="promotion__item">
            <div class="promotion__block">
                <div class="promotion__img">
                    <img 
                        src={`${window.location.origin}/bonuses/cashback.png`} 
                        alt={Lang('bonuses.preview.name.cashback')} />
                </div>

                <p class="promotion__title txt20x26 cWh">
                    {Lang('bonuses.preview.name.cashback')}
                </p>

                <div 
                    class="invite-details" 
                    style={{marginBottom: "10px"}}>
                        <p>{Lang('bonuses.preview.descriptions.cashback')}</p>
                </div>

                <div className="promotion__rules promotion__rules-freespins">
                    <span>{Lang('bonuses.names.deposit')}</span>
                    <div className="promotion__rules__value">{Lang('bonuses.names.deposits', {deposits: {from: cashback_preset.deposits.from, to: cashback_preset.deposits.to}, currency})}</div>
                </div>

                <div className="promotion__rules promotion__rules-bonus">
                    <span>{Lang('bonuses.names.bonus')}</span>
                    <div className="promotion__rules__value">{cashback_preset.default.percent}-{cashback_preset.happyhours.percent}%</div>
                </div>

                <div className="promotion__rules promotion__rules-bonus">
                    <span>{Lang('bonuses.names.freespins')}</span>
                    <div className="promotion__rules__value">{getMaximal(cashback_preset.freespins.minimal, Math.round(cashback_preset.deposits.from/cashback_preset.default.freespins))}-{getMaximal(cashback_preset.freespins.maximal, Math.round(cashback_preset.deposits.to/cashback_preset.happyhours.freespins))}</div>
                </div>


                {['limit', 'limit_usd'].includes(cashback_preset.wager_type) ? (
                    <>
                        {cashback_preset.wager_type === "limit_usd" ? (
                            <div className="promotion__rules promotion__rules-wager">
                                <span>{Lang('bonuses.names.redeem')}</span>
                                <div className="promotion__rules__value">{currency_format(cashback_preset.wager_value, currency.key, currency.symbol)}</div>
                            </div>
                        ) : (
                            <div className="promotion__rules promotion__rules-wager">
                                <span>{Lang('bonuses.names.redeem')}</span>
                                <div className="promotion__rules__value">X{cashback_preset.wager_value}</div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="promotion__rules promotion__rules-wager">
                        <span>{Lang('bonuses.names.wager')}</span>
                        <div className="promotion__rules__value">X{cashback_preset.wager_value}</div>
                    </div>
                )}

                {/* <div className="promotion__rules promotion__rules-wager">
                    <span>{Lang('bonuses.names.wager')}</span>
                    <div className="promotion__rules__value">X7</div>
                </div> */}

                <button 
                    class="btn"
                    onClick={e => {
                        if(!isAuth)
                            return ModalsUtil.toggle('#login_signin');

                        if(active_bonus)
                            return ModalsUtil.error(Lang('modals.deposit.bonus.title'), Lang('modals.deposit.bonus.text'))

                        ModalsUtil.toggle('#deposit_modal');
                    }}
                    style={{
                        color: "#14143d",
                    }}
                    >{Lang('pages.promotions.get')}</button>

                <button 
                    class="btn btn--outside"
                    onClick={e => navigate('/promotions/example')}
                    style={{marginTop: "10px"}}
                    >{Lang('pages.promotions.details')}</button>
            </div>
        </li>
    )
}