import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.user.buttons.activateVoucher,
    (button) => {
        return {
            button
        }
    }
)