import React, {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/deposit/method';
import {setBonusId, skipBonus} from '../../../reducers/deposit/actions';
import ModalsUtil from '../../../utils/modals';
import Lang from '../../../lang';
import $ from 'jquery';

export default ({methods}) => {
    const [bonusState, setBonusState] = useState(false);

    const [method, setMethod] = useState(null),
          {bonusId, bonus, error, button} = useSelector(Selector),
          dispatch = useDispatch();

    const outClickHandler = e => {
        const button = $('.select button'),
              list = $('.select__list');

        if($(button).is(e.target))
            return;

        if($(list).is(e.target) || $(list).has(e.target).length > 0)
            return;

        setBonusState(false);
    }

    const modalCloseHandler = e => {
        if(e.detail === 'deposit_modal') {
            setBonusState(false);
            setMethod(null);
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', outClickHandler);
        document.addEventListener('modal.closed', modalCloseHandler);
        return () => {
            document.removeEventListener('mouseup', outClickHandler);
            document.removeEventListener('modal.closed', modalCloseHandler);
        }
    });

    return (
        <form 
            class="popup__contain"
            style={{display: 'table'}}
            onSubmit={e => {
                e.preventDefault();

                if(button)
                    return;

                dispatch(skipBonus(bonusId, method || 'method'));
            }}>
            <div class="popup__top">
                <p class="popup__title">{Lang('modals.deposit.method.title')}</p>
                <button
                    type="button" 
                    class="popup__close"
                    onClick={e => ModalsUtil.close()}>
                        <img 
                            src={`${window.location.origin}/img/icons/close.svg`} 
                            alt="" />
                </button>
            </div>

            {error !== null && (
                <div class="inputBox--error" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{error}</p>
                    </div>
                </div>
            )}

            <p class="inputBox__title ">{Lang('modals.deposit.method.method')}</p>

            <div class="inputBox inputPays" style={{
                display: 'block',
                maxHeight: '35vh',
                overflow: 'auto'
            }}>
                {methods.map((_methods, _key) => {
                    return (
                        <>
                            {_methods.map((method,key) => {
                                return (
                                    <label class="inputPays__item" key={key}>
                                        <input 
                                            type="radio" 
                                            name="paysType" 
                                            value={method.key}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setMethod(method.key);
                                            }} />
                                        <div class="inputPays__block">
                                            <img 
                                                src={`${window.location.origin}/img/pays/${method.key}.png`} 
                                                alt={method.key} />
                                        </div>
                                    </label>
                                )
                            })}
                            <div style={{width: '100%', height: '20px', float: "left"}}></div>
                        </>
                    )
                })}
            </div>

            {/* <div class="inputBox inputBox--select inputBox--moreTitle">
                <div class="inputBox__mrTitle">
                    <p class="inputBox__title">Select your bonus</p>
                    <p 
                        class="right w500 txt14x18 cYel btnHover"
                        onClick={e => ModalsUtil.toggle('#bonus_info_modal')}>How it works?</p>
                </div>
                <div class={`select ${bonusState ? 'active' : ''}`}>
                    <button style={bonusState ? {border: '2px solid #ffb61d'} : {}} type="button" class="select__mainBtn" onClick={e => {
                        e.preventDefault();

                        setBonusState(!bonusState);
                    }}>
                        <input 
                            type="text" 
                            class="select__input" />
                        {bonus === null ? (
                            <div class="select__cnt">Select bonus</div>
                        ) : (
                            <>
                                <div class="bonusName__img">
                                    <img 
                                        src={`${window.location.origin}/img/components/bonusGame.jpg`} 
                                        alt="" />
                                </div>
                                <div class="bonusName__info">
                                    <p class="bonusName__name">Bonus name</p>
                                    <p class="bonusName__description">When depositing more than $20</p>
                                </div>
                            </>
                        )}
                        <div class="select__cntRight">
                            <img 
                                src={`${window.location.origin}/img/icons/chevron-down.svg`} 
                                alt="" /> 
                        </div>
                    </button>
                    <ul class="select__list">
                        <li class="select__item">
                            <button type="button" class="select__itemBtn bonusName" onClick={e => {
                                e.preventDefault();

                                dispatch(setBonusId(0));
                                setBonusState(false);
                            }}>
                                <div class="bonusName__img">
                                    <img 
                                        src={`${window.location.origin}/img/components/bonusGame.jpg`} 
                                        alt="" />
                                </div>
                                <div class="bonusName__info">
                                    <p class="bonusName__name">Bonus name</p>
                                    <p class="bonusName__description">When depositing more than $20</p>
                                </div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div> */}

            <button 
                class="btn" 
                type="submit"
                style={{
                    opacity: (button || !method) ? 0.7 : 1,
                    cursor: (button || !method) ? 'no-drop' : 'pointer',
                    marginTop: "15px"
                }}>{!button ? Lang('modals.deposit.method.next') : Lang('modals.deposit.method.wait')}</button>
        </form>
    )
}