import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.deposit.cashapp.payment,
    props.deposit.buttons.confirm_pending_order,
    props.deposit.buttons.cancel_pending_order,
    (payment, confirmButton, cancelButton) => {
        return {
            payment,
            confirmButton,
            cancelButton
        }
    }
)