import Numbro from 'numbro';
export const number = (float, format=false) => Numbro(float).format(format ? format : '0,0.00');
export const number_short = (float, format=false) => Numbro(float).format(format ? format : '0,0');

export const currency_short = (amount, currency, symbol) => {
    amount = parseFloat(amount.toFixed(2));

    if(currency === 'RUB') return `${amount}${symbol}`;
    return `${symbol}${amount}`; 
}

export const currency = (amount, currency, symbol, short=false, format=false) => {
    if(short && amount >= 1e3)
        amount = parseFloat((amount/1e3).toFixed(2)) + 'k';

    if(currency === "RUB") return `${short ? amount : number(amount, format)}${symbol}`;
    return `${symbol}${number(amount, format)}`;
}

export const getMaximal = (num1, num2) => {
    return num1 > num2 ? num1 : num2;
}

export const rate = (x1, x2) => {
    if(isNaN(x1) || !x1) return 0;
    if(isNaN(x2) || !x2) return 1;


    const result = (x1/x2).toFixed(2);
    return result;
}

export const phoneFormat = (country, phone) => {
    phone = phone.replace(/[^0-9]/gi, '');

    let pattern = 'No pattern [country]';

    if(country === 'RU') pattern = '+x (xxx) xxx xx-xx';
    if(country === 'US') pattern = '+x (xxx) xxx xx-xx';

    let string = '', charIndex = 0;
    for(let i = 0; i < pattern.length; i++)
        if(pattern[i] === 'x') {
            string += phone[charIndex] || '-';
            charIndex++;
        } else {
            string += pattern[i];
        }

    return string;
}  