import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/components/toolbar/background';
import {setBackgroundState} from '../../../reducers/main/actions';

export default () => {
    const {background} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="toolbar-select" onClick={e => dispatch(setBackgroundState(!background))}>
            <img 
                src={`${window.location.origin}/img/icons/${background ? 'bg_video' : 'bg_static'}.svg`}
                alt="Static Background" />
        </div>
    )
}