import React from 'react';

export default () => {
    
    return (
        <footer class="footer">
            <div class="wrapper footer__wrapper">
                <div class="footer__left">
                    <ul class="footer__navList">
                        {/* <li class="footer__navItem"><a href="#" class="footer__link txt14x18">About us</a></li>
                        <li class="footer__navItem"><a href="#" class="footer__link txt14x18">Сontact us</a></li>
                        <li class="footer__navItem"><a href="#" class="footer__link txt14x18">FAQ</a></li>
                        <li class="footer__navItem"><a href="#" class="footer__link txt14x18">Terms Of Use</a></li>
                        <li class="footer__navItem"><a href="#" class="footer__link txt14x18">Responsible Gambling Policy</a></li>
                        <li class="footer__navItem"><a href="#" class="footer__link txt14x18">Privacy Policy</a></li> */}
                    </ul>
                </div>
                <div class="footer__right">
                    <div class="footer__top">
                        <div class="footer__verf">
                            <img 
                                src={`${window.location.origin}/img/components/verf.png`} 
                                alt="" />
                        </div>
                        <div class="txt14x22">The information on the Website is provided by the Website operator AZIMUT C.O, registered at Scharlooweg 39, Willemstad, Curaçao. The activities of AZIMUT C.O are fully licensed and regulated by Antillephone N.V. (license No. 8048/JAZ2016-050 valid till 15 August 2027), Curaçao laws and regulations. The payment processing is managed by Unionstar Limited (registered under No. HE 356131 at Agias Fylaxeos &amp; Zinonos Rossidi 2, 1st Floor, 3082 Limassol, Cyprus), subsidiary of AZIMUT C.O</div>
                    </div>
                    <div class="footer__bottom">
                        <ul class="footer__partners">
                            <li class="footer__partner">
                                <img 
                                    src={`${window.location.origin}/img/footer/1.png`} 
                                    alt="" />
                            </li>
                            <li class="footer__partner">
                                <img 
                                    src={`${window.location.origin}/img/footer/2.png`} 
                                    alt="" />
                            </li>
                            <li class="footer__partner">
                                <img 
                                    src={`${window.location.origin}/img/footer/3.png`} 
                                    alt="" />
                            </li>
                            <li class="footer__partner">
                                <img 
                                    src={`${window.location.origin}/img/footer/4.png`} 
                                    alt="" />
                            </li>
                            <li class="footer__partner">
                                <img 
                                    src={`${window.location.origin}/img/footer/5.png`} 
                                    alt="" />
                            </li>
                        </ul>
                        <div>
                            <p class="txt14x22">Any person under 18 years old or under the legal age for gambling in a particular jurisdiction (“Eligible Age“) may not use the services of this Website under any circumstances.</p>
                            <p class="txt14x22 footer__с">© 2024 Azimut777</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}