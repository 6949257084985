import {React, useEffect} from 'react';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/bonus';
import {getBonus} from '../../../reducers/main/actions';
import Loader from '../../components/loader/page';
import LoaderUtil from '../../../utils/loader';
import Device from '../../../utils/device';

import NotFound from '../notFound';
import Content from './content';

export default () => {
    // Getting current gameId
    const {bonusId} = useParams(),
          location = useLocation(),
          navigate = useNavigate();

    // Getting reducer gameId
    const {bonus, sounds} = useSelector(Selector),
          dispatch = useDispatch(),
          loaded = bonus && bonus.id == bonusId;

    useEffect(() => {
        window.backgroundMusic.pause();

        if(Device.isMobile()) {
            const body = document.querySelector('body');

            if(!body.classList.contains('body-mobile-game'))
                body.classList.add('body-mobile-game');
        }

        return () => {
            if(sounds)
                window.backgroundMusic.play();

            if(Device.isMobile()) {
                const body = document.querySelector('body');
    
                if(body.classList.contains('body-mobile-game'))
                    body.classList.remove('body-mobile-game');
            }
        }
    }, [location]);

    useEffect(() => {
        if(!loaded)
            dispatch(getBonus(bonusId, navigate));
        else
            LoaderUtil.hide();
    }, [loaded]);

    if(!loaded)
        return <Loader />;

    return <Content bonus={bonus} />;
}