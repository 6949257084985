import {React} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Selector from '../../../selectors/components/winners';
import PresetSelector from '../../../selectors/preset';
import {toggleDemo, setModalGame} from '../../../reducers/game/actions';
import ModalsUtil from '../../../utils/modals';
import Jackpot from './jackpot';

export default () => {
    const {winners, countrys, isAuth, currency} = useSelector(Selector),
          {preset} = useSelector(PresetSelector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    return (
        <section class="loop" style={{overflow: 'hidden'}}>
            <div class="loop__wrapper">
                <ul class="loop__list">
                    {winners.filter((w, key) => key < 20).map((winner, key) => {
                        const country = countrys.filter(c => c.country_code === winner.user.country)[0] || false;

                        return (
                            <li 
                                class={`loop__item ${winner.type === 'jackpot' ? 'jackpot-type' : ''}`} 
                                key={key}
                                onClick={e => {
                                    dispatch(setModalGame(winner.game));
                                    ModalsUtil.toggle('#game_modal');
                                }}
                                style={{
                                    cursor: 'pointer'
                                }}>
                                <div class="loop__block">
                                    <div class="loop__img">
                                        <img 
                                            src={winner.game.image} 
                                            alt="" />

                                        {(country && preset.currency_presets[currency.key].winners.show_flag) && (
                                            <img
                                                src={`${window.location.origin}/img/icons/flags/${country.icon}`}
                                                alt="Austria" />
                                        )}
                                    </div>
                                    <div class="loop__text">
                                        <p class="txt12x15 loop__title">{winner.user.name}</p>
                                        <p class="txt12x15 cYel loop__sum">{country ? (country.currency === 'USD' ? `$${winner.amount}` : `${winner.amount}${country.currency_symbol}`) : `$${winner.amount}`}</p>
                                        <p class="txt12x15 loop__name">{winner.game.name}</p>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <Jackpot />
        </section>
    )
}