import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.main.sliders,
    props.user.isAuth,
    props.user.data,
    props.main.language,
    props.main.currency,
    (sliders, isAuth, user, language, currency) => {
        // Currency sorting
        sliders = sliders.filter(slide => slide.currency === currency.key);

        // Content sorting
        sliders = sliders.filter(slide => {
            const content = slide.content[language] || null;

            if(!content)
                return false;

            const main = content.main,
                  button = content.button;

            if(main && button)
                return true;

            return false;
        });

        // View mode sorting
        sliders = sliders.filter(slide => {
            if(slide.view_mode === 'all') return true;
            if(slide.view_mode === 'auth' && isAuth) return true;
            if(slide.view_mode === 'guests' && !isAuth) return true;
            return false;
        }); 

        sliders = sliders.map(slider => {
            return {
                ...slider,
                content: slider.content[language]
            }
        });

        return {
            sliders,
            isAuth
        }
    }
)