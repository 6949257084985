import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.main.preset,
    props.main.language,
    (preset, language) => {
        return {
            preset,
            language
        }
    }
)