import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Selector from '../../../selectors/modals/game';
import {toggleDemo} from '../../../reducers/game/actions';
import ModalsUtil from '../../../utils/modals';
import Provider from '../../../utils/provider';
import Lang from '../../../lang';
import GameWager from '../../components/game-wager';

export default () => {
    const {game, isAuth, active_bonus} = useSelector(Selector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    useEffect(() => {
        if(!game)
            ModalsUtil.close();
    }, [game]);

    return (
        <div class="popup" id="game_modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            {game && (
                <form class="popup__contain popupGame" onSubmit={e => e.preventDefault()}>
                    <div class="popup__top">
                        <button 
                            class="popup__close"
                            onClick={e => ModalsUtil.close()}>
                                <img 
                                    src={`${window.location.origin}/img/icons/close.svg`} 
                                    alt="" />
                        </button>
                    </div>
                    <div class="popupGame__img">
                        <img 
                            src={game.image} 
                            alt={game.name} />
                    </div>
                    <p class="popupGame__title">{game.name}</p>
                    <a href="#" class="popupGame__link" onClick={e => {
                        e.preventDefault();
                        navigate(`/provider/${game.title}`);
                        ModalsUtil.close();
                    }}>
                        {Provider.getName(game.title)}
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 11L10.5 4M10.5 4H5.83333M10.5 4V8.66667" stroke="url(#paint0_linear_574_32630)" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <defs>
                                <linearGradient id="paint0_linear_574_32630" x1="7.99091" y1="4" x2="7.99091" y2="11" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFAA04"></stop>
                                    <stop offset="1" stop-color="#FFBC04"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                    </a>

                    <GameWager game={game} active_bonus={active_bonus} modal={true} />

                    <button 
                        class="btn"
                        onClick={e => {
                            if(!isAuth)
                                return ModalsUtil.toggle("#login_signin");

                            if(active_bonus) {
                                if(active_bonus.details.games_restrictions.enabled && !active_bonus.details.games_restrictions.list.includes(game.gameId)) {
                                    return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                } else if(!active_bonus.details.games_restrictions.enabled && !game.is_wager) {
                                    return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                }
                            } 

                            if(active_bonus && active_bonus.freespins_session && active_bonus.freespins_session.left > 0)
                                return ModalsUtil.confirm({
                                    title: Lang('bonuses.game.freespins.title'),
                                    text: Lang('bonuses.game.freespins.text'),
                                    confirm: Lang('bonuses.game.freespins.confirm', {count: active_bonus.freespins_session.left}),
                                    cancel: Lang('bonuses.game.freespins.cancel'),
                                    onConfirm: () => {
                                        ModalsUtil.close();
                                        navigate(`/bonus/${active_bonus.id}`);
                                    },
                                    onCancel: () => {
                                        ModalsUtil.close();
                                        dispatch(toggleDemo(false));
                                        navigate(`/game/${game.id}`);
                                    }
                                });

                            if(active_bonus && active_bonus.wager.type === "wager" && game.title === "spadegaming")
                                return ModalsUtil.confirm({
                                    title: Lang('bonuses.game.fish_no_wager.title'),
                                    text: Lang('bonuses.game.fish_no_wager.text'),
                                    confirm: Lang('bonuses.game.fish_no_wager.confirm'),
                                    cancel: Lang('bonuses.game.fish_no_wager.cancel'),
                                    onConfirm: () => {
                                        ModalsUtil.close();
                                        dispatch(toggleDemo(false));
                                        navigate(`/game/${game.id}`);
                                    },
                                    onCancel: () => {
                                        ModalsUtil.close();
                                    }
                                });

                            dispatch(toggleDemo(false));
                            navigate(`/game/${game.id}`);
                            ModalsUtil.close();
                        }}>{Lang('modals.play.play')}</button>

                    {game.demo && (
                        <button 
                            class="btn btn--outside"
                            onClick={e => {
                                dispatch(toggleDemo(true));
                                navigate(`/game/${game.id}`);
                                ModalsUtil.close();
                            }}>{Lang('modals.play.demo')}</button>
                    )}
                </form>
            )}
        </div>
    )
}