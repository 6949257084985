import React from 'react';
import ModalsUtil from '../../../utils/modals';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/modals/done';
import Lang from '../../../lang';

export default () => {
    const {title, text} = useSelector(Selector);

    return (
        <div class="popup" id="done-modal" style={{display: 'none'}}>
            <div class="popup__bg"></div>
            <form class="popup__contain cashApp" onSubmit={e => {
                e.preventDefault();
            }}>
                <div class="resultDone">
                    <div class="resultDone__icon">
                        <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1667 17.4999C31.1667 25.324 24.8241 31.6666 17 31.6666C9.17601 31.6666 2.83337 25.324 2.83337 17.4999C2.83337 9.67588 9.17601 3.33325 17 3.33325C24.8241 3.33325 31.1667 9.67588 31.1667 17.4999ZM23.8688 14.9242C24.5327 14.2603 24.5327 13.184 23.8688 12.5201C23.2049 11.8562 22.1285 11.8562 21.4646 12.5201L15.1112 18.8735L12.5355 16.2978C11.8716 15.6339 10.7952 15.6339 10.1313 16.2978C9.4674 16.9617 9.4674 18.0381 10.1313 18.702L13.9091 22.4798C14.573 23.1437 15.6493 23.1437 16.3132 22.4798L23.8688 14.9242Z" fill="#17D359"></path>
                        </svg>
                    </div>
                    <p class="txt24x30 w600">{title}</p>
                    <p class="txt14x18 w400" dangerouslySetInnerHTML={{__html: text}}></p>
                </div>
                <button 
                    class="btn btn--transparent cGry mt24"
                    onClick={() => ModalsUtil.close()}>{Lang('modals.info.close')}</button>
            </form>
        </div>
    )
}