import Device from '../../../utils/device';
import GameImage from '../game-image';
import GameWager from '../game-wager';
import ModalsUtil from '../../../utils/modals';
import {useDispatch} from 'react-redux';
import {setModalGame, toggleDemo} from '../../../reducers/game/actions';
import Lang from '../../../lang';
import {useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import SliderContainer from './slider';
import Icons from '../../pages/main/icons';

export default ({
    icon: Icon,
    name,
    games,
    isSlider = false,
    defaultCountToShow = 10,
    countToShowNextStep = 10,
    active_bonus,
    isAuth,
    type
}) => {
    const dispatch = useDispatch(),
          navigate = useNavigate(),
          [countToShow, setCountToShow] = useState(defaultCountToShow),
          [isMobile, setMobileState] = useState(null),
          [itemsType, setItemsType] = useState('three');

    games = type === 'wagering' ? games.filter(game => {
        if(!active_bonus)
            return false;

        if(!active_bonus.details.games_restrictions.enabled)
            return game.is_wager;

        return active_bonus.details.games_restrictions.list.includes(game.gameId);
    }) : games;

    const checkMobileState = () => {
        if(document.body.offsetWidth < 993) {
            setMobileState(true);
        } else {
            setMobileState(false);
        }
    }

    const typeClick = () => {
        const arr = ['three', 'two', 'one'],
              curr_index = arr.findIndex(e => e === itemsType);

        setItemsType(arr[curr_index + 1] ? arr[curr_index + 1] : arr[0]);
    }
    

    useEffect(() => {
        if(isMobile === null)
            checkMobileState();

        window.addEventListener('resize', checkMobileState);
        return () => window.removeEventListener('resize', checkMobileState);
    }, [isMobile]);

    if(games.length < 1)
        return <></>;

    if(isSlider && !isMobile)
        return <SliderContainer icon={Icon} name={name} games={games} active_bonus={active_bonus} isAuth={isAuth} />

    return (
        <div class="listGames">
            <div class="listGames__top">
                <p class="listGames__title txt32x39">
                    <Icon />
                    {name}
                </p>
                {isMobile && (
                    <div class="listGames__btns" onClick={e => typeClick()}>
                        {itemsType === 'three' && <Icons.GamesThree />}
                        {itemsType === 'two' && <Icons.GamesTwo />}
                        {itemsType === 'one' && <Icons.GamesOne />}
                    </div>
                )}
            </div>
            <div class="listGames__bottom listGames__bottom--listGame">
                <div class={`listGames__slider slider-section-${itemsType}`}>
                    {games.filter((game, key) => key < countToShow).map((game, key) => {
                        return (
                            <div 
                                title={game.gameId}
                                class={`listGames__slide game-section-${itemsType}`} 
                                key={key}
                                onClick={e => {
                                    if(isMobile) {
                                        dispatch(setModalGame(game));
                                        ModalsUtil.toggle('#game_modal');
                                    }
                                }}>
                                <div class="listGames__link listGames__link--more">
                                    <GameImage name={game.name} image={game.image} />
                                    <GameWager game={game} active_bonus={active_bonus} />
                                        
                                    {!isMobile && (
                                        <div class="listGames__hovBtn">
                                            <button 
                                                class="btn"
                                                onClick={e => {
                                                    if(!isAuth)
                                                        return ModalsUtil.toggle('#login_signin');

                                                    if(active_bonus) {
                                                        if(active_bonus.details.games_restrictions.enabled && !active_bonus.details.games_restrictions.list.includes(game.gameId)) {
                                                            return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                        } else if(!active_bonus.details.games_restrictions.enabled && !game.is_wager) {
                                                            return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                        }
                                                    } 

                                                    if(active_bonus && active_bonus.freespins_session && active_bonus.freespins_session.left > 0)
                                                        return ModalsUtil.confirm({
                                                            title: Lang('bonuses.game.freespins.title'),
                                                            text: Lang('bonuses.game.freespins.text'),
                                                            confirm: Lang('bonuses.game.freespins.confirm', {count: active_bonus.freespins_session.left}),
                                                            cancel: Lang('bonuses.game.freespins.cancel'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                navigate(`/bonus/${active_bonus.id}`);
                                                            },
                                                            onCancel: () => {
                                                                ModalsUtil.close();
                                                                dispatch(toggleDemo(false));
                                                                navigate(`/game/${game.id}`);
                                                            }
                                                        });

                                                    if(active_bonus && active_bonus.wager.type === "wager" && game.title === "spadegaming")
                                                        return ModalsUtil.confirm({
                                                            title: Lang('bonuses.game.fish_no_wager.title'),
                                                            text: Lang('bonuses.game.fish_no_wager.text'),
                                                            confirm: Lang('bonuses.game.fish_no_wager.confirm'),
                                                            cancel: Lang('bonuses.game.fish_no_wager.cancel'),
                                                            onConfirm: () => {
                                                                ModalsUtil.close();
                                                                dispatch(toggleDemo(false));
                                                                navigate(`/game/${game.id}`);
                                                            },
                                                            onCancel: () => {
                                                                ModalsUtil.close();
                                                            }
                                                        });

                                                    dispatch(toggleDemo(false));
                                                    navigate(`/game/${game.id}`);
                                                }}>{Lang('modals.play.play')}</button>

                                            {game.demo && (
                                                <button 
                                                    class="btn btn--outside"
                                                    onClick={e => {
                                                        dispatch(toggleDemo(true));
                                                        navigate(`/game/${game.id}`);
                                                    }}>{Lang('modals.play.demo')}</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {(games.length > countToShow) && (
                <div class="listGames__btnMoreGames">
                    <button 
                        class="btn txt14x18"
                        onClick={e => {
                            setCountToShow(countToShow + countToShowNextStep)
                        }}>{Lang('pages.main.more', {
                            count: countToShow,
                            games: games.length
                        })}</button>
                </div>
            )}
        </div>
    )
}