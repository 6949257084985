import {React, createRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/game/content';
import PresetSelector from '../../../selectors/preset';
import {toggleFavorite, toggleDemo, getSession} from '../../../reducers/game/actions';
import ModalsUtil from '../../../utils/modals';
import {Link, useNavigate} from 'react-router-dom';
import Provider from '../../../utils/provider';
import LoaderPage from '../../components/loader/page';
import Device from '../../../utils/device';
import Winners from '../../components/winners';
import waitElement from '../../../utils/waitElement';
import Lang from '../../../lang';
import Providers from '../main/providers';
import {Helmet} from 'react-helmet';

const iframe = createRef(),
      demo = createRef();

export default () => {
    const {
        game,
        favoriteButton,
        isAuth,
        isDemo,
        session,
        active_bonus
    } = useSelector(Selector);

    const {preset, language} = useSelector(PresetSelector);

    const dispatch = useDispatch(),
          navigate = useNavigate();

    useEffect(() => {
        if(!session) {
            dispatch(getSession(game.id, game.demo ? (isAuth ? isDemo : true) : false));
        } else {
            if(session.iframe === 0)
                window.location.href = session.url;

            if(demo.current)
                demo.current.checked = isDemo;
            

            // if(iframe.current)
            //     iframe.current.contentWindow.addEventListener('popstate', href => { 
            //         // console.log(`POPSTATEEVENT`, href); 
            //         alert(`Hello world [Iframe]`);
            //     });

            window.onmessage = e => {
                const methods = ['closeGame', 'notifyCloseContainer', 'GAME_MODE:CLOSED', 'GAME_MODE:LOBBY'];

                let closing = false;
                for(const method of methods) {
                    if(!closing && JSON.stringify(e.data).indexOf(method) >= 0) {
                        closing = true;
                        navigate(-1);
                    }
                }
            }
        }
    });

    return (
        <>
            <Helmet>
                <title>{preset.titles[language].format.replace('{sitename}', preset.general.sitename).replace('{title}', game.name + ` ${isDemo ? '[DEMO]' : ''}`)}</title>
            </Helmet>
            <section class="game">
                <div class="game__wrapper wrapper">
                    {!Device.isMobile() && (
                        <>
                            <div class="game__top">
                                <h4 class="game__title">{game.name} - <Link to={`/provider/${game.title}`} class="cYel link"> {Provider.getName(game.title)}</Link></h4>
                                <div class="game__nav">
                                    {(game.demo) && (
                                        <label 
                                            class="inputToggle game__navItem"
                                            style={{
                                                opacity: session ? 1 : 0.7,
                                                cursor: session ? 'pointer' : 'no-drop'
                                            }}>
                                            <input 
                                                type="checkbox"
                                                ref={demo}
                                                defaultChecked={isDemo}
                                                onChange={e => {
                                                    if(!session)
                                                        e.target.checked = isDemo;

                                                    if(!isAuth)
                                                        if(!e.target.checked) {
                                                            e.target.checked = true;
                                                            ModalsUtil.toggle('#login_signin');
                                                            return;
                                                        } 

                                                    if(!e.target.checked) {
                                                        if(active_bonus) {
                                                            if(active_bonus.details.games_restrictions.enabled && !active_bonus.details.games_restrictions.list.includes(game.gameId)) {
                                                                return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                            } else if(!active_bonus.details.games_restrictions.enabled && !game.is_wager) {
                                                                return ModalsUtil.error(Lang('bonuses.game.bonus.title'), Lang('bonuses.game.bonus.text'));
                                                            }
                                                        }

                                                        if(active_bonus && active_bonus.freespins_session && active_bonus.freespins_session.left > 0)
                                                            return ModalsUtil.confirm({
                                                                title: Lang('bonuses.game.freespins.title'),
                                                                text: Lang('bonuses.game.freespins.text'),
                                                                confirm: Lang('bonuses.game.freespins.confirm', {count: active_bonus.freespins_session.left}),
                                                                cancel: Lang('bonuses.game.freespins.cancel'),
                                                                onConfirm: () => {
                                                                    ModalsUtil.close();
                                                                    e.target.checked = false;
                                                                    dispatch(toggleDemo(e.target.checked));
                                                                },
                                                                onCancel: () => {
                                                                    ModalsUtil.close();
                                                                    e.target.checked = true;
                                                                }
                                                            });

                                                        if(active_bonus && active_bonus.wager.type === "wager" && game.title === "spadegaming")
                                                            return ModalsUtil.confirm({
                                                                title: Lang('bonuses.game.fish_no_wager.title'),
                                                                text: Lang('bonuses.game.fish_no_wager.text'),
                                                                confirm: Lang('bonuses.game.fish_no_wager.confirm'),
                                                                cancel: Lang('bonuses.game.fish_no_wager.cancel'),
                                                                onConfirm: () => {
                                                                    ModalsUtil.close();
                                                                    e.target.checked = false;
                                                                    dispatch(toggleDemo(e.target.checked));
                                                                },
                                                                onCancel: () => {
                                                                    ModalsUtil.close();
                                                                    e.target.checked = true;
                                                                }
                                                            });
                                                    }

                                                    dispatch(toggleDemo(e.target.checked));
                                                }} />
                                            <div class="inputToggle__checkBox"></div>
                                            <p class="txt14x18 cWh ml8">{Lang('pages.game.demo')}</p>
                                        </label>
                                    )}
                                    {isAuth && (
                                        <label 
                                            class={`game__favorite favorite game__navItem ${game.favorite ? 'favorite--active' : ''}`}
                                            style={{
                                                opacity: favoriteButton ? 0.7 : 1,
                                                cursor: favoriteButton ? 'no-drop' : 'pointer'
                                            }}
                                            onClick={e => {
                                                e.preventDefault();

                                                if(favoriteButton)
                                                    return;

                                                dispatch(toggleFavorite(game.id));
                                            }}>
                                            <div class="favorite__icon">
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.4392 9.74C13.2449 9.92825 13.1557 10.2005 13.1999 10.4675L13.8667 14.1575C13.9229 14.4702 13.7909 14.7868 13.5292 14.9675C13.2727 15.155 12.9314 15.1775 12.6517 15.0275L9.32991 13.295C9.21441 13.2335 9.08616 13.2005 8.95491 13.1968H8.75166C8.68116 13.2073 8.61216 13.2297 8.54916 13.2642L5.22666 15.005C5.06241 15.0875 4.87641 15.1168 4.69416 15.0875C4.25016 15.0035 3.95391 14.5805 4.02666 14.1343L4.69416 10.4442C4.73841 10.175 4.64916 9.90125 4.45491 9.71L1.74666 7.085C1.52016 6.86525 1.44141 6.53525 1.54491 6.2375C1.64541 5.9405 1.90191 5.72375 2.21166 5.675L5.93916 5.13425C6.22266 5.105 6.47166 4.9325 6.59916 4.6775L8.24166 1.31C8.28066 1.235 8.33091 1.166 8.39166 1.1075L8.45916 1.055C8.49441 1.016 8.53491 0.98375 8.57991 0.9575L8.66166 0.9275L8.78916 0.875H9.10491C9.38691 0.90425 9.63516 1.073 9.76491 1.325L11.4292 4.6775C11.5492 4.92275 11.7824 5.093 12.0517 5.13425L15.7792 5.675C16.0942 5.72 16.3574 5.9375 16.4617 6.2375C16.5599 6.53825 16.4752 6.86825 16.2442 7.085L13.4392 9.74Z" stroke="white" stroke-width="1.6"></path>
                                                </svg>
                                            </div>
                                            <p class="txt14x18 cWh ml8">{Lang('pages.game.favorite')}</p>
                                        </label>
                                    )}
                                    <div class="game__navBtns navBtns game__navItem">
                                        <button 
                                            class="navBtn"
                                            onClick={e => {
                                                if(!session)
                                                    return;

                                                if(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled) {
                                                    const win = iframe.current;

                                                    if(win)
                                                        if(win.requestFullscreen) win.requestFullscreen();
                                                        else if(win.webkitRequestFullscreen) win.webkitRequestFullscreen();
                                                        else if(win.mozRequestFullScreen) win.mozRequestFullScreen();
                                                        else if(win.msRequestFullscreen) win.msRequestFullscreen();
                                                }
                                            }}
                                            style={{
                                                opacity: session ? 1 : 0.7,
                                                cursor: session ? 'pointer' : 'no-drop'
                                            }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5005 5.23272C16.5005 5.64698 16.1648 5.98281 15.7506 5.98281C15.3365 5.98281 15.0007 5.64698 15.0007 5.23272L15.0007 4.03901L11.7342 7.26016C11.4392 7.55099 10.9644 7.54762 10.6737 7.25261C10.3829 6.95761 10.3863 6.48269 10.6812 6.19186L13.9218 2.99628L12.7648 2.99627C12.3506 2.99627 12.0149 2.66044 12.0149 2.24618C12.0149 1.83191 12.3506 1.49609 12.7648 1.49609L15.7506 1.49611C16.1648 1.49611 16.5005 1.83194 16.5005 2.2462L16.5005 5.23272ZM1.5 5.23337C1.5 5.64754 1.83582 5.98329 2.25008 5.98329C2.66433 5.98329 3.00015 5.64754 3.00015 5.23337L3.00016 4.05797L6.19946 7.25661C6.49238 7.54947 6.96731 7.54947 7.26023 7.25661C7.55315 6.96374 7.55315 6.48892 7.26023 6.19606L4.06092 2.99742L5.23659 2.99741C5.65084 2.99741 5.98666 2.66165 5.98666 2.24748C5.98666 1.83331 5.65083 1.49756 5.23658 1.49756L2.25007 1.49758C1.83582 1.49758 1.5 1.83334 1.5 2.2475L1.5 5.23337ZM15.7504 12.0131C16.1645 12.0131 16.5003 12.3489 16.5003 12.7632L16.5003 15.7497C16.5003 16.1639 16.1645 16.4998 15.7504 16.4998L12.7645 16.4998C12.3504 16.4998 12.0146 16.164 12.0146 15.7497C12.0146 15.3354 12.3504 14.9996 12.7645 14.9996L13.9399 14.9996L10.6773 11.7362C10.3844 11.4433 10.3844 10.9684 10.6773 10.6754C10.9701 10.3825 11.445 10.3825 11.7378 10.6754L15.0005 13.9388L15.0005 12.7632C15.0005 12.3489 15.3362 12.0131 15.7504 12.0131ZM1.50025 12.7625C1.50025 12.3483 1.83607 12.0126 2.25032 12.0126C2.66458 12.0126 3.0004 12.3483 3.0004 12.7625L3.0004 13.9195L6.19585 10.679C6.48668 10.3841 6.96159 10.3807 7.25659 10.6715C7.55159 10.9622 7.55496 11.4371 7.26413 11.732L4.04311 14.9985L5.23683 14.9985C5.65109 14.9985 5.98691 15.3342 5.98691 15.7484C5.9869 16.1626 5.65108 16.4983 5.23682 16.4983L2.25032 16.4983C1.83607 16.4983 1.50025 16.1625 1.50025 15.7484L1.50025 12.7625Z" fill="inherit"></path>
                                            </svg>
                                        </button>
                                        <button
                                            style={{
                                                cursor: 'pointer'
                                            }} 
                                            class="navBtn"
                                            onClick={e => {
                                                e.preventDefault();
                                                navigate(-1);
                                            }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L1 15M1 1L15 15" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div class="game__game game__game--img">
                        {!session ? <LoaderPage /> : (
                            <iframe
                                width="100%"
                                height="100%"
                                src={session.url}
                                ref={iframe}></iframe>  
                        )}
                    </div>
                </div>
            </section>
            {!Device.isMobile() && <Winners />}
            <Providers />
        </>
    )
}