import types from './types';

const InitialState = {
    loaded: false,
    data: null,
    buttons: {
        changeAvatar: false,
        activateVoucher: false
    },
    bonuses: [],
    referral: {
        count: 0,
        amount: 0
    },
    currency: null,
    country: null,
    stats: {
        deposits: 0,
        cashouts: 0
    },
    restrictions: {
        wager: null,
        bonus_session: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.stats:
            return {
                ...state,
                stats: payload
            }
        case types.bonuses.toggle: 
            return {
                ...state,
                bonuses: state.bonuses.map(bonus => bonus.id === payload ? {...bonus, disabled: !bonus.disabled} : bonus)
            }
        case types.bonuses.new:
            return {
                ...state,
                bonuses: [
                    payload,
                    ...state.bonuses
                ]
            }
        case types.bonuses.update:
            return {
                ...state,
                bonuses: state.bonuses.map(bonus => bonus.id === payload.id ? payload : bonus)
            }
        case types.bonuses.remove:
            return {
                ...state,
                bonuses: state.bonuses.filter(bonus => bonus.id !== payload)
            }
        case types.restrictions.wager:
            return {
                ...state,
                restrictions: {
                    ...state.restrictions,
                    wager: payload
                }
            }
        case types.stats:
            return {
                ...state,
                data: {
                    ...state.data,
                    stats: payload
                }
            }
        case types.avatar:
            return {
                ...state,
                data: {
                    ...state.data,
                    avatar: payload.avatar
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.balance:
            return {
                ...state,
                data: {
                    ...state.data,
                    balance: payload
                }
            }
        case types.logout:
            return {
                ...state,
                loaded: true,
                data: null
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                data: payload.data,
                bonuses: payload.bonuses.map(bonus => {
                    return {...bonus, disabled: false}
                }),
                restrictions: payload.restrictions,
                stats: payload.stats,
                referral: payload.referral,
                country: payload.country.country,
                currency: payload.country.currency
            }
        default:
            return state;
    }
}