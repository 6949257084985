import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.cashout.error,
    props.cashout.buttons.next,
    (error, button) => {
        return {
            error,
            button
        }
    }
)