import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/form/signupPerson';
import {setSignUpStage, createAccount} from '../../../reducers/auth/actions';
import ModalsUtil from '../../../utils/modals';
import Input from './input';
import Lang from '../../../lang';

export default ({
    data,
    setData,
    formData
}) => {
    const {token, button} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <form 
            class="popup__contain"
            onSubmit={e => {
                e.preventDefault();

                if(button)
                    return;

                dispatch(createAccount(data, token))
            }}>
            <div class="popup__top">
                <p class="popup__title">{Lang('modals.register.title')}</p>
                <button 
                    type="button"
                    class="popup__close"
                    onClick={e => {
                        e.preventDefault();
                        ModalsUtil.close()
                    }}>
                    <img 
                        src={`${window.location.origin}/img/icons/close.svg`} 
                        alt="" />
                </button>
            </div>
            <div class="popup__steps steps">
                <div class="steps__item">
                    <div class="steps__circle">1</div>
                </div>
                <div class="steps__item active">
                    <div class="steps__circle">2</div>
                    <p class="steps__name">
                        {Lang('modals.register.steps.second')}
                    </p>
                </div>
            </div>

            {formData.error && (
                <div class="inputBox--error mb24 mt-24" style={{marginTop: '-10px', marginBottom: '20px'}}>
                    <div class="inputBox__warning">
                        <div class="inputBox__warningIcon">
                            <img 
                                src={`${window.location.origin}/img/icons/x-circle.svg`}
                                class="error" 
                                alt="" />
                        </div>
                        <p class="txt14x18 error valid">{Lang(formData.error.text, formData.error.params)}</p>
                    </div>
                </div>
            )}

            <Input
                label={Lang('modals.register.first_name')}
                placeholder={Lang('modals.register.first_name_placeholder')}
                name="first_name"
                updateState={state => setData({...data, first_name: state})}
                inputs={formData.inputs}
                defaultValue={data.first_name} />

            <Input
                label={Lang('modals.register.last_name')}
                placeholder={Lang('modals.register.last_name_placeholder')}
                name="last_name"
                updateState={state => setData({...data, last_name: state})}
                inputs={formData.inputs}
                defaultValue={data.last_name} />

            <div class="inputRadio">
                <label class="inputRadio__item">
                    <input 
                        type="radio" 
                        name="sex" 
                        value="male"
                        defaultChecked={data.sex === 1}
                        onChange={e => setData({
                            ...data,
                            sex: e.target.checked ? 1 : 0
                        })} />
                        
                    <div class="inputRadio__checkBox"></div>
                    <div class="inputRadio__text">{Lang('modals.register.male')}</div>
                </label>
                <label class="inputRadio__item">
                    <input 
                        type="radio" 
                        name="sex" 
                        value="female"
                        defaultChecked={data.sex === 0}
                        onChange={e => setData({
                            ...data,
                            sex: e.target.checked ? 0 : 1
                        })} />

                    <div class="inputRadio__checkBox"></div>
                    <div class="inputRadio__text">{Lang('modals.register.female')}</div>
                </label>
            </div>

            <Input
                label={Lang('modals.register.dob')}
                name="birth"
                placeholder={'dd/mm/yyyy'}
                updateState={state => setData({...data, birth: state})}
                inputs={formData.inputs}
                defaultValue={data.birth} />

            <label class="agree inputAgree">
                <input 
                    type="checkbox"
                    defaultChecked={data.mailing}
                    onChange={e => setData({
                        ...data,
                        mailing: e.target.checked
                    })} />
                <div class="inputAgree__checkBox">
                    <img 
                        src={`${window.location.origin}/img/icons/check.svg`} 
                        alt="" />
                </div>
                <div class="inputAgree__text">{Lang('modals.register.sms')}</div>
            </label>
            <label class="agree inputAgree">
                <input 
                    type="checkbox"
                    defaultChecked={data.checked}
                    onChange={e => setData({
                        ...data,
                        confirm: e.target.checked
                    })} />
                <div class="inputAgree__checkBox">
                    <img 
                        src={`${window.location.origin}/img/icons/check.svg`} 
                        alt="" />
                </div>
                <div class="inputAgree__text" dangerouslySetInnerHTML={{__html: Lang('modals.register.agree')}}></div>
            </label>
            <button
                type="submit"
                style={{
                    opacity: button ? 0.7 : 1,
                    cursor: button ? 'no-drop' : 'pointer'
                }} 
                class="btn">{!button ? Lang('modals.register.buttons.register') : Lang('modals.register.buttons.register_active')}</button>
            <button 
                class="btn btn--transparent"
                onClick={e => {
                    e.preventDefault();
                    dispatch(setSignUpStage(1));
                }}>{Lang('modals.register.buttons.back')}</button>
        </form>
    )
}