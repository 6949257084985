export default ({
    type: ({main}) => main.type,
    show: ({main}) => main.show,
    preset: ({main}) => main.preset,
    sliders: ({main}) => main.sliders,
    loaded: ({main}) => main.games !== null && main.winners !== null,
    games: ({main}) => main.games,
    bonus: ({main}) => main.bonus,
    search: ({main}) => main.search,
    winners: ({main}) => main.winners,
    jackpots: ({main}) => main.jackpots,
    countrys: ({main}) => main.countrys,
    language: ({main}) => main.language,
    currency: ({main}) => main.currency,
    sounds: ({main}) => main.sounds,
    background: ({main}) => main.background,
    header: ({main}) => main.header,
    modals: {
        error: {
            title: ({main}) => main.modals.error.title,
            text: ({main}) => main.modals.error.text
        },
        done: {
            title: ({main}) => main.modals.done.title,
            text: ({main}) => main.modals.done.text
        },
        info: {
            title: ({main}) => main.modals.info.title,
            text: ({main}) => main.modals.info.text
        },
        confirm: {
            title: ({main}) => main.modals.confirm.title,
            text: ({main}) => main.modals.confirm.text,
            confirm: ({main}) => main.modals.confirm.confirm,
            onConfirm: ({main}) => main.modals.confirm.onConfirm,
            cancel: ({main}) => main.modals.confirm.cancel,
            onCancel: ({main}) => main.modals.confirm.onCancel
        }
    }
})