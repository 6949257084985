export default {
    window: ({cashout}) => cashout.window,
    error: ({cashout}) => cashout.error,
    forms: {
        cashapp: ({cashout}) => cashout.forms.cashapp,
        bitcoin: ({cashout}) => cashout.forms.bitcoin,
        freekassa: ({cashout}) => cashout.forms.freekassa
    },
    buttons: {
        next: ({cashout}) => cashout.buttons.next,
        cashapp: ({cashout}) => cashout.buttons.cashapp,
        freekassa: ({cashout}) => cashout.buttons.freekassa
    }
}