import React from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/provider/games';
import Provider from '../../../utils/provider';
import GamesContainer from '../../components/games-container';

export default () => {
    const {
        provider,
        isAuth,
        games,
        active_bonus,
    } = useSelector(Selector);
    return (
        <section class="blockListGames" style={{
            paddingTop: '50px'
        }}>
            <div class="wrapper">
                <GamesContainer name={Provider.getName(provider)} games={games} icon={() => <></>} defaultCountToShow={25} countToShowNextStep={25} active_bonus={active_bonus} isAuth={isAuth} />
            </div>
        </section>
    )
}