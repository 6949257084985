import React, {Component} from 'react';
import CountUp from 'react-countup';

class Count extends Component {
    constructor({currency, multiply}) {
        super();

        this.currency = currency;
        this.multiply = multiply || false;

        this.state = {
            from: 0,
            to: 0
        }
    } 

    render() {
        let {from, to} = this.state;

        return (
            <CountUp
                start={from}
                end={to}
                duration={1.5}
                decimals={2}
                separator={','}>
                    {({countUpRef}) => (
                        <p class="maintext">
                            <span ref={countUpRef}></span><span style={{paddingLeft: "5px"}}>{this.currency.key}</span>
                        </p>
                    )}
            </CountUp>
        )
    }

    shouldComponentUpdate(prevProps) {
        return this.state.to !== this.props.value;
        // return prevProps.value !== this.props.value;
    }

    componentDidUpdate(prevProps) {
        const prev_value = prevProps.value,
              curr_value = this.props.value;

        if(prev_value !== curr_value)
            this.setState({
                from: this.multiply ?  prev_value * this.currency.value : prev_value,
                to: this.multiply ? curr_value * this.currency.value : curr_value
            });
    }

    componentDidMount() {
        this.setState({
            from: 0,
            to: this.multiply ? this.props.value * this.currency.value : this.props.value
        })
    }
}

export default Count;