import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.data,
    props.user.referral,
    props.user.currency,
    (data, stats, currency) => {
        return {
            code: data.referal_code,
            stats,
            currency
        }
    }
)