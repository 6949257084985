import types from './types';
import {SignIn, SignUp} from './states';

const InitialState = {
    signup: SignUp,
    signin: SignIn,

    buttons: {
        approveAccountInformation: false,
        signup: false,
        signin: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.signin.form:
            return {
                ...state,
                signin: {
                    ...state.signin,
                    form: {
                        ...state.signin.form,
                        error: payload.error ? payload.error : state.signin.form.error,
                        inputs: state.signin.form.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;
    
                            if(update)
                                return {
                                    ...input,
                                    ...update
                                }
                        
                            return input
                        })
                    }
                }
            }
        case types.signin.formReset:
            return {
                ...state,
                signin: {
                    ...state.signin,
                    form: SignIn.form
                }
            }
        case types.signup.token:
            return {
                ...state,
                signup: {
                    ...state.signup,
                    token: payload
                }
            }
        case types.signup.stage:
            return {
                ...state,
                signup: {
                    ...state.signup,
                    stage: payload
                }
            }
        case types.signup.form:
            return {
                ...state,
                signup: {
                    ...state.signup,
                    form: {
                        ...state.signup.form,
                        error: payload.error ? payload.error : state.signup.form.error,
                        inputs: state.signup.form.inputs.map(input => {
                            const update = payload.inputs ? payload.inputs.filter(_input => _input.key === input.key)[0] || false : false;

                            if(update)
                                return {
                                    ...input,
                                    ...update
                                }
                        
                            return input
                        })
                    }
                }
            }
        case types.signup.formReset:
            return {
                ...state,
                signup: {
                    ...state.signup,
                    form: SignUp.form
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}