import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.main.countrys,
    props.main.language,
    (countrys, language) => {
        return {
            countrys,
            country: countrys ? countrys.filter(c => c.code === language)[0] || null : null
        }
    }
)