import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.provider.provider,
    (provider) => {
        return {
            provider
        }
    }
)