import React, {useEffect} from 'react';
import Loader from '../../components/loader';
import {useNavigate} from 'react-router-dom';

export default () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.postMessage('Hello world');
        navigate('/');
    });
    
    return <Loader />;
}