import types from './types';
import API from '../../utils/api';
import Cookies from 'react-cookies';
import ModalsUtil from '../../utils/modals';
import Lang from '../../lang';

export const getPreset = () => async dispatch => {
    const response = await API.call({
        path: '/preset'
    });

    if(response.success)
        dispatch({
            type: types.preset,
            payload: response
        });
}

export const setLanguage = ln => {
    Cookies.save('language', ln, {
        secure: true,
        maxAge: 365 * 24 * 60 * 60 * 1e3,
        path: '/'
    });

    return {
        type: types.language,
        payload: ln
    }
}

export const setSoundsState = state => {
    Cookies.save('sounds', state ? 1 : 0, {
        secure: true,
        maxAge: 365 * 24 * 60 * 60 * 1e3,
        path: '/'
    });

    if(state)
        window.backgroundMusic.play();
    else
        window.backgroundMusic.pause();

    return {
        type: types.sounds,
        payload: state
    }
}

export const setBackgroundState = state => {
    Cookies.save('background', state ? 1 : 0, {
        secure: true,
        maxAge: 365 * 24 * 60 * 60 * 1e3,
        path: '/'
    });

    return {
        type: types.background,
        payload: state
    }
}

export const loadCountrys = () => async dispatch => {
    const response = await API.call({
        path: '/countrys'
    });

    if(response.success)
        dispatch({
            type: types.countrys,
            payload: response
        });
}

export const loadMain = () => async dispatch => {
    const response = await API.call({
        path: '/games/getMain'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.data
        });
}

export const setType = type => {
    return {
        type: types.type,
        payload: type
    }
}

export const showMore = () => {
    return {
        type: types.show
    }
}

export const setSearchQuery = query => {
    return {
        type: types.search,
        payload: query
    }
}

export const getBonus = (bonusId, navigate) => async dispatch => {
    const response = await API.call({
        path: '/games/getBonusSession',
        body: {
            bonusId
        }
    });
    
    if(!response.success) {
        navigate('/');

        if(!response.failed)
            ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error.text, response.error.params));
        else
            ModalsUtil.error(Lang('notifications.system.titles.error'), Lang(response.error));
    } else {
        dispatch({
            type: types.bonus,
            payload: response.bonus
        });
    }
}

export const toggleHeader = (state) => {
    return {
        type: types.header,
        payload: state
    }
}