export default () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_255_16)">
<path d="M0 10.4999C0 11.3296 0.670312 11.9999 1.5 11.9999C2.32969 11.9999 3 11.3296 3 10.4999C3 8.01556 5.01562 5.99993 7.5 5.99993H15V7.49994C15 8.10462 15.3656 8.65306 15.9281 8.88744C16.4906 9.12181 17.1328 8.99056 17.5641 8.564L20.5641 5.564C21.15 4.97806 21.15 4.0265 20.5641 3.44056L17.5641 0.44056C17.1328 0.00930987 16.4906 -0.117253 15.9281 0.117122C15.3656 0.351497 15 0.895247 15 1.49993V2.99993H7.5C3.35625 2.99993 0 6.35619 0 10.4999ZM24 13.4999C24 12.6702 23.3297 11.9999 22.5 11.9999C21.6703 11.9999 21 12.6702 21 13.4999C21 15.9843 18.9844 17.9999 16.5 17.9999H9V16.4999C9 15.8952 8.63437 15.3468 8.07187 15.1124C7.50937 14.8781 6.86719 15.0093 6.43594 15.4359L3.43594 18.4359C2.85 19.0218 2.85 19.9734 3.43594 20.5593L6.43594 23.5593C6.86719 23.9906 7.50937 24.1171 8.07187 23.8827C8.63437 23.6484 9 23.1046 9 22.4952V20.9999H16.5C20.6438 20.9999 24 17.6437 24 13.4999Z"/>
</g>
<defs>
<clipPath id="clip0_255_16">
<rect width="24" height="24"/>
</clipPath>
</defs>
</svg>   
)