import types from './types';
import gameTypes from '../game/types';
import userTypes from '../user/types';
import Cookies from 'react-cookies';

const InitialState = {
    type: 'home',
    show: 50,
    games: null,
    bonus: null,
    search: '',
    winners: [],
    countrys: null,
    currency: null,
    preset: null,
    sliders: [],
    language: Cookies.load('language') || null,
    sounds: (Cookies.load('sounds') || 0) == 1 ? true : false,
    background: (Cookies.load('background') || 0) == 1 ? true : false,
    header: false,
    jackpots: [],
    modals: {
        error: {
            title: "",
            text: ""
        },
        done: {
            title: "",
            text: ""
        },
        info: {
            title: "",
            text: ""
        },
        confirm: {
            title: "",
            text: "",
            confirm: "Confirm",
            cancel: "Cancel",
            onConfirm: null,
            onCancel: null
        }
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.preset:
            return {
                ...state,
                preset: payload.preset,
                sliders: payload.sliders
            }
        case types.header:
            return {
                ...state,
                header: payload
            }
        case types.bonus:
            return {
                ...state,
                bonus: payload
            }
        case types.sounds:
            return {
                ...state,
                sounds: payload
            }
        case types.background:
            return {
                ...state,
                background: payload
            }
        case types.language:
            return {
                ...state,
                language: payload
            }
        case types.countrys:
            return {
                ...state,
                countrys: payload.list,
                language: !state.language ? payload.language : state.language,
                currency: payload.currency
            }
        case types.modals.info:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    info: payload
                }
            }
        case types.modals.confirm:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    confirm: payload
                }
            }
        case types.modals.done:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    done: payload
                }
            }
        case types.modals.error:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    error: payload
                }
            }
        case types.jackpot:
            return {
                ...state,
                jackpots: state.jackpots.map(j => j.slug === payload.slug && j.currency === payload.currency ? payload : j)
            }
        case types.search:
            return {
                ...state,
                search: payload,
                show: InitialState.show
            }
        case types.winner:
            return {
                ...state,
                winners: [
                    payload,
                    ...state.winners
                ]
            }
        case types.show:
            return {
                ...state,
                show: state.show + InitialState.show
            }
        case types.type:
            return {
                ...state,
                type: payload,
                show: InitialState.show
            }
        case userTypes.loaded:
            return {
                ...state,
                ...payload.country,
                language: state.language ? state.language : payload.country.language
            }
        case userTypes.logout:
            return {
                ...state,
                games: null
            }
        case gameTypes.favorite:
            return {
                ...state,
                games: state.games ? state.games.map(game => {
                    return game.id === payload.game ? {
                        ...game,
                        favorite: payload.state
                    } : game;
                }) : state.games
            }
        case types.loaded:
            return {
                ...state,
                games: payload.games,
                winners: payload.winners,
                jackpots: payload.jackpots
            }
        default:
            return state;
    }
}